import React, { useCallback, useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { CiSearch } from "react-icons/ci";

const DropDownWithSearch = ({ type, tag, options, onSelect, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [filterData, setFilteredData] = useState();
  const [query, setQuery] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = () => {
    setIsOpen(false);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  const handleFilter = useCallback(
    (searchTerm) => {
      const filteredData = searchTerm
        ? options.filter((item) => {
            const content = item.label.toLowerCase();
            return content.includes(searchTerm.toLowerCase());
          })
        : options;

      setFilteredData(filteredData);
    },
    [options],
  );

  useEffect(() => {
    handleFilter(query);
  }, [handleFilter, query]);

  useEffect(() => {
    setSelectedOption({ value: tag, label: tag });
  }, [tag, value]);

  const refer = useOnclickOutside(() => setIsOpen(false));

  return (
    <div className="relative">
      <div
        className="flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-gray-300 rounded-md cursor-pointer hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-[100%] h-[40px]"
        onClick={toggleDropdown}
      >
        <p className="w-[100%]">
          {" "}
          {type === "multiSelect" ? (
            value ? (
              value
            ) : (
              <div className="text-left text-xs">{tag}</div>
            )
          ) : selectedOption ? (
            selectedOption.label
          ) : (
            <div className="text-left text-xs">{tag}</div>
          )}
        </p>

        <svg
          className={`w-4 h-4 ml-1 transition-transform duration-200 ${isOpen ? "transform rotate-180" : ""}`}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M6.293 7.293a1 1 0 0 1 1.414 0L10 9.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </div>
      {isOpen && (
        <div
          className={`absolute right-0 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg z-10 max-h-[400px] overflow-y-scroll ${
            isOpen ? "animate-slide-in" : "animate-slide-out"
          } `}
          ref={refer}
        >
          <div className="p-2">
            <div className="flex items-center bg-gray-100 border space-x-2 rounded-[4px] h-[35px] px-2">
              <div>
                <CiSearch />
              </div>
              <div className="w-full">
                <input
                  type="text"
                  className="w-full border-none focus:outline-none text-[14px] bg-gray-100"
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
          <ul className="" ref={refer} onClick={handleOutsideClick}>
            {filterData?.map((option, index) => (
              <li
                key={`${index}dropdown`}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer border-b-[1px]  text-xs"
                onClick={() => handleOptionClick(option)}
              >
                {option?.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropDownWithSearch;
