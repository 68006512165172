import { useQuery } from "@tanstack/react-query";
import { queryClient } from "config/gateway";
import { getWorkspaceToken } from "helpers/authHelper";
import { getToken } from "helpers/authHelper";
import { fetchSSE } from "services/contactService";

export const useServerSentEvents = () => {
  const token = getToken();
  const wpToken = getWorkspaceToken();

  const { data, error, isLoading } = useQuery({
    queryKey: ["serverSentEvents"],
    queryFn: () =>
      fetchSSE({
        headers: {
          Authorization: `Bearer ${token}`,
          workspace: `${wpToken}`,
        },
        onUpdate: (data) => {
          queryClient.setQueryData(["serverSentEvents"], (oldData = []) => [...oldData, data]);
        },
      }),

    enabled: !!token && !!wpToken,
  });

  return {
    data,
    error,
    isLoading,
  };
};
