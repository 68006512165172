import React, { useState } from "react";
import reportClicked from "assets/images/broadcast/reportClicked.png";
import emailSent from "assets/images/broadcast/newSent.png";
import bounced from "assets/images/broadcast/bounced.png";
import blackDoc from "assets/images/broadcast/blackDoc.png";
import ToggleButton from "components/Button/ToogleButton";

const Overview = ({ data, campDetails, graphData, select, setSelect }) => {
  const [toggleValue, setToggleValue] = useState(false);
  const handleToggleChange = () => {
    setToggleValue(!toggleValue);
  };

  return (
    <div>
      <div className="flex items-center space-x-3">
        <p className={`font-[500] text-[16px] ${toggleValue ? "text-gray-300" : ""}`}>Absolute (#)</p>
        <ToggleButton toggleValue={toggleValue} onChange={handleToggleChange} />
        <p className={`font-[500] text-[16px] ${!toggleValue ? "text-gray-300" : ""}`}>Percentage (%)</p>
      </div>
      <div className="">
        <div className="space-x-4 flex items-center justify-between">
          <div className="w-full bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
            <div className="flex justify-between">
              <div className="flex items-center justify-between space-x-5">
                <img src={emailSent} alt="" className="w-[51px] h-[51px]" />
                <div>
                  <p className="text-[28px] font-semibold">
                    {toggleValue ? <span>{Math.round(data?.sent?.percentage ?? 0)}%</span> : data?.sent?.value}
                  </p>
                  <p className="text-m">Total sends</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
            <div className="flex justify-between">
              <div className="flex items-center justify-between space-x-5">
                <img src={blackDoc} alt="" className="w-[51px] h-[51px]" />
                <div>
                  <p className="text-[28px] font-semibold">
                    {toggleValue ? <span>{Math.round(data?.opened?.percentage ?? 0)}%</span> : data?.opened?.value}
                  </p>
                  <p className="text-m">Opened</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
            <div className="flex justify-between">
              <div className="flex items-center justify-between space-x-5">
                <img src={reportClicked} alt="" className="w-[51px] h-[51px]" />
                <div>
                  <p className="text-[28px] font-semibold">
                    {toggleValue ? <span>{Math.round(data?.clicked?.percentage ?? 0)}%</span> : data?.clicked?.value}
                  </p>
                  <p className="text-m">Clicked</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-white rounded-[8px] border-[1px] p-4 my-[1em]">
            <div className="flex justify-between">
              <div className="flex items-center justify-between space-x-5">
                <img src={bounced} alt="" className="w-[51px] h-[51px]" />
                <div>
                  <p className="text-[28px] font-semibold">
                    {toggleValue ? <span>{Math.round(data?.bounced?.percentage ?? 0)}%</span> : data?.bounced?.value}
                  </p>
                  <p className="text-m">Bounced</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
