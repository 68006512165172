import { useEffect, useState } from "react";
import { statusColor } from "lib/statusColor";
import Modal from "components/Modal";
import BlueButton from "components/Button/BlueButton";
import WarningCard from "components/WarningCard";
import cerHolderMini from "assets/images/account/cerHolderMini.png";
import certificateHolder from "assets/images/account/certificateHolder.png";
import docUploadIcon from "assets/images/account/docUploadIcoon.png";
import { Link } from "react-router-dom";
import IndividualDocUpload from "./upload/IndividualDocUpload";

const ImageCard = ({ certName, status, image }) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(null);
  const previewDoc = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(!open);
  };

  const handleHover = (id) => {
    setHover(id === hover ? null : id);
  };

  return (
    <div className="mt-5 space-y-3 ">
      <div>
        <p>{certName}</p>
      </div>
      <div>
        <div
          className={`h-[280px] w-[254px] border ${
            hover === 1 ? "border-primary-400" : "border-gray-200 "
          } rounded-lg overflow-hidden mb-1 relative cursor-pointer transition-all delay-75 ${
            hover === 1 ? "bg-primary-100" : ""
          }`}
          onMouseEnter={() => handleHover(1)}
          onMouseLeave={() => handleHover(null)}
        >
          <div className="w-[70px] absolute top-5 left-[11rem]">{statusColor(status)}</div>
          {}
          <div className="px-5 py-3 mt-1">
            <img src={cerHolderMini} alt="" />
            <div className="absolute top-[3rem] left-4 px-1 flex justify-center w-[84%] h-[190px]">
              <img src={image} alt="" className="w-full h-full bg-white object-cover mt-1" />
            </div>
          </div>
          {hover !== 1 ? (
            ""
          ) : (
            <div className="bg-white h-[58px] absolute bottom-[0px] left-[2px] shadow-[0_0px_10px_-5px_rgba(0,0,0,0.3)] rounded-lg w-full">
              <div className="flex items-center justify-center space-x-3 selection h-full px-5">
                <BlueButton
                  text="Preview"
                  css="rounded-xl font-[800] bg-primaryBlue text-white"
                  variant="filled"
                  onClick={previewDoc}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        {open && (
          <Modal title="Preview Document" onClose={handleClose}>
            <div className="relative overflow-hidden">
              <div className="p-[3rem]">
                <div className="w-full p-10 rounded-t-xl overflow-hidden items-center flex justify-center relative bg-primary-100">
                  {/* <img src={certificateHolder} alt="" className="w-[629px] h-[446px]" /> */}
                  <div className=" w-[700px] h-[397px]">
                    <img src={certificateHolder} alt="" className="w-full h-full object-contain" />
                    <div className="absolute top-[4rem] left-[7.8rem] bg-white w-[49%] h-[355px] ">
                      <img src={image} alt="" className="w-full h-full object-contain" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute bottom-0 h-[70px] bg-white left-0 w-full border-t">
                <div className="flex justify-end pt-[1.5rem] pr-[3rem]">
                  <BlueButton text="close" type="button" onClick={handleClose} />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

const ImagePreview = ({ data, setSwitchTab, switchTab }) => {
  const [rcDoc, setRcDoc] = useState({
    name: "",
    status: "",
    value: "",
  });

  console.log(data);

  useEffect(() => {
    const rcNumberDoc = data.find((item) => item.name === "Rc Number");
    if (rcNumberDoc) {
      setRcDoc({
        name: rcNumberDoc.name,
        value: rcNumberDoc.value,
        status: rcNumberDoc.status,
      });
    }
  }, [data]);

  return (
    <div className="bg-white p-5 h-screen w-full rounded-md">
      <p className="text-[#737373] text-s font-[450] mt-2">Preview your uploaded and verified documents.</p>
      <hr />
      <div className="w-full my-2">
        <WarningCard
          heading="NOTE:"
          info={
            <p>
              If you have any issues with your documentation please contact{" "}
              <Link to="mailto:support@usemooyi.com" className="text-primary-800">
                support@usemooyi.com.
              </Link>
            </p>
          }
        />
      </div>

      <div className="flex items-center space-x-7">
        <div className="flex items-center space-x-7">
          {data.map((items, index) =>
            items.name === "Rc Number" ? null : (
              <ImageCard certName={items.name} status={items.status} image={items.value} key={index} />
            ),
          )}
        </div>

        {/* Use variables to store results of find checks */}
        {(() => {
          const missingProofOfAddr = data.every((item) => item.key !== "proofOfBusAdd" && item.key !== "mou");

          const missingCacDoc = data.every((item) => item.key !== "incopCert");

          return (
            <>
              {missingProofOfAddr && (
                <IndividualDocUpload
                  certName="Proof of Address"
                  status="Pending"
                  image={docUploadIcon}
                  docType="proofOfAddr"
                />
              )}
              {missingCacDoc && (
                <IndividualDocUpload certName="CAC document" status="Pending" image={docUploadIcon} docType="cacDoc" />
              )}
            </>
          );
        })()}
      </div>

      {rcDoc?.name !== "" ? (
        <div className="mt-10 pl-7">
          <div className="flex items-center mt-10 w-fit space-x-2 p-3 border ">
            <p>Rc Number: {rcDoc.value} </p>
            <p>{statusColor(rcDoc.status)} </p>
          </div>
        </div>
      ) : null}
      {/* <div className="mt-10">
        <BlueButton text="Upload document" type="button" onClick={() => setSwitchTab(!switchTab)} />
      </div> */}
    </div>
  );
};

export default ImagePreview;
