import React from "react";
import { Route, Routes } from "react-router-dom";
import Summary from "./Summary";
import EmailReportShare from "./emailSummary";
import SmsReportShare from "./smsSummary";
import PublicInsightSummary from "./insightOverview";

const PublicPages = () => {
  return (
    <Routes>
      <Route exact path="/summary/:pubId/:surveyId" element={<Summary />} />
      <Route exact path="/email-summary/:id" element={<EmailReportShare />} />
      <Route exact path="/sms-summary/:id" element={<SmsReportShare />} />
      <Route exact path="/insight-summary/*" element={<PublicInsightSummary />} />
    </Routes>
  );
};

export default PublicPages;
