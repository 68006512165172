import { useServerSentEvents } from "hooks/useContactServerSent";
import React from "react";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Typography from "utils/typography";
import uploadMascot from "assets/images/uploadMascot.png";
import { closeConnection } from "services/contactService";

const ProcessingContacts = () => {
  const navigate = useNavigate();
  const { data } = useServerSentEvents();

  const handleClose = () => {
    closeConnection();
    navigate("/contacts");
  };

  return (
    <div className="h-[100vh] w-[100vw] flex items-center justify-center">
      <div className=" w-[600px] ">
        <div className="flex items-center justify-between">
          <div></div>

          <IoClose className="text-3xl cursor-pointer" onClick={handleClose} />
        </div>

        <div className="text-center space-y-4">
          <img src={uploadMascot || ""} alt="uploadMascot" className="w-[300px] mx-auto mb-[2em]" />

          <Typography.H2>{(data && data[data?.length - 1]) || "Uploading your CSV"}...</Typography.H2>
        </div>
      </div>
    </div>
  );
};

export default ProcessingContacts;
