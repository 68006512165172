import { useState } from "react";
import Header from "../Header";
import PreLoader from "components/Loader/PreLoader";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CustomButton } from "components/Button/CustomButton";
import { BiSolidCopy } from "react-icons/bi";
import { copyFunc } from "lib/copyFunc";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchByPublishId } from "services/newSurveyService";
import { useParams } from "react-router-dom";
import useInsight from "hooks/useInsight";
import { useInsightOverview, useInsightOverviewPublic } from "hooks/useInsightOverview";
import totalScore from "assets/images/totalScore.png";
import promoters from "assets/images/promoters.png";
import passives from "assets/images/passives.png";
import detractors from "assets/images/detractors.png";
import OverviewTabPbk from "./components/OverviewTabPbk";

const NPSInsightSummary = () => {
  //const { pubId } = useParams();
  const workspaceId = "649d20953acb1d44e74069f8";
  const insightType = "nps";

  const { insightData, loadingInsight } = useInsight("nps");
  // const [surveyStatus, setSurveyStatus] = useState(insightData?.insights[0]?.surveyStatus);
  const { data } = useInsightOverviewPublic(insightType, workspaceId);
  const summaryData = data?.totalNpsMetrics;

  const surveyName = insightData?.insights[0]?.insightName;

  const isFetchingSummary = null;

  if (isFetchingSummary) {
    return <PreLoader />;
  }

  const onlineSummary = [
    {
      id: "npsScore",
      name: "NPS score",
      image: totalScore,
      value: summaryData?.npsScore,
      color: "text-primary-800",
    },
    {
      id: "promoter",
      name: "Promoters",
      image: promoters,
      value: summaryData?.promoter,
      color: "text-green-500",
    },
    {
      id: "passive",
      name: "Passives",
      image: passives,
      value: summaryData?.passive,
      color: "text-yellow-500",
    },
    {
      id: "detractor",
      name: "Detractors",
      image: detractors,
      value: summaryData?.detractor,
      color: "text-red-500",
    },
  ];

  return (
    <div className="bg-[#F7F7F8] md:h-[100vh] h-auto">
      <Header text={surveyName} />

      <div className="w-[90%] mx-auto ">
        <div className="md:hidden flex justify-between items-center pt-[2em]">
          <p className="font-bold text-[20px]">{surveyName}</p>
          <BsThreeDotsVertical className="text-[22px]" onClick={() => {}} />
        </div>

        <div className="flex justify-between my-4">
          <div className="md:w-[90%] w-[70%] flex items-start flex-col">
            <p className="border-b-[2px] border-purple pb-2">Summary</p>
            <hr className="w-[100%] border-[1px]" />
          </div>

          <CustomButton
            text="Copy link"
            icon={<BiSolidCopy />}
            onClick={() => copyFunc(window.location.href, "Link Copied")}
          />
        </div>

        <div className="flex flex-row  bg-white p-6 rounded-lg my-[1em] border h-auto gap-[2em] md:flex-row  md:h-[calc(100vh-200px)]  md:gap-0 ">
          <OverviewTabPbk overViewData={onlineSummary} graphData={data?.npsHistory} insightType={insightType} />
        </div>
      </div>
    </div>
  );
};

export default NPSInsightSummary;
