import { useQuery } from "@tanstack/react-query";
import PreLoader from "components/Loader/PreLoader";
import { useParams } from "react-router-dom";
import Overview from "./Overview";
import Details from "./Details";
import { getCampaignReport, getACampaignReport } from "services/campaignService";
import { getCampaignGraph } from "services/campaignService";
import Toast from "config/toast";
import Typography from "utils/typography";
import { reportStatus } from "lib/survStatus";
import { toTitleCase } from "lib/titleCase";
import Header from "../Header";

const SmsReportShare = () => {
  const { id } = useParams();

  const { data, isLoading: reportLoading } = useQuery({
    queryKey: ["oneCampaign", id],
    queryFn: () => getCampaignReport(id),
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { data: campDetails, isLoading: detailsLoading } = useQuery({
    queryKey: ["oneCampaignReport", id],
    queryFn: () => getACampaignReport(id),

    onError: (error) => {
      Toast.error(error);
    },
  });
  const { isLoading: graphLoading } = useQuery({
    queryKey: ["campaignGraph", id],
    queryFn: () => getCampaignGraph({ id: id }),

    onError: (error) => {
      Toast.error(error);
    },
  });

  const graphData = [
    {
      clickThroughRate: data?.delivered?.value,
      deliveryRate: data?.clicked?.value,
    },
  ];

  if (reportLoading || detailsLoading || graphLoading) {
    return <PreLoader text="Loading Report" />;
  }
  return (
    <div>
      <Header text="SMS Summary" />
      <div className="w-full h-screen overflow-y-auto flex items-center justify-center">
        <div className="w-[85%] p-10 rounded-[8px] bg-white border mt-[30rem]">
          <div className="flex justify-between items-center mt-3">
            <div className="flex items-start space-x-3">
              <div>
                <Typography.H3 className="font-[600] flex items-center space-x-2 ">
                  <span>{campDetails.campaignName}</span>
                  <span>{reportStatus(toTitleCase(campDetails.campaignStatus))}</span>
                </Typography.H3>
              </div>
            </div>
          </div>

          <div>
            <Overview data={data} graphData={graphData} campDetails={campDetails} />
            <Details data={campDetails} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmsReportShare;
