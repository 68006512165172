import Typography from "utils/typography";
import Tooltip from "./Tooltip";
import { formatAmount } from "lib/formatAmount";

const CreditCard = ({ name, icon, count, tooltipContent, id }) => {
  // const Icon = icon;
  return (
    <div className="border-b-[1px] rounded-md h-full " key={name}>
      <div className="w-[100%] h-[100%] p-2">
        <div className="w-[100%] h-[100%] rounded-md p-1 space-y-2 flex justify-between">
          <div className="flex items-center space-x-3">
            <div className="w-[15px] h-[15px] flex items-center justify-center rounded">
              {/* <Icon fill1="#141254" fill2={id === 1 ? "#141254" : "#fff"} /> */}
              <img src={icon} alt="" className="w-[40px]" />
            </div>
            <div className="flex items-center space-x-2">
              <Typography.MicroText className="">{name}</Typography.MicroText>

              <Tooltip className="" text={tooltipContent} />
            </div>
          </div>

          <Typography className=" text-[16px] font-bold">{formatAmount(count)}</Typography>
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
