import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import Skeleton from "react-loading-skeleton";
import CopyAction from "assets/svgs/actionsSvgs/CopyAction";
import DownloadAction from "assets/svgs/actionsSvgs/DownloadAction";
import PowerOffAction from "assets/svgs/actionsSvgs/PowerOffAction";
import PlayAction from "assets/svgs/actionsSvgs/PlayAction";
import Play2Action from "assets/svgs/actionsSvgs/Play2Action";
import ShareAction from "assets/svgs/actionsSvgs/ShareAction";
import PieChartsV2 from "../components/Charts/PieChartV2";
import PieCharts from "../components/Charts/PieChart";
import ChartCard from "../components/ChartCard";
import ProfilingStackedBarChart from "../components/Charts/ProfilingStackedBarChart";
import CustomBarChart from "../components/Charts/CustomBarChart";
import Typography from "utils/typography";
import { useNavigate } from "react-router-dom";
import EditAction from "assets/svgs/actionsSvgs/EditAction";
import svgUrl from "assets/svgs/dob.svg";
import gender from "assets/svgs/gender.svg";
import location from "assets/svgs/Location.svg";
import Dropdown from "../../../components/DropDown";
import dayjs from "dayjs";
import ShareModal from "../../Research/components/Analytics/ShareModal";
import CSVModal from "../../Research/components/Analytics/CSVModal";
import Toast from "config/toast";
import { baseMooyiRewardsURL } from "helpers/getBaseUrl";
import SurveyModals from "components/Modals/SurveyModals";
import { useInsightInhibitAction, useFetchInsightCSV } from "hooks/useInsightOverview";
import closeSVG from "assets/svgs/Close.svg";
import pauseSVG from "assets/svgs/pause.svg";
import restartSVG from "assets/svgs/restart.svg";
import { setRespondentId } from "helpers/surveyHelper";

const Overview = ({
  insights,
  insightId,
  isLoading,
  dates,
  setFilterPublishId,
  insightType,
  surveyStatus,
  shareLink,
}) => {
  const [active, setActive] = useState(null);
  const navigate = useNavigate();
  const handleHover = (id) => {
    setActive(active === id ? null : id);
  };
  const { state } = useLocation();
  const [open, setOpen] = useState(null);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [showRestartModal, setShowResumeModal] = useState(false);
  const [action, setAction] = useState(null);
  const [togglePause, setTogglePause] = useState(false);
  const [publishId, setPublishId] = useState(null);

  const {
    data: actionData,
    refetch: actionRefetch,
    isLoading: actionIsLoading,
    isError: actionIsError,
  } = useInsightInhibitAction(insightId, action);
  const { data: csvData, refetch: csvRefetch } = useFetchInsightCSV(insightType, publishId);

  const handleEdit = () => {
    navigate(`/insights/setup/customer-profile?insightId=${insights._id}`);
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    const cachedData = queryClient.getQueryData([`getInsight-${insightType}`]);
    if (cachedData && cachedData?.insights[0]?.surveyStatus !== surveyStatus) {
      queryClient.invalidateQueries([`getInsight-${insightType}`]);
    }
  }, [surveyStatus, insightType, queryClient]);

  useEffect(() => {
    if (surveyStatus === "paused") {
      setTogglePause(true);
    }
  }, [surveyStatus]);

  useEffect(() => {
    if (actionData) {
      Toast.success(actionData?.message);
      if (showCloseModal) {
        setShowCloseModal(false);
      } else if (showPauseModal) {
        setShowPauseModal(false);
      } else if (showRestartModal) {
        setShowResumeModal(false);
      }
    } else if (actionIsError) {
      Toast.error("Failed!");
    }
  }, [actionData, actionIsError]);

  useEffect(() => {
    if (action) {
      actionRefetch();

      if (action === "pause" || action === "resume") {
        setTogglePause((val) => !val);
      }
    }
  }, [action, actionRefetch]);

  const surveyLink = `${baseMooyiRewardsURL}insights/${insightId}?userId=`;
  const copyFunc = async () => {
    await navigator.clipboard.writeText(`${surveyLink?.toString()}`);
    Toast.success("Link copied");
  };

  const controlCSVModal = () => {
    csvRefetch();
    handleModal(2);
  };
  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const actionButtons = [
    {
      id: 1,
      name: "Copy link",
      action: (
        <CopyAction
          fill1={active === 1 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 1 ? "#95ADFF" : "#404040"}
          fill3={active === 1 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
      onClick: copyFunc,
    },
    {
      id: 2,
      name: "Export",
      action: (
        <DownloadAction
          fill1={active === 2 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 2 ? "#95ADFF" : "#404040"}
          fill3={active === 2 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
      onClick: controlCSVModal,
    },
    {
      id: 3,
      name: "Close survey",
      action: (
        <PowerOffAction
          fill1={active === 3 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 3 ? "#95ADFF" : "#404040"}
          fill3={active === 3 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
      onClick: () => {
        setShowCloseModal(true);
      },
    },
    togglePause === false
      ? {
          id: 4,
          name: "Pause survey",
          action: (
            <PlayAction
              fill1={active === 4 ? "#1D19AF" : "#DFE5EC"}
              fill2={active === 4 ? "#95ADFF" : "#404040"}
              fill3={active === 4 ? "#1D19AF" : "#A3A3A3"}
            />
          ),
          onClick: () => {
            setShowPauseModal(true);
          },
        }
      : {
          id: 4,
          name: "Resume survey",
          action: (
            <Play2Action
              fill1={active === 4 ? "#1D19AF" : "#DFE5EC"}
              fill2={active === 4 ? "#95ADFF" : "#404040"}
              fill3={active === 4 ? "#1D19AF" : "#A3A3A3"}
            />
          ),
          onClick: () => {
            setShowResumeModal(true);
          },
        },
    {
      id: 5,
      name: "Share",
      action: (
        <ShareAction
          fill1={active === 5 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 5 ? "#95ADFF" : "#404040"}
          fill3={active === 5 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
      onClick: () => {
        handleModal(1);
      },
    },
  ];

  const filterByDateOptions = dates.map((date) => {
    return { label: dayjs(date.date).format("YYYY-MM-DD"), value: date.id };
  });

  return (
    <div>
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-row gap-4 items-center w-[20%]">
          <Typography.SubText>Filter:</Typography.SubText>
          <div className="border-0  w-[90%]">
            <Dropdown
              tag={""}
              options={[{ label: "Select date", value: " " }, ...filterByDateOptions]}
              onSelect={(e) => {
                setPublishId(e.value);
                setFilterPublishId(e.value);
              }}
              selectedValue={" "}
            />
          </div>
        </div>
        <div className="flex space-x-2 items-center">
          <Typography.SubText>Actions:</Typography.SubText>
          <div className="flex items-center space-x-2 relative">
            {insights?.surveyStatus === "draft" ? (
              <div
                className="cursor-pointer"
                onMouseEnter={() => handleHover(6)}
                onMouseLeave={() => setActive(null)}
                onClick={handleEdit}
              >
                <EditAction
                  fill1={active === 6 ? "#1D19AF" : "#DFE5EC"}
                  fill2={active === 6 ? "#95ADFF" : "#404040"}
                  fill3={active === 6 ? "#1D19AF" : "#A3A3A3"}
                />

                {active === 6 ? (
                  <div className="absolute top-10">
                    <p className="px-3 py-1 w-fit rounded-md bg-white text-[10px]">Edit</p>
                  </div>
                ) : null}
              </div>
            ) : (
              <>
                {actionButtons.map((el, i) => (
                  <div
                    key={i}
                    className="cursor-pointer"
                    onMouseEnter={() => handleHover(el.id)}
                    onMouseLeave={() => setActive(null)}
                    onClick={el.onClick}
                  >
                    {el.action}
                    {active === el.id ? (
                      <div className="absolute top-10">
                        <p className="px-3 py-1 w-fit rounded-md bg-white text-[10px]">{el.name}</p>
                      </div>
                    ) : null}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="grid grid-cols-3 gap-4 py-4">
          {[1, 2, 3, 4, 5, 6].map(() => (
            <Skeleton className="w-[full] h-[30vh] py-10" />
          ))}
        </div>
      ) : (
        <div className="mt-5">
          <div className="grid grid-cols-3 gap-4">
            {insights &&
              Object.entries(insights).map(([key, value], index) => {
                if (value.questionType === "date") {
                  return (
                    <div className="flex flex-col items-center justify-center w-[100%]">
                      <ChartCard
                        title={key}
                        subtitle={value?.description ?? ""}
                        className={"flex flex-col items-center"}
                      >
                        <div className="flex flex-col gap-20 my-20">
                          <div className="w-full flex flex-row justify-center border-3 max-h-[110px]">
                            <img src={svgUrl} alt="My SVG" width="209" height="120" />
                          </div>
                          <div
                            className={`grid ${
                              value.analytics.length > 1 ? "grid-cols-2" : "grid-cols-1"
                            } gap-5  border-0 p-2 mx-10`}
                          >
                            {Object.entries(value.analytics).map(([key, value], index) => (
                              <div className="flex flex-row justify-between  border-2 shadow p-2 rounded ">
                                <div className="flex flex-row justify-center items-center gap-1">
                                  <div
                                    className={`w-3 h-3  border rounded ${
                                      index % 2 === 0 ? "bg-violet-400" : "bg-violet-800"
                                    } `}
                                  ></div>
                                  <div className="text-xs">
                                    <span className="font-light">{key}</span>
                                  </div>
                                </div>
                                <div className="text-xs">{value}%</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </ChartCard>
                    </div>
                  );
                } else if (value.questionType === "location") {
                  const stateObj = value.analytics[Object.keys(value.analytics)[0]];
                  const stateArray = Object.entries(stateObj);
                  return (
                    <div className="flex flex-col items-center justify-center w-[100%]">
                      <ChartCard
                        title={key}
                        subtitle={value?.description ?? ""}
                        className={"flex flex-col items-center "}
                      >
                        <div className="flex flex-col gap-20 my-20">
                          <div className="w-full flex flex-row justify-center border-3 max-h-[110px]">
                            <img src={location} alt="My SVG" width="95" height="100" />
                          </div>

                          <div
                            className={`grid  ${
                              stateArray.length > 1 ? "grid-cols-2" : "grid-cols-1"
                            } gap-5  border-0 p-2 mx-3 `}
                          >
                            {stateArray.map(([key, value], index) => (
                              <div className="flex flex-row justify-between border-2 shadow p-2 rounded gap-5">
                                <div className="flex flex-row justify-center items-center gap-1">
                                  <div
                                    className={`w-3 h-3 border rounded ${
                                      index % 2 === 0 ? "bg-violet-400" : "bg-violet-800"
                                    }`}
                                  ></div>
                                  <div className="text-xs">{key}</div>
                                </div>
                                <div className="text-xs">{value}%</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </ChartCard>
                    </div>
                  );
                } else if (key.includes("gender")) {
                  return (
                    <div>
                      <ChartCard title={key} subtitle={value?.description ?? ""}>
                        <div className="flex flex-col gap-20 my-20">
                          <div className="w-full flex flex-row justify-center border-3  max-h-[110px]">
                            <img src={gender} alt="My SVG" width="182" height="122" />
                          </div>
                          <div className="grid grid-cols-2 gap-5  border-0 p-2 mx-10">
                            {Object.entries(value.analytics).map(([key, value], index) => (
                              <div className="flex flex-row justify-between border-2 shadow p-2 rounded">
                                <div className="flex flex-row justify-center items-center gap-1">
                                  <div
                                    className={`w-3 h-3 border rounded ${
                                      index % 2 === 0 ? "bg-violet-400" : "bg-violet-800"
                                    }`}
                                  ></div>
                                  <div className="text-xs">{key}</div>
                                </div>
                                <div className="text-xs">{value.percentage}%</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </ChartCard>
                    </div>
                  );
                } else {
                  const data = Object.entries(value.analytics).map(([key, value]) => ({
                    name: key,
                    value: value.percentage,
                  }));
                  const labels = data.map((obj) => obj.name);
                  return index % 2 === 0 ? (
                    <div>
                      <ChartCard
                        title={key}
                        subtitle={value?.description ?? ""}
                        className="overflow-scroll no-scrollbar"
                      >
                        <div className="flex flex-col gap-25 my-3">
                          {/* <PieCharts data={data} className="grid grid-cols-2 gap-2" /> */}
                          <PieChartsV2 data={data} className="grid grid-cols-2 gap-2" labels={labels} />
                        </div>
                      </ChartCard>
                    </div>
                  ) : (
                    <div>
                      <ChartCard
                        title={key}
                        subtitle={value?.description ?? ""}
                        className="overflow-scroll no-scrollbar flex flex-col gap-5"
                      >
                        <div className="flex flex-col ">
                          <CustomBarChart data={data} className="grid grid-cols-2 gap-2" />
                        </div>
                      </ChartCard>
                    </div>
                  );
                }
              })}

            {open === 1 ? <ShareModal handleModal={handleModal} data={state} shareLink={shareLink} /> : null}

            {open === 2 ? <CSVModal handleModal={handleModal} fileUrl={csvData?.filePath} data={state} /> : null}

            {showCloseModal && (
              <SurveyModals
                title="Close Survey"
                setShowModal={setShowCloseModal}
                setAction={() => setAction("close")}
                yesBtnText="Yes,close"
                actionType="close"
                img={closeSVG}
                isLoading={actionIsLoading}
              >
                <div className="text-[12px]">Are you sure you want to close this survey?</div>
              </SurveyModals>
            )}

            {showPauseModal && (
              <SurveyModals
                title="Pause Survey"
                setShowModal={setShowPauseModal}
                setAction={() => setAction("pause")}
                yesBtnText="Yes,pause"
                actionType="pause"
                img={pauseSVG}
                isLoading={actionIsLoading}
              >
                <div className="flex flex-col items-center justify-center">
                  <div className="text-[12px]">Are you sure you want to pause the survey?</div>
                  <div className="text-[12px]">You will stop receiving responses until you restart.</div>
                </div>
              </SurveyModals>
            )}

            {showRestartModal && (
              <SurveyModals
                title="Resume Survey"
                setShowModal={setShowResumeModal}
                setAction={() => setAction("resume")}
                yesBtnText="Resume survey"
                actionType="resume"
                img={restartSVG}
                isLoading={actionIsLoading}
              >
                <div className="flex flex-col items-center justify-center">
                  <div className="text-[12px]">Do you really want to restart the survey?</div>
                </div>
              </SurveyModals>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Overview;
