import PluginComponent from "pages/PluginComponent";
import React, { useEffect, useState } from "react";
import EmailModal from "./EmailModal";
import { useMutation } from "@tanstack/react-query";
import { singleSmsCampaign } from "services/campaignService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import Publish from "./Publish";
import { createTestCampaign } from "services/campaignService";

// const tabs = [
//   { name: "Edit Content", value: 0 },
//   { name: "Publish", value: 1 },
// ];

const IndividualEmail = (data) => {
  const [editorOpen, setEditorOpen] = useState(true);
  const [step, setStep] = useState(0);
  console.log({ step }, "steps");
  // const [disableButton, setDisabledButton] = useState(true);
  const [broadcastData, setBroadcastData] = useState({});

  const { mutate } = useMutation({
    mutationFn: singleSmsCampaign,
    onSuccess: () => {
      Toast.success("Email sent successfully");
      queryClient.invalidateQueries({ queryKey: ["allEmailCampaigns"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: testMutate } = useMutation({
    mutationFn: createTestCampaign,
    onSuccess: () => {
      Toast.success(" Sending test campaign...");
      queryClient.invalidateQueries({ queryKey: ["wsCredit"] });
      setBroadcastData({
        ...broadcastData,
        testContact: "",
      });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleTest = () => {
    const userData = {
      campaign_channel: broadcastData.campaign_channel,
      content: JSON.stringify(broadcastData.content),
      sender_name: broadcastData.sender_name,
    };

    const newUserData = {
      ...userData,
      campaign_subject: broadcastData.campaign_name || "Mooyi Camp",
      sender_mail: broadcastData.sender_mail.toString().replace(",", " "),
      tester: {
        email: broadcastData.testContact,
      },
    };
    try {
      testMutate(newUserData);
    } catch (error) {}
  };

  const handleSubmit = () => {
    const payload = {
      ...broadcastData,
      content: JSON.stringify(broadcastData.content),
    };
    mutate(payload);
  };

  useEffect(() => {
    if (step === 3) {
      handleSubmit();
    }
  }, [step]);

  console.log(broadcastData);

  const displayStep = () => {
    switch (step) {
      case 0:
        return (
          <div>
            <EmailModal toggleModal={setStep} data={data} setBroadcastData={setBroadcastData} />
          </div>
        );
      case 1:
        return (
          <div className="absolute top-5 w-full h-screen bg-white">
            {editorOpen ? (
              <PluginComponent
                broadcastData={broadcastData}
                setBroadcastData={setBroadcastData}
                setStep={setStep}
                setEditorOpen={setEditorOpen}
                emailType="individualEmail"
              />
            ) : null}
          </div>
        );
      case 2:
        return (
          <div className="absolute top-5 left-0 w-full h-screen bg-white px-5">
            <Publish handleTest={handleTest} data={broadcastData} setData={setBroadcastData} setStep={setStep} />
          </div>
        );

      default:
    }
  };
  return <div className="w-full h-full z-50">{displayStep()}</div>;
};

export default IndividualEmail;
