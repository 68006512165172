import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import StackedBarCharts from "../Charts/StackedBarCharts";
import HalfPieChart from "../Charts/HalfPieChart";
import Typography from "utils/typography";
//import PieChart from "../analyticsTab/metricsCard/PieChart";
import Skeleton from "react-loading-skeleton";
import MetricCard from "../analyticsTab/metricsCard";
import Loading from "assets/images/loading.gif";
import dayjs from "dayjs";
import emptyNPSPopularTags from "assets/svgs/emptyNPSPopularTags.svg";
import HalfDoughnutChart from "../analyticsTab/metricsCard/HalfPieChart";

const NPSOverview = ({ isLoading, overViewData, graphData }) => {
  const cleanData = graphData
    ? graphData?.map((elm) => ({
        Promoters: elm.promoter,
        Detractors: elm.detractor,
        Passives: elm.passive,
        Date: dayjs(elm.publishDate).format("DD/MM/YYYY"),
      }))
    : null;

  const pieChartNPSData = {
    Promoters: overViewData[1]?.value,
    Passives: overViewData[2]?.value,
    Detractors: overViewData[3]?.value,
  };
  const pieChartData = Object.values(pieChartNPSData);
  const piecChartLabels = Object.keys(pieChartNPSData);

  // console.log("CleanData:: ", cleanData);
  return (
    <div className="flex items-center gap-[1.5em] w-full">
      <div className="flex space-x-4 mt-[2em] w-full">
        <div className="flex flex-col items-center w-[25%] bg-white p-4 border-[1px] rounded-lg space-y-[3em]">
          <div className="w-[100%] h-[130px] flex flex-col space-y-4">
            <Typography.SubText className="text-center">Latest Score</Typography.SubText>

            <div className="mx-auto relative">
              <HalfDoughnutChart data={pieChartData} labels={piecChartLabels} />

              <div className=" w-full flex flex-col items-center">
                {isLoading ? (
                  <Skeleton count={1} className="w-[40%]" />
                ) : (
                  <Typography.Headers>{overViewData[0]?.value ?? 0}</Typography.Headers>
                )}
              </div>
            </div>
          </div>

          <div className="border-t-[1px] w-[100%] pt-5 space-y-4">
            {overViewData?.slice(1).map((el, i) => (
              <div key={i} className="w-full">
                <MetricCard
                  elm={el}
                  type={el.name}
                  value={el.value ?? 0}
                  title={el.name}
                  loading={isLoading}
                  img={el.image}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white p-6  rounded-lg border-[1px] w-[75%]">
          {isLoading ? (
            <div className="w-full h-full flex items-center justify-center">
              <img src={Loading} alt="Loading..." className="w-[200px]" />
            </div>
          ) : (
            <div className="w-full h-[70%]">
              <div className="flex items-center justify-between">
                <Typography.SubText>Popular tags</Typography.SubText>

                {cleanData ? (
                  <Link to={"/insights/nps"}>
                    <Typography.SubText className="cursor-pointer text-primary-800">See all</Typography.SubText>
                  </Link>
                ) : (
                  <Typography.SubText className="cursor-pointer text-primary-200">See all</Typography.SubText>
                )}
              </div>
              {cleanData ? (
                <StackedBarCharts data={cleanData} dataKey={"Date"} className="h-[300px]" />
              ) : (
                <div className="flex flex-col text-center justify-center py-20 gap-10">
                  <div className="flex flex-row w-full text-center justify-center ">
                    <img src={emptyNPSPopularTags} alt="empty gender details" width={"81.78px"} height={"53.16px"} />
                  </div>
                  <div className="text-sm font-light">Your popular tags will be displayed here.</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NPSOverview;
