import { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../Header";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CustomButton } from "components/Button/CustomButton";
import { BiSolidCopy } from "react-icons/bi";
import { copyFunc } from "lib/copyFunc";
import useInsight from "hooks/useInsight";
import { useInsightOverview } from "hooks/useInsightOverview";
import LoadingSkeleton from "../../Insight/components/LoadingSkeleton";
import dayjs from "dayjs";
import Typography from "utils/typography";
import BlueButton from "../../../components/Button/BlueButton";
import researchGif from "assets/images/insights/customerProfile.png";
import { baseApiURL } from "helpers/getBaseUrl";
import CPOverviewTabPbk from "./components/CPOverviewTabPbk";
import { useInsightOverviewPublic } from "hooks/useInsightOverview";

const CPInsightSummary = () => {
  const { workspaceId } = useParams();

  const insightType = "cp";

  const { insightData, loadingInsight } = useInsight("cp");
  const { data, isLoading } = useInsightOverviewPublic(insightType, workspaceId);

  const [surveyStatus, setSurveyStatus] = useState(insightData?.insights[0]?.surveyStatus);

  const surveyName = insightData?.insights[0]?.insightName;

  return (
    <div className="bg-[#F7F7F8]  h-auto">
      <Header text={surveyName} />

      <div className="w-[90%] mx-auto ">
        <div className="md:hidden flex justify-between items-center pt-[2em]">
          <p className="font-bold text-[20px]">{surveyName}</p>
          <BsThreeDotsVertical className="text-[22px]" onClick={() => {}} />
        </div>

        <div className="flex justify-between my-4">
          <div className="md:w-[90%] w-[70%] flex items-start flex-col">
            <p className="border-b-[2px] border-purple pb-2">Summary</p>
            <hr className="w-[100%] border-[1px]" />
          </div>

          <CustomButton
            text="Copy link"
            icon={<BiSolidCopy />}
            onClick={() => copyFunc(window.location.href, "Link Copied")}
          />
        </div>

        <div className="flex bg-white p-6 rounded-lg my-[1em] border h-auto gap-[2em]">
          {loadingInsight ? <LoadingSkeleton /> : <CPOverviewTabPbk insights={data} isLoading={isLoading} />}
        </div>
      </div>
    </div>
  );
};

export default CPInsightSummary;
