import { useQuery } from "@tanstack/react-query";
import PreLoader from "components/Loader/PreLoader";
import { useState } from "react";
import { BsShareFill } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import Overview from "./Overview";
import Details from "./Details";
import { getCampaignReport, getACampaignReport } from "services/campaignService";
import { getCampaignGraph } from "services/campaignService";
import Toast from "config/toast";

import PrimaryButton from "components/Button/PrimaryButton";
import ShareModal from "./ShareModal";
import Typography from "utils/typography";
import { reportStatus } from "lib/survStatus";
import { toTitleCase } from "lib/titleCase";
import { FaChevronLeft } from "react-icons/fa";

const SmsReport = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openModal, setOpen] = useState(null);

  const { data, isLoading: reportLoading } = useQuery({
    queryKey: ["oneCampaign", id],
    queryFn: () => getCampaignReport(id),
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { data: campDetails, isLoading: detailsLoading } = useQuery({
    queryKey: ["oneCampaignReport", id],
    queryFn: () => getACampaignReport(id),

    onError: (error) => {
      Toast.error(error);
    },
  });
  const { isLoading: graphLoading } = useQuery({
    queryKey: ["campaignGraph", id],
    queryFn: () => getCampaignGraph({ id: id }),

    onError: (error) => {
      Toast.error(error);
    },
  });

  const graphData = [
    {
      clickThroughRate: data?.delivered?.value,
      deliveryRate: data?.clicked?.value,
    },
  ];

  if (reportLoading || detailsLoading || graphLoading) {
    return <PreLoader text="Loading Report" />;
  }
  return (
    <div>
      <div className="flex justify-between items-center mt-3">
        <div className="flex items-start space-x-3">
          <Link to="/broadcasts/sms" className="text-[#737373] flex items-center space-x-2">
            <div className="bg-white border-[1px] rounded h-[25px] w-[25px] flex items-center justify-center mt-1">
              <FaChevronLeft className="text-xs text-gray-600" />
            </div>
          </Link>

          <div>
            <Typography.H3 className="font-[600] flex items-center space-x-2 ">
              <span>{campDetails.campaignName}</span>
              <span>{reportStatus(toTitleCase(campDetails.campaignStatus))}</span>
            </Typography.H3>
            {/* <Typography.SubText className="flex items-center space-x-1">
              <span>{campDetails.campaignObjective}</span>
              <BsDot />
              <span>{moment(campDetails?.campaignDate).format("MMMM Do YYYY | h:mm:ss a")}</span>
            </Typography.SubText> */}
          </div>
        </div>

        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <div
              onClick={() => setOpen(1)}
              className="flex space-x-2 items-center bg-primary-100 px-6 py-2 rounded-[6px] text-primary-800 cursor-pointer hover:bg-primary-600 text-xs hover:text-white"
            >
              <BsShareFill />
              <p>Share</p>
            </div>

            <PrimaryButton
              text="Retarget"
              css="bg-primary-100 text-primary-800 px-6 py-2 text-xs hover:text-white"
              onClick={() => navigate(`/broadcasts/sms/retarget/${id}`)}
            />
          </div>
        </div>
      </div>

      <div>
        <Overview data={data} graphData={graphData} campDetails={campDetails} />
        <Details data={campDetails} />
      </div>
      {openModal && <ShareModal handleModal={() => setOpen(null)} />}
    </div>
  );
};

export default SmsReport;
