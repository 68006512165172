import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import PieCharts from "../../../Insight/components/Charts/PieChart";
import ChartCard from "../../../Insight/components/ChartCard";
import CustomBarChart from "../../../Insight/components/Charts/CustomBarChart";
import svgUrl from "assets/svgs/dob.svg";
import gender from "assets/svgs/gender.svg";
import location from "assets/svgs/Location.svg";

const CPOverviewTabPbk = ({ insights, isLoading }) => {
  return (
    <div className="flex flex-col">
      {isLoading ? (
        <div className="grid grid-cols-3 gap-4 py-4">
          {[1, 2, 3, 4, 5, 6].map(() => (
            <Skeleton className="w-[full] h-[30vh] py-10" />
          ))}
        </div>
      ) : (
        //  <div className="flex mt-5 bg-red-800 h-[100%]">
        <div className="grid grid-cols-3 gap-4 flex-col">
          {insights &&
            Object.entries(insights).map(([key, value], index) => {
              if (value.questionType === "date") {
                return (
                  <div className="flex flex-col items-center justify-center w-[100%]">
                    <ChartCard
                      title={key}
                      subtitle={value?.description ?? ""}
                      className={"flex flex-col items-center justify-center"}
                    >
                      <div className="w-full flex flex-row justify-center border-3 py-10">
                        <img src={svgUrl} alt="My SVG" width="209" height="120" />
                      </div>
                      <div
                        className={`grid ${
                          value.analytics.length > 1 ? "grid-cols-2" : "grid-cols-1"
                        } gap-5  border-0 p-2 mx-10`}
                      >
                        {Object.entries(value.analytics).map(([key, value], index) => (
                          <div className="flex flex-row justify-between  border-2 shadow p-2 rounded ">
                            <div className="flex flex-row justify-center items-center gap-1">
                              <div
                                className={`w-3 h-3  border rounded ${
                                  index % 2 === 0 ? "bg-violet-400" : "bg-violet-800"
                                } `}
                              ></div>
                              <div className="text-xs">
                                <span className="font-light">{key}</span>
                              </div>
                            </div>
                            <div className="text-xs">{value}%</div>
                          </div>
                        ))}
                      </div>
                    </ChartCard>
                  </div>
                );
              } else if (value.questionType === "location") {
                const stateObj = value.analytics[Object.keys(value.analytics)[0]];
                const stateArray = Object.entries(stateObj);
                return (
                  <div className="flex flex-col items-center justify-center w-[100%]">
                    <ChartCard
                      title={key}
                      subtitle={value?.description ?? ""}
                      className={"flex flex-col items-center justify-center"}
                    >
                      <div className="w-full flex flex-row justify-center border-3 py-10">
                        <img src={location} alt="My SVG" width="95" height="127" />
                      </div>

                      <div
                        className={`grid  ${
                          stateArray.length > 1 ? "grid-cols-2" : "grid-cols-1"
                        } gap-5  border-0 p-2 mx-3 `}
                      >
                        {stateArray.map(([key, value], index) => (
                          <div className="flex flex-row justify-between border-2 shadow p-2 rounded gap-5">
                            <div className="flex flex-row justify-center items-center gap-1">
                              <div
                                className={`w-3 h-3 border rounded ${
                                  index % 2 === 0 ? "bg-violet-400" : "bg-violet-800"
                                }`}
                              ></div>
                              <div className="text-xs">{key}</div>
                            </div>
                            <div className="text-xs">{value}%</div>
                          </div>
                        ))}
                      </div>
                    </ChartCard>
                  </div>
                );
              } else if (key.includes("gender")) {
                return (
                  <div>
                    <ChartCard title={key} subtitle={value?.description ?? ""}>
                      <div className="w-full flex flex-row justify-center border-3 py-10">
                        <img src={gender} alt="My SVG" width="182" height="122" />
                      </div>
                      <div className="grid grid-cols-2 gap-5  border-0 p-2 mx-10">
                        {Object.entries(value.analytics).map(([key, value], index) => (
                          <div className="flex flex-row justify-between border-2 shadow p-2 rounded">
                            <div className="flex flex-row justify-center items-center gap-1">
                              <div
                                className={`w-3 h-3 border rounded ${
                                  index % 2 === 0 ? "bg-violet-400" : "bg-violet-800"
                                }`}
                              ></div>
                              <div className="text-xs">{key}</div>
                            </div>
                            <div className="text-xs">{value.percentage}%</div>
                          </div>
                        ))}
                      </div>
                    </ChartCard>
                  </div>
                );
              } else {
                const data = Object.entries(value.analytics).map(([key, value]) => ({
                  name: key,
                  value: value.percentage,
                }));

                return index % 2 === 0 ? (
                  <div>
                    <ChartCard title={key} subtitle={value?.description ?? ""} className="overflow-scroll no-scrollbar">
                      <PieCharts data={data} className="grid grid-cols-2 gap-2" />
                    </ChartCard>
                  </div>
                ) : (
                  <div>
                    <ChartCard
                      title={key}
                      subtitle={value?.description ?? ""}
                      className="overflow-scroll no-scrollbar flex flex-col gap-5"
                    >
                      <CustomBarChart data={data} className="grid grid-cols-2 gap-2" />
                    </ChartCard>
                  </div>
                );
              }
            })}
        </div>
        // </div>
      )}
    </div>
  );
};

export default CPOverviewTabPbk;
