export const roleData = [
  {
    value: "",
    label: "Select role",
  },
  {
    value: "Data Analyst",
    label: "Data Analyst",
  },
  {
    value: "Consultant",
    label: "Consultant",
  },
  {
    value: "Digital Marketer",
    label: "Digital Marketer",
  },
  {
    value: "Freelancer",
    label: "Freelancer",
  },
  {
    value: "Product Designer",
    label: "Product Designer",
  },
  {
    value: "UX Researcher",
    label: "UX Researcher",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const options1 = [
  { value: "art/design", label: "Art/Design" },
  { value: "agriculture", label: "Agriculture" },
  { value: "architecture", label: "Architecture" },
  { value: "aviation", label: "Aviation" },
  { value: "banking/finance", label: "Banking/Finance" },
  { value: "construction", label: "Construction" },
  { value: "ecommerce", label: "Ecommerce" },
  { value: "education", label: "Education" },
  { value: "engineering", label: "Engineering" },
  { value: "entertainment", label: "Entertainment" },
  { value: "FMCG", label: "FMCG" },
  { value: "healthcare", label: "Healthcare" },
  { value: "hospitality", label: "Hospitality" },
  { value: "housing/real estate", label: "Housing/Real estate" },
  { value: "management", label: "Management" },
  { value: "marketing/advertising", label: "Marketing/Advertising" },
  { value: "information technology", label: "Information Technology" },
  { value: "logistics", label: "Logistics" },
  { value: "science", label: "Science" },
];

export const sizeOptions = [
  { value: "0-9 employees", label: "0-9 Employees" },
  { value: "11-50 employees", label: "11-50 Employees" },
  { value: "50-200 employees", label: "50-200 Employees" },
  { value: "251-1000 employees", label: "251-1000 Employees" },
];

export const proofData = [
  { value: "Utility Bill", label: "Utility Bill" },
  { value: "Any government issued ID", label: "Any government issued ID" },
  { value: "Affidavit", label: "Affidavit" },
];

export const category = [
  { value: "automotive_and_accessories", label: "Automotive and Accessories" },
  { value: "baby_and_kids_products", label: "Baby and Kids Products" },
  { value: "banking_and_financial_services", label: "Banking and Financial Services" },
  { value: "beauty_and_personal_care", label: "Beauty and Personal Care" },
  { value: "education", label: "Education" },
  { value: "electronics_and_gadgets", label: "Electronics and Gadgets" },
  { value: "food_and_beverages", label: "Food and Beverages" },
  { value: "health_and_wellness_products", label: "Health and Wellness Products" },
  { value: "home_decor_and_furnishings", label: "Home Decor and Furnishings" },
  { value: "home_and_kitchen_appliances", label: "Home and Kitchen Appliances" },
  { value: "jobs_and_career", label: "Jobs and Career" },
  { value: "mobile_phones_and_accessories", label: "Mobile Phones and Accessories" },
  { value: "other", label: "Other" },
];

export const categoryV2 = [
  {
    value: "animals_and_pets",
    label: "Animals & Pets",
    subcategories: [
      { value: "animal_health", label: "Animal Health" },
      { value: "animal_parks_and_zoo", label: "Animal Parks & Zoo" },
      { value: "cats_and_dogs", label: "Cats & Dogs" },
      { value: "horses_and_riding", label: "Horses & Riding" },
      { value: "pet_services", label: "Pet Services" },
      { value: "pet_stores", label: "Pet Stores" },
    ],
  },

  {
    value: "events_and_entertainment",
    label: "Events & Entertainment",
    subcategories: [
      { value: "adult_entertainment", label: "Adult Entertainment" },
      { value: "children's_entertainment", label: "Children's Entertainment" },
      { value: "clubbing_and_nightlife", label: "Clubbing & Nightlife" },
      { value: "events_and_venues", label: "Events & Venues" },
      { value: "gambling ", label: "Gambling" },
      { value: "gaming", label: "Gaming" },
      { value: "museums_and_exhibits", label: "Museums & Exhibits" },
      { value: "music_and_movies", label: "Music & Movies" },
      { value: "theater_and_opera", label: "Theater & Opera" },
      { value: "wedding_and_party", label: "Wedding & Party" },
    ],
  },
  {
    value: "home_and_garden",
    label: "Home & Garden",
    subcategories: [
      { value: "bathroom_and_kitchen", label: "Bathroom & Kitchen" },
      { value: "cultural_goods", label: "Cultural Goods" },
      { value: "decoration_and_interior", label: "Decoration & Interior" },
      { value: "energy_and_heating", label: "Energy & Heating" },
      { value: "fabric_and_stationery", label: "Fabric & Stationery" },
      { value: "furniture_stores", label: "Furniture Stores" },
      { value: "garden_and_pond", label: "Garden & Pond" },
      { value: "home_and_garden_services", label: "Home & Garden Services" },
      { value: "home_goods_stores", label: "Home Goods Stores" },
      { value: "home_improvements", label: "Home Improvements" },
    ],
  },
  {
    value: "restaurant_and_bars",
    label: "Restaurant & Bars",
    subcategories: [
      { value: "african_and_pacific_cuisine", label: "African & Pacific Cuisine" },
      { value: "bars_and_cafes", label: "Bars & Cafes" },
      { value: "chinese_and_korean_cuisine", label: "Chinese & Korean Cuisine" },
      { value: "european_cuisine", label: "European Cuisine" },
      { value: "general_restaurants", label: "General Restaurants" },
      { value: "japanese_cuisine", label: "Japanese Cuisine" },
      { value: "mediterranean_cuisine", label: "Mediterranean Cuisine" },
      { value: "middle_eastern_cuisine", label: "Middle Eastern Cuisine" },
      { value: "north_and_south_american_cuisine", label: "North & South American Cuisine" },
      { value: "southeast_asian_cuisine", label: "Southeast Asian Cuisine" },
      { value: "takeaway", label: "Takeaway" },
      { value: "vegetarian_and_diet", label: "Vegetarian & Diet" },
    ],
  },
  {
    value: "beauty_and_well-being",
    label: "Beauty & Well-being",
    subcategories: [
      { value: "cosmetics_and_makeup", label: "Cosmetics & Makeup" },
      { value: "hair_care_and_styling", label: "Hair Care & Styling" },
      { value: "personal_care", label: "Personal Care" },
      { value: "salons_and_clinics", label: "Salons & Clinics" },
      { value: "tattoos_and_piercings", label: "Tattoos & Piercings" },
      { value: "wellness_and_spa", label: "Wellness & Spa" },
      { value: "yoga_and_meditation", label: "Yoga & Meditation" },
    ],
  },
  {
    value: "food_Beverages_and_tobacco",
    label: "Food, Beverages & Tobacco",
    subcategories: [
      { value: "agriculture_and_produce", label: "Agriculture & Produce" },
      { value: "asian_grocery_stores", label: "Asian Grocery Stores" },
      { value: "bakery_and_pastry", label: "Bakery & Pastry" },
      { value: "beer_and_wine", label: "Beer & Wine" },
      { value: "beverages_and_liquor", label: "Beverages & Liquor" },
      { value: "candy_and_chocolate", label: "Candy & Chocolate" },
      { value: "coffee_and_tea", label: "Coffee & Tea" },
      { value: "food_production", label: "Food Production" },
      { value: "fruits_and_vegetables", label: "Fruits & Vegetables" },
      { value: "grocery_stores_and_markets", label: "Grocery Stores & Markets" },
      { value: "lunch_and_catering", label: "Lunch & Catering" },
      { value: "meat_seafood_and_eggs", label: "Meat, Seafood & Eggs" },
      { value: "smoking_and_tobacco", label: "Smoking & Tobacco" },
    ],
  },
  {
    value: "home_services",
    label: "Home Services",
    subcategories: [
      { value: "cleaning_service_providers", label: "Cleaning Service Providers" },
      { value: "craftsman", label: "Craftsman" },
      { value: "house_services", label: "House Services" },
      { value: "house_sitting_and_security", label: "House Sitting & Security" },
      { value: "moving_and_storage", label: "Moving & Storage" },
      { value: "plumbing_and_sanitation", label: "Plumbing & Sanitation" },
      { value: "repair_service_providers", label: "Repair Service Providers" },
    ],
  },
  {
    value: "shopping_and_fashion",
    label: "Shopping & Fashion",
    subcategories: [
      { value: "accessories", label: "Accessories" },
      { value: "clothing_and_underwear", label: "Clothing & Underwear" },
      { value: "clothing_rental_and_repair", label: "Clothing Rental & Repair" },
      { value: "costume_and_wedding", label: "Costume & Wedding" },
      { value: "jewelry_and_watches", label: "Jewelry & Watches" },
      { value: "malls_and_marketplaces", label: "Malls & Marketplaces" },
    ],
  },
  {
    value: "business_services",
    label: "Business Services",
    subcategories: [
      { value: "administration_and_services", label: "Administration & Services" },
      { value: "associations_and_centers", label: "Associations & Centers" },
      { value: "hr_and_recruiting", label: "HR & Recruiting" },
      { value: "import_and_export", label: "Import & Export" },
      { value: "it_and_communication", label: "IT & Communication" },
      { value: "office_space_and_supplies", label: "Office Space & Supplies" },
      { value: "print_and_graphic_design", label: "Print & Graphic Design" },
      { value: "research_and_development", label: "Research & Development" },
      { value: "sales_and_marketing", label: "Sales & Marketing" },
      { value: "shipping_and_logistics", label: "Shipping & Logistics" },
      { value: "wholesale", label: "Wholesale" },
      { value: "fmcg", label: "FMCG" },
    ],
  },
  {
    value: "legal_services_and_government",
    label: "Legal services & Government",
    subcategories: [
      { value: "customs_and_toll", label: "Customs & Toll" },
      { value: "government_department", label: "Government Department" },
      { value: "law_enforcement", label: "Law Enforcement" },
      { value: "lawyers_and_attorneys", label: "Lawyers & Attorneys" },
      { value: "legal_service_providers", label: "Legal Service Providers" },
      { value: "libraries_and_archives", label: "Libraries & Archives" },
      { value: "municipal_department", label: "Municipal Department" },
      { value: "registration_services", label: "Registration Services" },
    ],
  },
  {
    value: "sports",
    label: "sports",
    subcategories: [
      { value: "ball_games", label: "Ball Games" },
      { value: "bat_and_ball_games", label: "Bat-and-ball Games" },
      { value: "bowls_and_lawn_sports", label: "Bowls & Lawn Sports" },
      { value: "dancing_and_gymnastics", label: "Dancing & Gymnastics" },
      { value: "equipment_and_associations", label: "Equipment & Associations" },
      { value: "extreme_sports", label: "Extreme Sports" },
      { value: "fitness_and_weight_lifting", label: "Fitness & Weight Lifting" },
      { value: "golf_and_ultimate", label: "Golf & Ultimate" },
      { value: "hockey_and_ice_skating", label: "Hockey & Ice Skating" },
      { value: "martial_arts_and_wrestling", label: "Martial Arts & Wrestling" },
      { value: "outdoor_and_winter_sports", label: "Outdoor & Winter Sports" },
      { value: "shooting_and_target_sports", label: "Shooting & Target Sports" },
      { value: "swimming_and_water_sports", label: "Swimming & Water Sports" },
      { value: "tennis_and_racquet_sports", label: "Tennis & Racquet Sports" },
    ],
  },
  {
    value: "health_and_medical",
    label: "Health & Medical",
    subcategories: [
      { value: "clinics", label: "Clinics" },
      { value: "dental_services", label: "Dental Services" },
      { value: "diagnostics_and_testing", label: "Diagnostics & Testing" },
      { value: "doctors_and_surgeons", label: "Doctors & Surgeons" },
      { value: "health_equipment", label: "Health Equipment" },
      { value: "hospital_and_emergency", label: "Hospital & Emergency" },
      { value: "medical_specialists", label: "Medical Specialists" },
      { value: "mental_health", label: "Mental Health" },
      { value: "pharmacy_and_medicine", label: "Pharmacy & Medicine" },
      { value: "physical_aids", label: "Physical Aids" },
      { value: "pregnancy_and_children", label: "Pregnancy & Children" },
      { value: "therapy_and_senior_health", label: "Therapy & Senior Health" },
      { value: "vision_and_hearing", label: "Vision & Hearing" },
    ],
  },
  {
    value: "media_and_publishing",
    label: "Media & Publishing",
    subcategories: [
      { value: "books_and_magazines", label: "Books & Magazines" },
      { value: "media_and_information", label: "Media & Information" },
      { value: "photography", label: "Photography" },
      { value: "video_and_sound", label: "Video & Sound" },
    ],
  },
  {
    value: "travel_and_vacation",
    label: "Travel & Vacation",
    subcategories: [
      { value: "accommodation_and_lodging", label: "Accommodation & Lodging" },
      { value: "activities_and_tours", label: "Activities & Tours" },
      { value: "airlines_and_air_travel", label: "Airlines & Air Travel" },
      { value: "hotels", label: "Hotels" },
      { value: "travel_agencies", label: "Travel Agencies" },
    ],
  },
  {
    value: "education_and_training",
    label: "Education & Training",
    subcategories: [
      { value: "colleges_and_universities", label: "Colleges & Universities" },
      { value: "courses_and_classes", label: "Courses & Classes" },
      { value: "education_services", label: "Education Services" },
      { value: "language_learning", label: "Language Learning" },
      { value: "music_and_theater_classes", label: "Music & Theater Classes" },
      { value: "school_and_high_school", label: "School & High School" },
      { value: "specials_schools", label: "Specials Schools" },
      { value: "vocational_training", label: "Vocational Training" },
    ],
  },
  {
    value: "hobbies_and_crafts",
    label: "Hobbies & Crafts",
    subcategories: [
      { value: "art_and_handicraft", label: "Art & Handicraft" },
      { value: "astrology_and_numerology", label: "Astrology & Numerology" },
      { value: "fishing_and_hunting", label: "Fishing & Hunting" },
      { value: "hobbies", label: "Hobbies" },
      { value: "metal_stone_and_glass_work", label: "Metal, Stone & Glass Work" },
      { value: "music_and_instruments", label: "Music & Instruments" },
      { value: "needlework_and_knitting", label: "Needlework & Knitting" },
      { value: "outdoor_activities", label: "Outdoor Activities" },
      { value: "painting_and_paper", label: "Painting & Paper" },
    ],
  },
  {
    value: "money_and_insurance",
    label: "Money & Insurance",
    subcategories: [
      { value: "accounting_and_tax", label: "Accounting & Tax" },
      { value: "banking_and_money", label: "Banking & Money" },
      { value: "credit_and_debt_services", label: "Credit & Debt Services" },
      { value: "insurance", label: "Insurance" },
      { value: "investments_and_wealth", label: "Investments & Wealth" },
      { value: "real_estate", label: "Real Estate" },
    ],
  },
  {
    value: "public_and_local_Services",
    label: "Public & Local Services",
    subcategories: [
      { value: "employment_and_career", label: "Employment & Career" },
      { value: "funeral_and_memorial", label: "Funeral & Memorial" },
      { value: "housing_associations", label: "Housing Associations" },
      { value: "kids_and_family", label: "Kids & Family" },
      { value: "military_and_veteran", label: "Military & Veteran" },
      { value: "nature_and_environment", label: "Nature & Environment" },
      { value: "professional_organizations", label: "Professional Organizations" },
      { value: "public_services_and_welfare", label: "Public Services & Welfare" },
      { value: "religious_institutions", label: "Religious Institutions" },
      { value: "shelters_and_homes", label: "Shelters & Homes" },
      { value: "waste_management", label: "Waste Management" },
    ],
  },
  {
    value: "utilities",
    label: "Utilities",
    subcategories: [
      { value: "energy_and_power", label: "Energy & Power" },
      { value: "oil_and_fuel", label: "Oil & Fuel" },
      { value: "water_utilities", label: "Water Utilities" },
    ],
  },
  {
    value: "vehicle_and_transportation",
    label: "Vehicle & Transportation",
    subcategories: [
      { value: "air_and_water_transport", label: "Air & Water Transport" },
      { value: "airports_and_parking", label: "Airports & Parking" },
      { value: "auto_parts_and_wheels", label: "Auto Parts & Wheels" },
      { value: "bicycles", label: "Bicycles" },
      { value: "cars_and_trucks", label: "Cars & Trucks" },
      { value: "motorcycle_and_powersports", label: "Motorcycle & Powersports" },
      { value: "other_vehicles_and_trailers", label: "Other Vehicles & Trailers" },
      { value: "taxis_and_public_transport", label: "Taxis & Public Transport" },
      { value: "vehicle_rental", label: "Vehicle Rental" },
    ],
  },
  {
    value: "others",
    label: "Others",
    subcategories: [{ value: "other", label: "other" }],
  },
];
// export const subCategoryV2 = [
//   {value:"" , label: ""},

// ]
