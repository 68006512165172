import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import MetricCard from "../../../Insight/components/analyticsTab/metricsCard";
import StackedBarCharts from "../../../Insight/components/Charts/StackedBarCharts";
import CopyAction from "assets/svgs/actionsSvgs/CopyAction";
import DownloadAction from "assets/svgs/actionsSvgs/DownloadAction";
import PowerOffAction from "assets/svgs/actionsSvgs/PowerOffAction";
import PlayAction from "assets/svgs/actionsSvgs/PlayAction";
import ShareAction from "assets/svgs/actionsSvgs/ShareAction";
import Loading from "assets/images/loading.gif";
import EditAction from "assets/svgs/actionsSvgs/EditAction";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "utils/typography";
import HalfDoughnutChart from "../../../Insight/components/analyticsTab/metricsCard/HalfPieChart";
import Skeleton from "react-loading-skeleton";
import dayjs from "dayjs";

const OverviewTabPbk = ({ overViewData, graphData, isLoading, insightType }) => {
  const cleanData = graphData?.map((elm) => ({
    Promoters: elm.promoter,
    Detractors: elm.detractor,
    Passives: elm.passive,
    Date: dayjs(elm.publishDate).format("DD/MM/YYYY"),
  }));

  const cleanCSATData = graphData?.map((elm) => ({
    Neutral: elm.neutral,
    Satisfied: elm.satisfied,
    Unsatisfied: elm.unsatisfied,
    Date: dayjs(elm.publishDate).format("DD/MM/YYYY"),
  }));

  const location = useLocation();
  const locationArray = location.pathname.split("/");
  const pieChartCSATData = {
    Satisfied: overViewData[1]?.value,
    Neutral: overViewData[2]?.value,
    Unsatisfied: overViewData[3]?.value,
  };
  const pieChartNPSData = {
    Promoters: overViewData[1]?.value,
    Passives: overViewData[2]?.value,
    Detractors: overViewData[3]?.value,
  };
  const pieChartData = insightType === "nps" ? Object.values(pieChartNPSData) : Object.values(pieChartCSATData);
  const piecChartLabels = insightType === "nps" ? Object.keys(pieChartNPSData) : Object.keys(pieChartCSATData);

  return (
    <div className="flex space-x-4 mt-[2em] w-full">
      <div className="flex flex-col items-center w-[25%] bg-white p-4 border-[1px] rounded-lg space-y-[3em]">
        <div className="w-[100%] h-[140px] flex flex-col space-y-4">
          <Typography.H4 className="text-center">Score</Typography.H4>

          <div className="mx-auto relative">
            <HalfDoughnutChart data={pieChartData} labels={piecChartLabels} />
            <div className=" w-full flex flex-col items-center ">
              {isLoading ? (
                <Skeleton count={1} className="w-[40%]" />
              ) : (
                <Typography.Headers>{overViewData[0]?.value}</Typography.Headers>
              )}
            </div>
          </div>
        </div>

        <div className="border-t-[1px] w-[100%] pt-5 space-y-4">
          {overViewData?.slice(1).map((el, i) => (
            <div key={i} className="w-full">
              <MetricCard elm={el} type={el.name} value={el.value} title={el.name} loading={isLoading} />
            </div>
          ))}
        </div>
      </div>

      <div className="flex bg-white p-6  rounded-lg border-[1px] w-[75%]">
        {isLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <img src={Loading} alt="Loading..." className="w-[200px]" />
          </div>
        ) : (
          <StackedBarCharts
            data={locationArray.includes("nps") ? cleanData : cleanCSATData}
            dataKey={"Date"}
            className="w-[100%] h-[85%]"
          />
        )}
      </div>
    </div>
  );
};

export default OverviewTabPbk;
