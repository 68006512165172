import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from "recharts";

// const data = [
//   { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
//   { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
//   { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
//   { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
//   { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
//   { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
//   { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
// ];

const COLORS = ["#1D19AF", "#4852FF", "#5F0BDD", "#00DA7A"];

const CustomBarChart = ({ data, className }) => {
  return (
    <div className="flex flex-col gap-2">
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={20}
        >
          <XAxis dataKey="name" fontSize={10} scale="point" padding={{ left: 10, right: 10 }} />
          <YAxis fontSize={10} />
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend /> */}
          <CartesianGrid strokeDasharray="3 3" />

          <Bar dataKey="value" background={{ fill: "#eee" }}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div className={className ? className : "flex gap-2 mx-auto mb-4 w-full"}>
        {data?.map((elm, index) => (
          <div
            key={`optionTag${index}`}
            className="flex items-center space-x-2 p-1 px-2 border-[1px] rounded-[4px] justify-start"
          >
            <div className="w-[10px] h-[10px] rounded-sm" style={{ background: COLORS[index % COLORS.length] }}></div>
            <p className="text-ss">{elm.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white text-black rounded-md p-2 shadow-md">
        <p className="text-sm ">Label: {label}</p>
        <p className="text-sm">Value: {payload[0].value}</p>
      </div>
    );
  }

  return null;
};

export default CustomBarChart;
