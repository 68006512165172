import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

// Register the required components
ChartJS.register(ArcElement, Tooltip, Legend);

const possibleColors = ["#4852FF", "#6B7FFF", "#95ADFF"];
const HalfDoughnutChart = ({ labels, data }) => {
  const dataObj = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: getArraySubset(possibleColors, labels.length),
      },
    ],
  };

  const options = {
    cutout: "70%", // Controls the doughnut center
    rotation: -90, // Starts from the top
    circumference: 180, // Half-pie (half circle)
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        backgroundColor: "#F8F8FF", // Set tooltip background color to white
        titleColor: "#000000", // Set tooltip title color
        bodyColor: "#000000", // Set tooltip body color
        borderColor: "#000000", // Optional: add a border
        borderWidth: 0, // Optional: set border width
      },
    },
  };
  return (
    <div className="w-[150px] h-[100px] p-0 m-0">
      <Doughnut data={dataObj} options={options} />
    </div>
  );
};

export default HalfDoughnutChart;

function getArraySubset(array, count, startIndex = 0) {
  if (!Array.isArray(array)) {
    throw new Error("The first argument must be an array.");
  }

  return array.slice(startIndex, startIndex + count);
}
