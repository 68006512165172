import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import OverviewCard from "../OverviewCard";
import Dropdown from "components/DropDown";
import Typography from "utils/typography";
import ResponseCard from "./ResponseCard";
import { useLocation } from "react-router-dom";
import SideModal from "components/Modals/SideModal";
import BlueButton from "components/Button/BlueButton";
import MetricCard from "./metricsCard";
import SingleOverviewCard from "../SingleOverviewCard";
import { formatDate } from "lib/formatDate";
import SingleResponseCard from "./ResponseCard/SingleResponseCard";

const ResponsesTab = ({ overViewData, responses }) => {
  const location = useLocation();
  const locationArray = location.pathname.split("/");
  const isNPS = locationArray.includes("nps");
  const [openModal, setOpenModal] = useState(null);
  const [type, setType] = useState("All");
  const [filteredData, setFilteredData] = useState();

  const handleModal = (id) => {
    setOpenModal(openModal === id ? null : id);
  };

  const filterTab = [
    { name: "All", value: 0 },
    { name: isNPS ? "Promoters" : "Satisfied", value: 1 },
    { name: isNPS ? "Passives" : "Neutral", value: 2 },
    { name: isNPS ? "Detractors" : "Unsatisfied", value: 3 },
  ];

  const responseFilter = overViewData.filter((el) => el.name === "Responses");
  const handleFilter = (name) => {
    setType(name);
  };

  useEffect(() => {
    const filteredData = type === "All" ? responses : responses.filter((el) => el?.scores?.classifier === type);
    setFilteredData(filteredData);
  }, [type]);

  return (
    <div>
      <div className="flex w-full items-center justify-between">
        <div>
          <p>Filter:</p>
        </div>
        <div className="flex space-x-2 items-center">
          <div className="bg-white border w-[366px] h-[40px] rounded-[8px] flex items-center px-4 space-x-2">
            <BsSearch className="text-[#9CA3AF]" />
            <input
              type="text"
              className="placeholder:text-[#9CA3AF] placeholder:text-ss placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
              placeholder="Search feedback or audience..."
              // onChange={handleChangeText}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between space-x-4 mt-5">
        {overViewData?.map((elm, i) => (
          <OverviewCard key={i} elm={elm} loading={false} type={elm.name} title={elm.name} value={elm.value} />
        ))}
      </div>
      <div className="mt-5 w-full rounded-lg bg-white border p-5">
        <div className="flex items-center justify-between w-full">
          <div>
            <Dropdown tag="Survey questions" />
          </div>
          <div className={`rounded-md gap-3 px-3 flex items-center bg-gray-50 border`}>
            {filterTab.map((el, i) => (
              <Typography.SubText
                key={i}
                className={`py-2 pr-3 cursor-pointer ${i !== filterTab.length - 1 ? "border-r " : null} ${
                  type === el.name ? "font-[600]" : ""
                }`}
                onClick={() => handleFilter(el.name)}
              >
                {el.name}
              </Typography.SubText>
            ))}
          </div>
        </div>
        <div className="mt-5 space-y-5">
          {filteredData?.map((el, i) => (
            <>
              <div
                key={i}
                className="cursor-pointer"
                onClick={() => {
                  /*handleModal(el._id)*/
                }}
              >
                <ResponseCard elm={el} />
              </div>
              {openModal === el._id ? (
                <SideModal title={`Preview`} onClose={handleModal}>
                  <div className="w-[672px] px-5 pt-5 overflow-y-auto h-[85vh]">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <div className="w-[86px] h-[86px] bg-primary-100 rounded-full flex items-center justify-center overflow-hidden border-[1px]">
                          <p className="text-primary-800  text-[25px] font-[500] mx-auto">
                            {[el?.firstname.slice(0, 1).toUpperCase(), el?.lastname.slice(0, 1).toUpperCase()]}
                          </p>
                        </div>
                        <div>
                          <Typography.Text>
                            {el?.firstname} {el?.lastname}
                          </Typography.Text>
                          <Typography.SubText>Created on {formatDate(el?.responseTime?.startTime)}</Typography.SubText>
                        </div>
                      </div>
                      <div>
                        <BlueButton text="Go to profile" type="button" onClick={() => ""} />
                      </div>
                    </div>
                    <div className="py-5">
                      <hr />
                    </div>
                    <Typography.Text>Summary</Typography.Text>
                    <div className="p-5 bg-gray-50 mt-3">
                      <div className="grid grid-cols-2 items-center gap-4 w-full">
                        <MetricCard
                          elm={el}
                          type={el?.scores?.classifier}
                          value={el?.scores?.value}
                          title={el?.scores?.classifier}
                        />
                        <SingleOverviewCard
                          key={i}
                          elm={el}
                          loading={false}
                          value={overViewData[0]?.value}
                          title={overViewData[0]?.name}
                        />
                      </div>
                    </div>
                    <div className="py-5">
                      <hr />
                    </div>
                    <Typography.Text>Responses</Typography.Text>
                    <div className="mt-3">
                      <div className="flex w-full items-center justify-between">
                        <div>
                          <p>Filter:</p>
                        </div>
                        <div className="flex space-x-2 items-center">
                          <div className="bg-white border w-[366px] h-[40px] rounded-[8px] flex items-center px-4 space-x-2">
                            <BsSearch className="text-[#9CA3AF]" />
                            <input
                              type="text"
                              className="placeholder:text-[#9CA3AF] placeholder:text-ss placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
                              placeholder="Search tags..."
                              // onChange={handleChangeText}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 space-y-2">
                        {el?.responses?.map((elm, i) => (
                          <div key={i}>
                            <SingleResponseCard elm={el} question={elm?.question} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </SideModal>
              ) : null}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResponsesTab;
