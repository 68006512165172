import GreyButton from "components/Button/GreyButton";
import { formatAmount } from "lib/formatAmount";
import { formatDate } from "lib/formatDate";
import { toTitleCase } from "lib/titleCase";
import { useStore } from "store";
import React, { useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { BsSearch } from "react-icons/bs";
import { accStatus } from "lib/survStatus";

const accColumns = [
  { field: "sn", header: "S/N" },
  { field: "price", header: "Amount" },
  { field: "mooyiCredit", header: "Mooyi Credit" },
  { field: "plan", header: "Plan" },
  // { field: "category", header: "Category" },
  { field: "status", header: "Status" },
  { field: "date", header: "Created At" },
  { field: "viewProps", header: "" },
];

const WorkspaceHistory = ({ accLedger, setAccLedgerBatch, accLedgerBatch, accLoading }) => {
  const { totalDoc } = useStore();
  const [query, setQuery] = useState("");
  const [searchedData, setSearchedData] = useState();

  console.log(accLedger, "accLedger");

  const accLedgerData = accLedger?.ledgers?.map((el, i) => ({
    id: el?._id,
    sn: i + 1,
    date: formatDate(el?.createdAt),
    plan: (
      <>
        <div key={i} className="py-1 px-2 flex items-center justify-center w-fit rounded-md bg-gray-50">
          {toTitleCase(el?.plan) || toTitleCase(el?.planSub[0]?.plan)}
        </div>
      </>
    ),
    price: <p>&#x20A6; {formatAmount(el?.plan_price)}</p>,
    status: accStatus(toTitleCase(el?.payment_status)),
    type: toTitleCase(el?.transaction_type),
    amount: <p>&#x20A6; {toTitleCase(el?.amount)}</p>,
    mooyiCredit: el?.mooyi_credit,
  }));

  const handlePagination = (x) => {
    if (x === "prev") {
      if (accLedgerBatch <= 1) {
        setAccLedgerBatch(1);
      } else {
        setAccLedgerBatch(accLedgerBatch - 1);
      }
    } else if (x === "next" && accLedgerData.length === 10) {
      setAccLedgerBatch(accLedgerBatch + 1);
    }
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const searchFilter = useCallback(
    (searchTerm) => {
      const dataSet = accLedger?.ledgers?.filter((item) => {
        const content = item?.id?.toLowerCase();
        return content?.includes(searchTerm?.toLowerCase());
      });

      setSearchedData(dataSet);
    },
    [accLedger?.ledgers],
  );

  useEffect(() => {
    searchFilter(query);
  }, [query, searchFilter]);

  return (
    <div>
      <div className="bg-white border w-[366px] h-[40px] rounded-[8px] flex items-center px-4 space-x-2">
        <BsSearch className="text-[#9CA3AF]" />
        <input
          type="text"
          className="placeholder:text-[#9CA3AF] placeholder:text-ss placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
          placeholder="Search"
          onChange={handleChange}
        />
      </div>
      <table className="w-[100%] mt-5">
        <thead className=" text-ss bg-[#F9FAFB]  border-b-[1px] border-t-[1px] ">
          <tr className=" h-[45px] px-7 text-left border-b-[1px] border-r-[1px]">
            {accColumns &&
              accColumns.map((head, i) => (
                <th key={i} className="pl-4 border-l-[1px]">
                  {head.header}{" "}
                </th>
              ))}
          </tr>
        </thead>

        <tbody>
          {accLoading ? (
            <>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((row, i) => (
                <tr key={i} className="h-[50px]  border-b-[1px] border-r-[1px] text-ss text-[#151515] font-[500]">
                  {searchedData?.map((col, i) => (
                    <td key={i} className="pl-4 border-l-[1px]">
                      <Skeleton />
                    </td>
                  ))}
                </tr>
              ))}
            </>
          ) : (
            <>
              {accLedgerData &&
                accLedgerData.map((row, i) => (
                  <tr key={i} className="h-[50px] border-b-[1px] text-s text-[#151515] font-[500]">
                    {accColumns?.map((col, i) => (
                      <td key={i} className="pl-4 border-l-[1px]">
                        {row[col.field]}
                      </td>
                    ))}
                  </tr>
                ))}
            </>
          )}
        </tbody>
      </table>

      <div className="flex justify-between w-[90%] mt-[2em]">
        <div>
          {accLoading ? (
            <Skeleton />
          ) : (
            <p>
              {accLedgerBatch} of {Math.ceil(totalDoc / 10)}
            </p>
          )}
        </div>

        <div className="flex space-x-2">
          <GreyButton text="Prev" onClick={() => handlePagination("prev")} />
          <GreyButton text="Next" onClick={() => handlePagination("next")} />
        </div>
      </div>
    </div>
  );
};

export default WorkspaceHistory;
