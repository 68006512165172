import React, { useEffect, useState } from "react";
import certificate from "assets/images/account/certificate.png";
import ImagePreview from "./ImagePreview/index";
import { byteConverter } from "lib/byteConverter";
import { uploadDocument } from "utils/upload";
import { BsFileImage } from "react-icons/bs";
import { ScaleLoader } from "react-spinners";
import { useMutation } from "@tanstack/react-query";
import { updateBusiness } from "services/userService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import { FiLoader } from "react-icons/fi";
import { TiTick } from "react-icons/ti";
import { useStore } from "store";
import SecondaryInput from "components/Inputs/SecondaryInput";
import SelectInput from "components/Inputs/SelectInput";
import PurpleState from "assets/images/purpledrop.png";
import { proofData } from "./data";
import BlueButton from "components/Button/BlueButton";
import WarningCard from "components/WarningCard";
import { Link } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";

const ComplianceTab = ({ user }) => {
  const { onBoarding } = useStore();
  const [rcNumber, setRcNumber] = useState("");
  const [openModal, setOpenModal] = useState();
  const [fileData, setFileData] = useState({
    incopCert: user?.data?.incopCert?.pathToFile,
    proofOfBusAdd: user?.data?.proofOfBusAdd?.pathToFile,
    incopCertName: "",
    proofOfBusAddName: "",
    fileType: user?.data?.proofOfBusAdd?.documentType,
  });

  const [switchTab, setSwitchTab] = useState(false);

  console.log(user, "user");

  const checks = [
    {
      key: "incopCert",
      name: "Certificate of incorporation",
      condition: Object.keys(user?.data?.incopCert || {}).length >= 2,
      value: user?.data?.incopCert?.pathToFile,
      status: user?.data?.incopCert?.status,
    },
    {
      key: "proofOfBusAdd",
      name: "Proof of business address",
      condition: Object.keys(user?.data?.proofOfBusAdd || {}).length >= 2,
      value: user?.data?.proofOfBusAdd?.pathToFile,
      status: user?.data?.proofOfBusAdd?.status,
    },
    {
      key: "cacDocument",
      name: "CAC document",
      condition: Object.keys(user?.data?.cacDocument || {}).length >= 2,
      value: user?.data?.cacDocument?.pathToFile,
      status: user?.data?.cacDocument?.status,
    },
    {
      key: "mou",
      name: "Memorandum of understanding",
      condition: Object.keys(user?.data?.mou || {}).length >= 2,
      value: user?.data?.mou?.pathToFile,
      status: user?.data?.mou?.status,
    },
    {
      key: "rcNumber",
      name: "Rc Number",
      condition: user?.data?.rcNumber?.status === "approved",
      value: user?.data?.rcNumber?.value,
      status: user?.data?.rcNumber?.status,
    },
  ];

  const trueChecks = checks.filter((check) => check.condition).map((check) => check);

  useEffect(() => {
    trueChecks?.length > 0 ? setSwitchTab(true) : setSwitchTab(false);
  }, [trueChecks?.length]);

  console.log(trueChecks, "available");

  const toggleModal = (id, x, y) => {
    setOpenModal(openModal === id ? null : id);
  };

  const { mutate } = useMutation({
    mutationFn: updateBusiness,
    onSuccess: () => {
      Toast.success("Document uploaded successfully");
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const saveDoc = () => {
    const userData = {
      incopCert: fileData.incopCert,
      proofOfBusAdd: {
        path: fileData.proofOfBusAdd,
        documentType: fileData.fileType,
      },
      rcNumber: rcNumber,
    };

    mutate({ complianceDocuments: userData });
  };

  const checkSelect = async (e, x) => {
    let file = e.target.files[0];
    let formData = new FormData();
    if (x === "incopCert") {
      setFileData({
        ...fileData,
        incopCertName: file,
      });
      formData.append("document", file);
      const response = await uploadDocument(formData);
      setFileData({ ...fileData, incopCert: `https://${response?.data?.filePath}`, incopCertName: file });
    } else if (x === "proofOfBusAdd") {
      setFileData({
        ...fileData,
        proofOfBusAddName: file,
      });
      formData.append("document", file);
      const response = await uploadDocument(formData);
      setFileData({ ...fileData, proofOfBusAdd: `https://${response?.data?.filePath}`, proofOfBusAddName: file });
    }
  };

  const checkCompliance = (x) => {
    console.log("compliance:", typeof x);
    if (!x) {
      return (
        <div className="bg-[#FFFAEB] text-[#B54708] min-w-[95px] h-[24px] flex items-center justify-center rounded-[30px] space-x-1">
          <p className="text-s">Pending</p>
          <FiLoader className="text-ss" />
        </div>
      );
    }
    return (
      <div className="bg-[#ECFDF3] text-[#027A48] min-w-[95px] h-[24px] flex items-center justify-center rounded-[30px] space-x-1">
        <p className="text-s">Verified</p>
        <TiTick className="text-ss" />
      </div>
    );
  };

  return !switchTab ? (
    <div className="flex-1 h-[80vh] overflow-y-auto bg-white w-full p-5 rounded-[8px]">
      <p className="flex items-center space-x-2 text-primary-800 my-2" onClick={() => setSwitchTab(!switchTab)}>
        <span>
          <IoIosArrowRoundBack />
        </span>{" "}
        Go back
      </p>
      <p className="text-[#292D32] font-semibold text-[20px] flex items-center">
        Provide your company registration information below.
        {/* <span className="pl-2">{checkCompliance(onBoarding.compliance)}</span> */}
      </p>
      <p className="text-[#737373] text-s font-[450] mt-2">We would send you an update you on its status.</p>
      <hr />
      <div className="w-full my-2">
        <WarningCard
          heading="NOTE:"
          info={
            <p>
              If you have any issues with your documentation please contact{" "}
              <Link to="mailto:support@usemooyi.com" className="text-primary-800">
                support@usemooyi.com.
              </Link>
            </p>
          }
        />
      </div>

      <div>
        <div className="w-[80%] mt-[2em]">
          <div className="grid grid-cols-2 gap-4">
            <div className="mt-2">
              <p className="text-[#292D32] text-m font-[500] tracking-[-0.02em]">Certificate of Incorporation</p>

              <div className="flex space-x-2 items-center">
                <div className="flex space-x-4 items-center my-2 w-full">
                  {!fileData.incopCertName && fileData.incopCert === undefined ? (
                    <div className="bg-[#FAFAFA] h-[40px] border-[1px] border-[#DFE5EC] rounded-[8px]  w-full flex items-center px-[1em]">
                      <div className="border-[1px] border-[#DFE5EC] shadow-xl bg-white w-[100px] h-[30px] flex items-center justify-center rounded-[5px] relative text-ss overflow-hidden">
                        <p className="font-[500]">Choose file</p>
                        <input
                          type="file"
                          name="incopCert"
                          id=""
                          accept=".jpg, .jpeg, .png"
                          className="text-ss absolute top-0 left-0 bottom-0 right-0 opacity-0"
                          onChange={(e) => checkSelect(e, "incopCert")}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="flex  w-full  border-[1px] border-[#DFE5EC] rounded-[8px]  h-[60px] items-center px-4 justify-between">
                        <div className="flex items-center space-x-4">
                          <div className="bg-[#29085C1A] text-primary-800 w-[40px] h-[40px] rounded-full flex items-center justify-center ">
                            <BsFileImage />
                          </div>

                          <div>
                            <p className="text-s ">
                              {fileData.incopCert === ""
                                ? fileData?.incopCertName?.name?.substring(0, 40)
                                : fileData?.incopCert?.substring(0, 40)}
                              {fileData.incopCert ? "..." : <ScaleLoader width={2} height={15} color="#29085C" />}
                            </p>
                            <p className="text-ss text-[#A3A3A3]">{byteConverter(fileData?.incopCertName?.size)}</p>
                          </div>
                        </div>

                        <p
                          className="text-s text-primary-800 cursor-pointer"
                          onClick={() => toggleModal(2, fileData?.incopCert, "Certificate of Incorporation")}
                        >
                          View
                        </p>
                      </div>
                    </>
                  )}

                  {onBoarding.compliance === false && fileData.incopCert ? (
                    <p
                      className="text-s text-primary-800 cursor-pointer"
                      onClick={() =>
                        setFileData((prevState) => ({ ...prevState, incopCert: undefined, incopCertName: undefined }))
                      }
                    >
                      Remove
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                {user?.data?.incopCert?.status === "rejected" ? (
                  <p
                    className="text-s text-primary-800 min-w-[100px] underline underline-offset-4 cursor-pointer"
                    onClick={() => toggleModal(1)}
                  >
                    See reason
                  </p>
                ) : null}
              </div>

              {/* <p>{fileData.incopCertName}</p> */}
            </div>

            <div className="flex space-x-4 items-center w-full">
              <div className="w-full">
                <SecondaryInput
                  text="RC Number (Optional)"
                  placeholder="RC Number (Optional)"
                  onChange={(e) => setRcNumber(e.target.value)}
                  name="rcNumber"
                  type="text"
                  value={rcNumber}
                />
              </div>

              <div className="flex items-center justify-center h-[100%] mt-[1.2em]">
                {/*} {checkStatus(user?.data?.rcNumber?.status)}*/}
              </div>
            </div>
            <div>
              <p>Proof of address</p>
              <div className="flex space-x-2 items-center w-full">
                <div className="flex justify-between items-center w-full ">
                  <div className="w-full">
                    <SelectInput
                      options={proofData}
                      onChange={(e) =>
                        setFileData({
                          ...fileData,
                          fileType: e.target.value,
                        })
                      }
                      css="w-full"
                      value={fileData.fileType}
                      name="fileType"
                    />
                  </div>

                  <div className="flex items-center justify-center h-[100%]">
                    {/*{checkStatus(user?.data?.proofOfBusAdd?.status)}*/}
                  </div>
                </div>

                {user?.data?.proofOfBusAdd?.status === "rejected" ? (
                  <p
                    className="text-s text-primary-800 min-w-[100px] underline underline-offset-4 cursor-pointer"
                    onClick={() => toggleModal(1)}
                  >
                    See reason
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="w-[60%]">
          <div className="flex items-center">
            {!fileData.proofOfBusAddName && fileData.proofOfBusAdd === undefined ? (
              <div className="border-[1px] border-dashed border-[#D0D5DD] rounded-[8px] h-[120px] w-[500px] mt-2 bg-[#FAFAFA] flex flex-col items-center text-ss justify-center text-[#737373] space-y-2 relative">
                <img src={PurpleState} alt="PurpleState" className="w-[60px]" />
                <p>Drag and drop PNG or JPEG file or browse computer </p>
                <p>Max file size: 10MB</p>
                <input
                  type="file"
                  name="proofOfBusAdd"
                  id=""
                  className="text-ss absolute top-0 left-0 bottom-0 right-0 opacity-0"
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => checkSelect(e, "proofOfBusAdd")}
                />
              </div>
            ) : (
              <div className="flex  w-[500px]  border-[1px] border-[#DFE5EC] rounded-[8px]  h-[60px] items-center px-4 justify-between">
                <div className="flex items-center space-x-4">
                  <div className="bg-[#29085C1A] text-primary-800 w-[40px] h-[40px] rounded-full flex items-center justify-center ">
                    <BsFileImage />
                  </div>

                  <div>
                    <p className="text-s">
                      {fileData.proofOfBusAdd === ""
                        ? fileData?.proofOfBusAddName?.name?.substring(0, 40)
                        : fileData.proofOfBusAdd?.substring(0, 40)}
                      {fileData.proofOfBusAdd ? "..." : <ScaleLoader width={2} height={15} color="#29085C" />}
                    </p>
                    <p className="text-ss text-[#A3A3A3]">{byteConverter(fileData?.proofOfBusAddName?.size)}</p>
                  </div>
                </div>

                <p
                  className="text-s text-primary-800 cursor-pointer"
                  onClick={() => toggleModal(2, fileData.proofOfBusAdd, "Proof of address")}
                >
                  View
                </p>
              </div>
            )}
            {onBoarding.compliance === false && fileData.proofOfBusAdd ? (
              <p
                className="text-s text-primary-800 cursor-pointer pl-3"
                onClick={() =>
                  setFileData((prevState) => ({
                    ...prevState,
                    proofOfBusAdd: undefined,
                    proofOfBusAddName: undefined,
                  }))
                }
              >
                Remove
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {trueChecks?.length > 0 && (
        <div className="mt-10">
          <BlueButton text="Save" type="button" onClick={saveDoc} />
        </div>
      )}
    </div>
  ) : (
    <ImagePreview data={trueChecks} setSwitchTab={setSwitchTab} switchTab={switchTab} />
  );
};

export default ComplianceTab;
