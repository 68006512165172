import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "utils/typography";
import Tooltip from "components/Tooltip";
import BlueButton from "components/Button/BlueButton";
import researchGif from "assets/images/researchGif.png";
import OverviewTab from "./components/OverviewTab";
import totalScore from "assets/images/totalScore.png";
import promoters from "assets/images/promoters.png";
import passives from "assets/images/passives.png";
import completionRate from "assets/images/completionRate.png";
import docIcon from "assets/images/docIcon.png";
import detractors from "assets/images/detractors.png";
import dropOffRate from "assets/images/dropOffRate.png";
import { useInsightOverview } from "hooks/useInsightOverview";

import SingleOverviewCard from "./components/SingleOverviewCard";
import CustomerProfilingOverview from "./components/overviewTabs/CPOverview";
import NPSOverview from "./components/overviewTabs/NPSOverview";
import CSATOverview from "./components/overviewTabs/CSATOverview";

const tabs = [
  { id: 1, name: " Customer Profiling" },
  { id: 2, name: "NPS Survey" },
  { id: 3, name: "Customer Satisfaction" },
];

const Home = () => {
  const [step, setStep] = useState(1);
  const [onlineSummary, setOnlineSummary] = useState([]);
  const [npsOnlineSummary, setNPSOnlineSummary] = useState([]);
  const [csatOnlineSummary, setCSATOnlineSummary] = useState([]);

  const navigate = useNavigate();
  const { data: cpData, isLoading: cpIsLoading } = useInsightOverview("cp");
  const { data: npsData, isLoading: npsIsLoading } = useInsightOverview("nps");
  const { data: csatData, isLoading: csatIsLoading } = useInsightOverview("csat");

  // console.log( "data ::" , cpData)

  const handleTabChange = (e) => {
    setStep(e);
  };

  const getQuestion = (data, searchKey) => {
    if (!data) return null;

    const result = Object.entries(data).filter(([key, value]) => key.includes(searchKey));

    return result ? result[0][1] : null;
  };

  const getQuestionByType = (data, questionType) => {
    const analytics =
      data &&
      Object.entries(data).filter(([key, value]) => {
        if (value.questionType === questionType) {
          return value.analytics;
        }
        return null;
      });
    return analytics;
  };

  const getTopAudienceLocation = (analytics) => {
    const country = Object.keys(analytics[0][1].analytics)[0]; //pick one country '"Nigeria"
    const states = analytics[0][1].analytics[country];
    const largest = Math.max(...Object.values(states));

    return largest;
  };

  const genderObj = getQuestion(cpData, "gender");
  const totalMale = genderObj ? genderObj.analytics?.Male?.value : 0;
  const totalFemale = genderObj ? genderObj.analytics?.Female?.value : 0;

  const qtyByType = getQuestionByType(cpData, "location");
  console.log("qtyByType ::", qtyByType);
  const topAudienceLocation = qtyByType && qtyByType.length > 0 ? getTopAudienceLocation(qtyByType) : 0;

  useEffect(() => {
    if (cpData) {
      setOnlineSummary([
        {
          id: "onlineSummary1",
          name: "Total male",
          image: docIcon,
          value: totalMale,
        },
        {
          id: "onlineSummary2",
          name: "Total female",
          image: completionRate,
          value: totalFemale,
        },
        {
          id: "onlineSummary3",
          name: "Total cities",
          image: dropOffRate,
          value: topAudienceLocation,
        },
      ]);
    }
  }, [cpData]);

  useEffect(() => {
    if (npsData) {
      const summaryData = npsData?.totalNpsMetrics;
      setNPSOnlineSummary([
        {
          id: "npsScore",
          name: "NPS score",
          image: totalScore,
          value: summaryData?.npsScore ?? 0,
          color: "text-primary-800",
        },
        {
          id: "promoter",
          name: "Promoters",
          image: promoters,
          value: summaryData?.promoter ?? 0,
          color: "text-green-500",
        },
        {
          id: "passive",
          name: "Passives",
          image: passives,
          value: summaryData?.passive ?? 0,
          color: "text-yellow-500",
        },
        {
          id: "detractor",
          name: "Detractors",
          image: detractors,
          value: summaryData?.detractor ?? 0,
          color: "text-red-500",
        },
      ]);
    }
  }, [npsData]);

  useEffect(() => {
    if (csatData) {
      const summaryData = csatData?.totalCsatMetrics;
      setCSATOnlineSummary([
        {
          id: "onlineSummary1",
          name: "CSAT score",
          image: totalScore,
          value: summaryData?.csatScore ?? 0,
        },
        {
          id: "onlineSummary2",
          name: "Satisfied",
          image: promoters,
          value: summaryData?.satisfied ?? 0,
        },
        {
          id: "onlineSummary3",
          name: "Neutral",
          image: passives,
          value: summaryData?.neutral ?? 0,
        },
        {
          id: "onlineSummary3",
          name: "Unsatisfied",
          image: detractors,
          value: summaryData?.unsatisfied ?? 0,
          color: "text-red-500",
        },
      ]);
    }
  }, [csatData]);

  //console.log("cpData ::", cpData)

  const displayStep = () => {
    switch (step) {
      case 1:
        return <CustomerProfilingOverview nameKey="customerProfile" overViewData={{ onlineSummary, cpData }} />;
      case 2:
        return <NPSOverview overViewData={npsOnlineSummary} graphData={npsData?.npsHistory} isLoading={npsIsLoading} />;
      case 3:
        return (
          <CSATOverview overViewData={csatOnlineSummary} graphData={csatData?.csatHistory} isLoading={csatIsLoading} />
        );

      default:
    }
  };

  return (
    <div className="">
      <div className="border-[1px] rounded-lg h-[240px] w-[100%] bg-white ">
        <div className="bg-contactBg bg-cover h-[100%] flex items-center justify-center ">
          <div className="w-[40%] space-y-4">
            <Typography.H3 className="font-semibold text-primary-800">Get insights from your customers</Typography.H3>

            <Typography.SubText className="w-[70%]">
              Know your audience's needs, preferences, and feedback to create meaningful interactions that drive loyalty
              and growth.
            </Typography.SubText>

            <BlueButton text="Get started" onClick={() => navigate("setup")} />
          </div>

          <div className="w-[40%] h-[100%]">
            <img src={researchGif} alt="researchGif" className="h-[100%]" />
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-2 pt-5">
        <Typography.Text>Overview</Typography.Text>
        <Tooltip text="This shows a summary of the number of surveys sent, average completion and drop-off rates." />
      </div>

      <div className="flex space-x-4 mt-4">
        {tabs.map((el) => {
          return (
            <Typography.SubText
              className={` pb-[4px] cursor-pointer ${
                step === el.id ? "border-b-[3px] text-[#0F172A] border-primary-800 font-semibold " : "text-[#6C6685]"
              } `}
              onClick={el.id !== 4 ? () => handleTabChange(el.id) : null}
              key={el.id}
            >
              {el.name}
            </Typography.SubText>
          );
        })}
      </div>
      <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

      <div>{displayStep()}</div>
    </div>
  );
};

export default Home;
