import ModalWithoutClose from "components/ModalWithoutClose";
import { FaTimes } from "react-icons/fa";
import BlueButton from "components/Button/BlueButton";

const SurveyModals = ({
  children,
  title,
  setShowModal,
  setAction,
  yesBtnText,
  noBtnText,
  actionType,
  img,
  isLoading,
}) => {
  return (
    <ModalWithoutClose>
      <div className="w-[500px] h-auto  bg-white rounded-xl p-0 py-6 ">
        <div className="flex flex-col gap-10 h-full">
          <div className="flex flex-row space-between">
            <div className="flex flex-row border-0 w-[90%] items-center px-5">
              <p className="text-[20px] font-[500]">{title}</p>
            </div>
            <div
              className="text-[#737373] bg-[#ECECECB2] h-[30px] w-[30px] flex items-center justify-center rounded-full text-[20px] cursor-pointer"
              onClick={() => setShowModal(false)}
            >
              <FaTimes className="w-[10px] " />
            </div>
          </div>

          <div className="flex flex-col gap-10 items-center justify-center">
            <div
              className={`flex w-full items-center justify-center py-4 ${
                actionType === "close" ? "bg-red-50" : "bg-blue-50"
              }`}
            >
              <img src={img} alt="" />
            </div>
            {children}
          </div>

          <div className="flex flex-row gap-5 items-center justify-center border-t border-gray-100 p-2">
            <button
              className="bg-blue-50 text-blue-700 font-[500] p-2 rounded-md w-[120px]"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <BlueButton text={yesBtnText} onClick={setAction} loading={isLoading} />
          </div>
        </div>
      </div>
    </ModalWithoutClose>
  );
};

export default SurveyModals;
