import { useQuery } from "@tanstack/react-query";
import {
  getInsightOverview,
  getInsightInhibitAction,
  fetchCSV,
  getInsightOverviewPublic,
} from "services/insightService";

const useInsightOverview = (insightType, publishId) => {
  const { data, isLoading } = useQuery({
    queryKey: ["getInsightOverview", insightType, publishId],
    queryFn: () => {
      return getInsightOverview(insightType, publishId);
    },
  });

  return {
    data,
    isLoading,
  };
};

const useInsightInhibitAction = (insightId, action) => {
  const { data, refetch, isError, isLoading } = useQuery({
    queryKey: ["getInsightInhibitAction", insightId, action],
    queryFn: () => {
      return getInsightInhibitAction(insightId, action);
    },
    enabled: false,
    onSuccess: () => {},
  });

  return {
    data,
    refetch,
    isError,
    isLoading,
  };
};

const useFetchInsightCSV = (insightType, publishId) => {
  const { data, refetch } = useQuery({
    queryKey: ["fetchCSV", insightType, publishId],
    queryFn: () => fetchCSV(insightType, publishId),

    enabled: false,
    onSuccess: () => {},
  });

  return {
    data,
    refetch,
  };
};

const useInsightOverviewPublic = (insightType, workspaceId) => {
  const { data, isLoading } = useQuery({
    queryKey: ["getInsightOverviewPublic", insightType, workspaceId],
    queryFn: () => {
      return getInsightOverviewPublic(insightType, workspaceId);
    },
  });

  return {
    data,
    isLoading,
  };
};

export { useInsightOverview, useInsightInhibitAction, useFetchInsightCSV, useInsightOverviewPublic };
