export const statusColor = (x) => {
  switch (x) {
    case "Declined":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#E24444] bg-[#FCCDCD] justify-center rounded-xl py-[2px]">
          <p>Declined</p>
        </p>
      );
    case "pending":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#AA8609] bg-[#FAECBA] justify-center rounded-xl py-[2px]">
          <p> Pending</p>
        </p>
      );
    case "Verified":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#12B76A] bg-[#B2F9C8] justify-center rounded-xl py-[2px]">
          <p> Verified</p>
        </p>
      );
    case "approved":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#12B76A] bg-[#B2F9C8] justify-center rounded-xl py-[2px]">
          <p> Approved</p>
        </p>
      );
    case "Active":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#12B76A] bg-[#B2F9C8] justify-center rounded-xl py-[2px]">
          <p> Active</p>
        </p>
      );
    case "Closed":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#E24444] bg-[#FCCDCD] justify-center rounded-xl py-[2px]">
          <p>Closed</p>
        </p>
      );
    case "Failed":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#E24444] bg-[#FCCDCD] justify-center rounded-xl py-[2px]">
          <p>Failed</p>
        </p>
      );
    case "Inactive":
      return (
        <p className="text-[11px]  flex gap-1 items-center text-[#E24444] bg-[#FCCDCD] justify-center rounded-xl py-[2px]">
          <p>Inactive</p>
        </p>
      );

    default:
  }
};
