import React, { useState } from "react";
import moment from "moment";
import { getTemplateEmail } from "services/externalService";
import { useQuery } from "@tanstack/react-query";
import Skeleton from "react-loading-skeleton";
import Typography from "utils/typography";

const Details = ({ data }) => {
  const templateUrl = JSON.parse(data?.campaignContent).filePath;
  const { data: htmlFile, isLoading } = useQuery({
    queryKey: ["getTemplateEmail", templateUrl],
    queryFn: () => getTemplateEmail(templateUrl),
  });

  // console.log(htmlFile?.data, "html content");
  console.log(data);

  console.log("data", templateUrl);
  return (
    <div>
      <div className="bg-white rounded-md border-[1px]">
        <div className="w-full h-[60px] flex justify-center items-center px-5  bg-white border-b-[1px]">
          <Typography>Email Details</Typography>
        </div>

        <div className="flex items-start justify-between gap-4 p-4">
          <div className="w-[100%] p-5 space-y-3 bg-white">
            <div className="text-m flex justify-between border-b-[1px] p-[12px]">
              <p className="w-[30%] text-[#525252] font-[400]">Delivery date & time</p>
              <p className=" text-black font-[500]">{moment(data?.campaignDate).format("MMMM Do YYYY, h:mm:ss a")}</p>
            </div>

            <div className="text-m flex justify-between border-b-[1px] p-[12px]">
              <p className="w-[30%] text-[#525252] font-[400]">Subject</p>
              <p className=" text-black font-[500]">{data?.campaignSubject}</p>
            </div>

            <div className="text-m flex justify-between border-b-[1px] p-[12px]">
              <p className="w-[30%] text-[#525252] font-[400]">Sender ID</p>
              <p className=" text-black font-[500]">{data?.senderId}</p>
            </div>

            <div className="text-m flex justify-between border-b-[1px] p-[12px]">
              <p className="w-[30%] text-[#525252] font-[400]">Objective</p>
              <p className=" text-black font-[500]">{data?.campaignObjective}</p>
            </div>

            <div className="text-m flex justify-between p-[12px] items-center">
              <p className="w-[30%] text-[#525252] font-[400]">Audience</p>
              <div className="flex space-x-4">
                {data?.audience?.personalContacts?.map((el, i) => {
                  return (
                    <p key={i} className="text-[12px] p-2 bg-gray-200 rounded-md">
                      {el}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[2rem] rounded-md border-[1px] bg-white">
        <div className=" border-[1px] rounded-md h-[100%] overflow-hidden">
          <div className="w-full h-[60px] flex justify-center items-center px-5  bg-white border-b-[1px]">
            <Typography>Preview</Typography>
          </div>

          {isLoading ? (
            <div className="p-4">
              <Skeleton className="h-[100px]" />
              <Skeleton className="h-[50px]" />
              <Skeleton className="h-[40px]" />
              <Skeleton className="h-[30px]" />
              <Skeleton className="h-[30px]" />
              <Skeleton className="h-[30px]" />
              <Skeleton className="h-[20px]" />
              <Skeleton className="h-[15px]" />
              <Skeleton className="h-[15px]" />
              <Skeleton className="h-[20px]" />
              <Skeleton className="h-[20px]" />
              <Skeleton className="h-[15px]" />
              <Skeleton className="h-[15px]" />
            </div>
          ) : (
            <div className="html-container mt-10">
              <div dangerouslySetInnerHTML={{ __html: htmlFile?.data }} className="w-[90%]" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Details;
