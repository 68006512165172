import React, { useState } from "react";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import Logo from "assets/images/newLogo.png";
import {
  CalenderIcon,
  ContactIcon,
  FieldIcon,
  HomeIcon,
  MessagingIcon,
  SettingsIcon,
  SurveyIcon,
} from "assets/icons/sidebar";

import { HiChevronDown } from "react-icons/hi2";
import Skeleton from "react-loading-skeleton";
import useOnclickOutside from "react-cool-onclickoutside";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { roleChecker } from "lib/roleChecker";
import BlueButton from "./Button/BlueButton";
import useLoggedInUser from "hooks/useLoggedInUser";
import useWorkspace from "hooks/useWorkspace";
import { cn } from "helpers/classHelpers";
import { InsightIcon } from "assets/icons/sidebar";

const tabs = [
  { id: 1, name: "Home", icons: HomeIcon, path: "/dashboard" },

  // { id: 8, name: "Customer Insight", icons: ContactIcon, path: "/customer-insight" },
];

const cTabs = [{ id: 7, name: "Calendar", icons: CalenderIcon, path: "/calendar" }];

const askTabs = [
  { id: 1, name: "Online surveys", icons: SurveyIcon, link: "/research/online-survey" },
  { id: 2, name: "Field interviews", icons: FieldIcon, link: "/research/field-interview" },
  // { id: 3, name: "Brand tracking", icons: BrandTracking, link: "/research/brand-tracking" },
];

const insightTab = [
  { id: 1, name: "Customer profiling", icons: InsightIcon, link: "/insights/customer-profile" },
  { id: 2, name: "NPS", icons: InsightIcon, link: "/insights/nps" },
  { id: 3, name: "CSAT", icons: InsightIcon, link: "/insights/csat" },
];

const contactTabs = [
  { id: 1, name: "All contacts", link: "/contacts/all-contacts?tab=0" },
  { id: 2, name: "All leads", link: "/contacts/all-contacts?tab=1" },
  { id: 3, name: "Tags", link: "/contacts/all-contacts?tab=2" },
  { id: 4, name: "Segments", link: "/contacts/all-contacts?tab=3" },
  // { id: 5, name: "Custom fields", link: "/contacts/all-contacts?tab=4" },
];

const activeStyle =
  "text-primary-800 bg-primary-50 flex items-center rounded-[6px]  px-4 py-[10px]  flex-row  justify-start text-left  w-auto text-s cursor-pointer ";
const nonActiveStyle =
  "flex text-s text-neutral-700  items-center rounded px-4 py-[10px] text-center sm:justify-start justify-center sm:w-auto w-[25%] hover:text-primary-800 hover:bg-primary-50 cursor-pointer";

// const engageTabs = [
//   { id: 1, name: "Messaging", icons: MessagingIcon, path: "/messaging" },
//   // { id: 2, name: "Pages", icons: PagesIcon, path: "/pages" },
// ];

const settingsTabs = [{ id: 1, name: "Settings", icons: SettingsIcon, path: "/settings" }];

const OpenSidebar = () => {
  const [open, setOpen] = useState(null);
  const { data } = useWorkspace();
  const [dropDown, setDropdown] = useState(null);
  // const [otherDropdown, setOtherDropDown] = useState(null);
  const [noticed, setNoticed] = useState(false);
  const [selected] = useState(false);
  const [searchParams] = useSearchParams();
  const { user } = useLoggedInUser();

  const toggleDropdown = (id) => {
    setOpen(open === id ? null : id);
  };

  // const handleDropDown = useCallback((id) => {
  //   setOtherDropDown((prevDropDown) => (prevDropDown === id ? null : id));
  // }, []);

  const handleHover = (id) => {
    setNoticed(noticed === id ? null : id);
  };

  const refer = useOnclickOutside(toggleDropdown);

  return (
    <div className="h-[100%] overflow-y-scroll no-scrollbar">
      <Link to="/">
        <img src={Logo} alt="logo" width={135} />
      </Link>

      <div className="border-[1px] rounded-[12px] my-[1.5em] bg-white relative cursor-pointer workspace">
        <div className="flex justify-between p-2 hover:bg-[#EBEBEB] rounded-t-[10px]" onClick={() => toggleDropdown(1)}>
          <div className="flex space-x-2 ">
            {data?.wsProfile === null || data?.wsProfile === undefined ? (
              <p className="bg-black rounded-full min-w-[34px] h-[34px] text-white flex items-center justify-center text-ss font-semibold">
                {data?.name?.slice(0, 2).toUpperCase()}
              </p>
            ) : !data?.wsProfile?.includes("https://") ? (
              <div className="h-[34px] w-[34px] overflow-hidden rounded-full shadow-md border-[1px]">
                <img src={`https://${data?.wsProfile}`} alt="" className="w-[100%] h-[100%] object-cover " />
              </div>
            ) : (
              <p className="bg-black rounded-full min-w-[34px] h-[34px] text-white flex items-center justify-center text-ss font-[500]">
                {data?.name?.slice(0, 2).toUpperCase()}
              </p>
            )}
            <div>
              <p className="font-semibold text-s"> {data?.name || <Skeleton />} </p>
              <p className="text-ss text-[#16A34A]">{roleChecker(user?.data?.role)}</p>
            </div>
          </div>
          <HiChevronDown className="text-m mt-[2px] cursor-pointer" />
        </div>
        <Link
          to="/settings?g=invite"
          className="flex space-x-2 p-2 border-t-[1px] items-center text-s hover:bg-[#EBEBEB] rounded-b-[10px]"
        >
          <ContactIcon fill1="#D4D4D4" fill2="#404040" />
          <p>Invite teammates</p>
        </Link>

        {open === 1 && (
          <div
            className="shadow-secondary absolute top-[2em] right-0 bg-white z-[99] w-[170px] rounded-[6px] text-center py-4"
            ref={refer}
          >
            <div>
              {data?.wsProfile === null || data?.wsProfile === undefined ? (
                <p className="bg-black rounded-full w-[34px] h-[34px] text-white flex items-center justify-center text-ss font-semibold mx-auto">
                  {data?.name?.slice(0, 2).toUpperCase()}
                </p>
              ) : !data?.wsProfile?.includes("https://") ? (
                <div className="h-[34px] w-[34px] overflow-hidden rounded-full mx-auto shadow-md border-[1px]">
                  <img src={`https://${data?.wsProfile}`} alt="" className="w-[100%] h-[100%] object-cover" />
                </div>
              ) : (
                <p className="bg-black rounded-full w-[34px] h-[34px] text-white flex items-center justify-center text-ss font-semibold  mx-auto">
                  {data?.name?.slice(0, 2).toUpperCase()}
                </p>
              )}
              <p className=" text-s">{data?.name}</p>
              <p className="text-ss py-[1em]">
                {data?.adminCount} Member{data?.adminCount > 1 ? "s" : ""}
              </p>

              <Link to="/organization">
                <BlueButton text="Switch Workspace" className="w-[150px] h-[35px] text-ss px-[0]" />
              </Link>
            </div>
          </div>
        )}
      </div>

      <div className={` flex items-center flex-col space-y-2 `}>
        {tabs.map((el) => {
          const IconComponent = el.icons;

          return (
            <NavLink
              to={el.path}
              key={`${el.id}--appRoute`}
              className={cn("hover:text-secondary w-[100%]", el.name)}
              onMouseEnter={() => handleHover(el.id)}
              onMouseLeave={() => handleHover(el.id)}
            >
              {({ isActive }) => (
                <div className={isActive ? activeStyle : nonActiveStyle}>
                  {noticed === el.id ? (
                    <IconComponent fill1="#95ADFF" fill2="#1D19AF" />
                  ) : (
                    <IconComponent fill1={isActive ? "#95ADFF" : "#D4D4D4"} fill2={isActive ? "#1D19AF" : "#404040"} />
                  )}

                  <IconComponent fillColor={isActive ? "fill-secondary" : "fill-white hover:fill-secondary"} />
                  <p className="text-sm">{el.name}</p>
                </div>
              )}
            </NavLink>
          );
        })}
      </div>
      <div
        key={`contacts--appRoute`}
        className="hover:text-secondary w-[100%] mt-2 contactTabs"
        onMouseEnter={() => handleHover(10)}
        onMouseLeave={() => handleHover(10)}
      >
        <NavLink
          to="/contacts"
          onClick={() => {
            dropDown === 1 ? setDropdown(null) : setDropdown(1);
          }}
        >
          {({ isActive }) => (
            <div className={selected || isActive ? activeStyle : nonActiveStyle}>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center">
                  {selected || noticed === 10 ? (
                    <ContactIcon fill1="#95ADFF" fill2="#1D19AF" />
                  ) : (
                    <ContactIcon
                      fill1={selected || isActive ? "#95ADFF" : "#D4D4D4"}
                      fill2={selected || isActive ? "#1D19AF" : "#404040"}
                    />
                  )}

                  <ContactIcon fillColor={selected ? "fill-secondary" : "fill-white hover:fill-secondary"} />
                  <p className="text-sm">Contacts</p>
                </div>
                {dropDown === 1 ? (
                  <div>
                    <IoIosArrowUp />
                  </div>
                ) : (
                  <div>
                    <IoIosArrowDown />
                  </div>
                )}
              </div>
            </div>
          )}
        </NavLink>
      </div>
      {dropDown === 1 ? (
        <div className="flex flex-col place-self-start w-[90%] mx-auto text-sm mt-2 space-y-1">
          {contactTabs.map((el, id) => (
            <NavLink to={el.link} key={`${el.id}--appContactRoute`} className="hover:text-secondary w-[100%]">
              <div
                className={
                  searchParams.get("tab") === `${id}`
                    ? "text-primary-800 bg-primary-50 flex items-center rounded-[6px]  px-4 py-[10px]  flex-row  justify-start text-left  w-auto text-s cursor-pointer"
                    : "flex text-s text-neutral-700  items-center rounded px-4 py-[10px] text-center sm:justify-start justify-center sm:w-auto w-[25%] hover:text-primary-800 hover:bg-primary-50 cursor-pointer"
                }
              >
                <p className="text-sm">{el.name}</p>
              </div>
            </NavLink>
          ))}
        </div>
      ) : null}

      <div>
        {/* <p className="border-b-[1px] px-4 my-2 text-sm text-neutral-500">ASK</p> */}

        <div
          key={`insight--appRoute`}
          className="hover:text-secondary w-[100%] insights"
          onMouseEnter={() => handleHover(12)}
          onMouseLeave={() => handleHover(12)}
        >
          <NavLink
            to="/insights"
            onClick={() => {
              dropDown === 3 ? setDropdown(null) : setDropdown(3);
            }}
          >
            {({ isActive }) => (
              <div className={selected || isActive ? activeStyle : nonActiveStyle}>
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    {selected || noticed === 12 ? (
                      <InsightIcon fill1="#95ADFF" fill2="#1D19AF" />
                    ) : (
                      <InsightIcon
                        fill1={selected || isActive ? "#95ADFF" : "#D4D4D4"}
                        fill2={selected || isActive ? "#1D19AF" : "#404040"}
                      />
                    )}

                    <ContactIcon fillColor={selected ? "fill-secondary" : "fill-white hover:fill-secondary"} />
                    <p className="text-sm">Insights</p>
                  </div>
                  {dropDown === 3 ? (
                    <div>
                      <IoIosArrowUp />
                    </div>
                  ) : (
                    <div>
                      <IoIosArrowDown />
                    </div>
                  )}
                </div>
              </div>
            )}
          </NavLink>
        </div>

        {dropDown === 3 ? (
          <div className="flex flex-col place-self-start w-[90%] mx-auto text-sm ">
            {insightTab.map((el, id) => (
              <NavLink to={el.link} key={`${el.id}--appInsightRoute`} className="hover:text-secondary w-[100%]">
                {({ isActive }) => (
                  <div className={isActive ? activeStyle : nonActiveStyle}>
                    <p className="text-sm">{el.name}</p>
                  </div>
                )}
              </NavLink>
            ))}
          </div>
        ) : null}
      </div>

      <div className="mt-2">
        {/* <p className="border-b-[1px] px-4 my-2 text-sm text-neutral-500">ASK</p> */}

        <div
          key={`surveys--appRoute`}
          className="hover:text-secondary w-[100%] research"
          onMouseEnter={() => handleHover(11)}
          onMouseLeave={() => handleHover(11)}
        >
          <NavLink
            to="/research"
            onClick={() => {
              dropDown === 2 ? setDropdown(null) : setDropdown(2);
            }}
          >
            {({ isActive }) => (
              <div className={selected || isActive ? activeStyle : nonActiveStyle}>
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    {selected || noticed === 11 ? (
                      <SurveyIcon fill1="#95ADFF" fill2="#1D19AF" />
                    ) : (
                      <SurveyIcon
                        fill1={selected || isActive ? "#95ADFF" : "#D4D4D4"}
                        fill2={selected || isActive ? "#1D19AF" : "#404040"}
                      />
                    )}

                    <ContactIcon fillColor={selected ? "fill-secondary" : "fill-white hover:fill-secondary"} />
                    <p className="text-sm">Research</p>
                  </div>
                  {dropDown === 2 ? (
                    <div>
                      <IoIosArrowUp />
                    </div>
                  ) : (
                    <div>
                      <IoIosArrowDown />
                    </div>
                  )}
                </div>
              </div>
            )}
          </NavLink>
        </div>

        {dropDown === 2 ? (
          <div className="flex flex-col place-self-start w-[90%] mx-auto text-sm mt-2 space-y-1">
            {/* {askTabs.map((el, id) => (
              <NavLink to={el.link} key={`${el.id}--appAskRoute`} className="hover:text-secondary w-[100%]">
                <div
                  className={
                    searchParams.get("tab") === `${id}`
                      ? "text-primary-800 flex items-center px-4 py-[10px] justify-start text-left text-s cursor-pointer"
                      : "flex text-s text-neutral-700  items-center  px-4 py-[10px] text-center sm:justify-start justify-center sm:w-auto w-[25%] hover:text-primary-800 hover:bg-primary-50 rounded-md cursor-pointer"
                  }
                >
                  <p className="text-sm">{el.name}</p>
                </div>
              </NavLink>
            ))} */}
            {askTabs.map((el, id) => (
              <NavLink to={el.link} key={`${el.id}--appInsightRoute`} className="hover:text-secondary w-[100%]">
                {({ isActive }) => (
                  <div className={isActive ? activeStyle : nonActiveStyle}>
                    <p className="text-sm">{el.name}</p>
                  </div>
                )}
              </NavLink>
            ))}
          </div>
        ) : null}
      </div>

      <div className="mt-2">
        {/* <p className="border-b-[1px] px-4 my-2 text-sm text-neutral-500">ENGAGE</p> */}
        <div className={` flex items-center flex-col`}>
          <div
            key={`broadcast--appEngageRoute`}
            className="hover:text-secondary w-[100%] messaging"
            onMouseEnter={() => handleHover(13)}
            onMouseLeave={() => handleHover(13)}
          >
            <NavLink
              to="/broadcasts"
              onClick={() => {
                dropDown === 4 ? setDropdown(null) : setDropdown(4);
              }}
            >
              {({ isActive }) => (
                <div
                  className={`flex items-center justify-between hover:bg-primary-50 w-full rounded-[6px]  px-4 py-[10px] ${
                    selected || isActive ? "text-primary-800 bg-primary-50" : ""
                  }`}
                >
                  <div className="flex items-center">
                    {selected || noticed === 13 ? (
                      <MessagingIcon fill1="#95ADFF" fill2="#1D19AF" />
                    ) : (
                      <MessagingIcon
                        fill1={selected || isActive ? "#95ADFF" : "#D4D4D4"}
                        fill2={selected || isActive ? "#1D19AF" : "#404040"}
                      />
                    )}

                    <MessagingIcon
                      fillColor={selected || isActive ? "fill-secondary" : "fill-white hover:fill-secondary"}
                    />
                    <p className="text-sm text-neutral-700">Messaging</p>
                  </div>
                  {dropDown === 4 ? (
                    <div>
                      <IoIosArrowUp />
                    </div>
                  ) : (
                    <div>
                      <IoIosArrowDown />
                    </div>
                  )}
                </div>
              )}
            </NavLink>
          </div>

          {dropDown === 4 ? (
            <div className="flex flex-col place-self-start w-[90%] mx-auto text-sm mt-2 space-y-1 ">
              <NavLink to="/broadcasts/email" key={`email--appAskRoute`} className="hover:text-secondary w-[100%]">
                {({ isActive }) => (
                  <div className={isActive ? activeStyle : nonActiveStyle}>
                    <p className="text-sm">Email</p>
                  </div>
                )}
              </NavLink>

              <NavLink to="/broadcasts/sms" key={`sms--appAskRoute`} className="hover:text-secondary w-[100%]">
                {({ isActive }) => (
                  <div className={isActive ? activeStyle : nonActiveStyle}>
                    <p className="text-sm">SMS</p>
                  </div>
                )}
              </NavLink>

              {/* <NavLink to="/broadcasts/voice" key={`voice--appAskRoute`} className="hover:text-secondary w-[100%]">
                {({ isActive }) => (
                  <div className={isActive ? activeStyle : nonActiveStyle}>
                    <p className="text-sm">Voice</p>
                  </div>
                )}
              </NavLink> */}
            </div>
          ) : null}
        </div>
      </div>

      <div className={` flex items-center flex-col space-y-2 mt-2`}>
        {cTabs.map((el) => {
          const IconComponent = el.icons;

          return (
            <NavLink
              to={el.path}
              key={`${el.id}--appRoute`}
              className={cn("hover:text-secondary w-[100%]", el.name)}
              onMouseEnter={() => handleHover(el.id)}
              onMouseLeave={() => handleHover(el.id)}
            >
              {({ isActive }) => (
                <div className={isActive ? activeStyle : nonActiveStyle}>
                  {noticed === el.id ? (
                    <IconComponent fill1="#95ADFF" fill2="#1D19AF" />
                  ) : (
                    <IconComponent fill1={isActive ? "#95ADFF" : "#D4D4D4"} fill2={isActive ? "#1D19AF" : "#404040"} />
                  )}

                  <IconComponent fillColor={isActive ? "fill-secondary" : "fill-white hover:fill-secondary"} />
                  <p className="text-sm">{el.name}</p>
                </div>
              )}
            </NavLink>
          );
        })}
      </div>

      <div>
        <div className={` flex items-center flex-col space-y-2 border-t-[1px]  ${"mt-[1em]"}`}>
          {settingsTabs.map((el) => {
            const IconComponent = el.icons;

            return (
              <NavLink
                to={el.path}
                key={`${el.id}--appSettingsRoute`}
                className={cn("hover:text-secondary w-[100%]", el.name)}
                onMouseEnter={() => handleHover(`${el.id}--appSettingsRoute`)}
                onMouseLeave={() => handleHover(`${el.id}--appSettingsRoute`)}
              >
                {({ isActive }) => (
                  <div className={isActive ? activeStyle : nonActiveStyle}>
                    {noticed === `${el.id}--appSettingsRoute` ? (
                      <IconComponent fill1="#95ADFF" fill2="#1D19AF" />
                    ) : (
                      <IconComponent
                        fill1={isActive ? "#95ADFF" : "#D4D4D4"}
                        fill2={isActive ? "#1D19AF" : "#404040"}
                      />
                    )}

                    <IconComponent fillColor={isActive ? "fill-secondary" : "fill-white hover:fill-secondary"} />
                    <p className="text-sm">{el.name}</p>
                  </div>
                )}
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OpenSidebar;
