import { handleApiError } from "utils/handleApiError";
import Instance from "utils/instance";
import SURVEY_BACKEND_URLS from "utils/urls";

export const getInsightOverview = async (insightType, publishId) => {
  try {
    const { data } = await Instance.get(
      `${SURVEY_BACKEND_URLS.insightUrls.GET_INSIGHT_OVERVIEW(insightType, publishId)}`,
    );
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getResponseByType = async (type) => {
  try {
    const { data } = await Instance.get(`${SURVEY_BACKEND_URLS.insightUrls.insightResponses}?insightType=${type}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createInsightName = async (payload) => {
  try {
    const { data } = await Instance.post(`${SURVEY_BACKEND_URLS.insightUrls.CREATE_INSIGHT_NAME}`, payload);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createInsightQuestion = async (payload) => {
  try {
    const { data } = await Instance.post(`${SURVEY_BACKEND_URLS.insightUrls.CREATE_INSIGHT_QUESTION}`, payload);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createInsightAudience = async (payload) => {
  try {
    const { data } = await Instance.post(`${SURVEY_BACKEND_URLS.insightUrls.CREATE_INSIGHT_AUDIENCE}`, payload);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createInsightDispatch = async (payload) => {
  try {
    const { data } = await Instance.post(`${SURVEY_BACKEND_URLS.insightUrls.CREATE_INSIGHT_DISPATCH}`, payload);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getInsightCost = async (insightId) => {
  try {
    const { data } = await Instance.get(`${SURVEY_BACKEND_URLS.insightUrls.GET_INSIGHT_COST(insightId)}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const publishInsight = async (insightId) => {
  try {
    const { data } = await Instance.get(`${SURVEY_BACKEND_URLS.insightUrls.PUBLISH_INSIGHT(insightId)}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getInsightQuestion = async () => {
  try {
    const { data } = await Instance.get(`${SURVEY_BACKEND_URLS.insightUrls.GET_INSIGHT_QUESTION}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getInsight = async (insightType) => {
  try {
    const { data } = await Instance.get(`${SURVEY_BACKEND_URLS.insightUrls.GET_INSIGHT(insightType)}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getInsightStatus = async (insightType) => {
  try {
    const { data } = await Instance.get(`${SURVEY_BACKEND_URLS.insightUrls.GET_INSIGHT_STATUS(insightType)}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getInsightInhibitAction = async (insightId, action) => {
  try {
    const { data } = await Instance.get(
      `${SURVEY_BACKEND_URLS.insightUrls.GET_INSIGHT_INHIBIT_ACTION(insightId, action)}`,
    );
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const fetchCSV = async (insightType, publishId) => {
  try {
    const { data } = await Instance.get(`${SURVEY_BACKEND_URLS.insightUrls.GET_CSV(insightType, publishId)}`);
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getInsightOverviewPublic = async (insightType, workspaceId) => {
  try {
    const { data } = await Instance.get(
      `${SURVEY_BACKEND_URLS.insightUrls.GET_INSIGHT_OVERVIEW_PUBLIC(insightType, workspaceId)}`,
    );
    return data?.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
