import React from "react";
import { Route, Routes } from "react-router-dom";
import IndividualEntry from "./AddContacts/IndividualEntry";
import ContactHome from "./Home";
import ContactSetup from "./ContactSetup";
import UploadList from "./AddContacts/UploadList";
import ContactTabs from "./contactTabs";
import SingleContact from "./SingleContact";
import SingleTag from "./contactTabs/Tags/SingleTag";
import CreateTag from "./contactTabs/Tags/CreateTag";
import IndividualSms from "./IndividualSMS";
import IndividualEmail from "./IndividualEmail";
import SingleSegment from "./contactTabs/Segments/SingleSegment";
import CreateSegment from "./contactTabs/Segments/createSegment";
import ManualSegment from "./contactTabs/Segments/createSegment/ManualSegment";

const Contacts = () => {
  // console.log( error, isLoading, "hello")
  return (
    <Routes>
      <Route exact path="/" element={<ContactHome />} />
      <Route exact path="/setup" element={<ContactSetup />} />
      <Route exact path="/create" element={<IndividualEntry />} />
      <Route exact path="/upload" element={<UploadList />} />
      <Route exact path="/all-contacts" element={<ContactTabs />} />
      <Route exact path="/:id" element={<SingleContact />} />
      <Route exact path="/:id/sms" element={<IndividualSms />} />
      <Route exact path="/:id/email" element={<IndividualEmail />} />

      <Route exact path="/tag/:id/:name" element={<SingleTag />} />
      <Route exact path="/tag/create" element={<CreateTag />} />
      <Route exact path="/tag/create" element={<CreateTag />} />

      <Route exact path="/segment/create" element={<CreateSegment />} />
      <Route exact path="/segment/:id" element={<SingleSegment />} />
      <Route exact path="/segment/create/:name" element={<ManualSegment />} />
    </Routes>
  );
};

export default Contacts;
