import React from "react";
import { Pie, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

//const possibleColors = ["#074973", "#0068A8", "#04A5F3", "#77D3FF", "#B7E6FF", "#EFF9FF"]//["#4852FF", "#6B7FFF", "#95ADFF",];
const possibleColors = ["#1D19AF", "#4852FF", "#5F0BDD", "#00DA7A", "#074973", "#0068A8"];

const PieChart = ({ data, className, labels }) => {
  // Data for the pie chart
  const dataObj = {
    labels: labels,
    datasets: [
      {
        data: data.map((obj) => obj?.value),
        backgroundColor: getArraySubset(possibleColors, labels.length),
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#F8F8FF", // Set tooltip background color to white
        titleColor: "#000000", // Set tooltip title color
        bodyColor: "#000000", // Set tooltip body color
        borderColor: "#000000", // Optional: add a border
        borderWidth: 0, // Optional: set border width
      },
    },
  };

  return (
    <div className="flex flex-col w-full h-full gap-12 mt-2">
      <div className="flex w-[100%] h-[180px] p-0 m-0 items-center justify-center">
        <Doughnut data={dataObj} options={options} />
      </div>
      <div className={className ? className : "flex gap-2 mx-auto mb-4 absolute  bottom-0 w-full"}>
        {data?.map((elm, index) => (
          <div
            key={`optionTag${index}`}
            className="flex items-center space-x-2 p-1 px-2 border-[1px] rounded-[4px] justify-start"
          >
            <div
              className="w-[10px] h-[10px] rounded-sm"
              style={{ background: possibleColors[index % possibleColors.length] }}
            ></div>
            <p className="text-ss">{elm.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PieChart;

function getArraySubset(array, count, startIndex = 0) {
  if (!Array.isArray(array)) {
    throw new Error("The first argument must be an array.");
  }

  return array.slice(startIndex, startIndex + count);
}
