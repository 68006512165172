export const genderData = [
  {
    id: 2,
    name: "Male",
    value: "Male",
  },
  {
    id: 3,
    name: "Female",
    value: "Female",
  },
  {
    id: 4,
    name: "Both",
    value: "Both",
  },
];
