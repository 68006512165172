import BlueButton from "components/Button/BlueButton";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import contactBanner from "assets/images/contactbanner.png";
import { Link, useNavigate } from "react-router-dom";
import totalContact from "assets/images/totalContacts.svg";
import activeContact from "assets/images/activeContact.svg";
import leads from "assets/images/leads.svg";
import tagGroup from "assets/images/tagGroup.svg";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addToTags, deleteContacts, getAllContacts, getContactDashboard, getCSVData } from "services/contactService";
import DeleteContact from "./DeleteContact";
import AddToTagsModal from "./AddToTagsModal";
import { MdDeleteOutline } from "react-icons/md";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { BiExport } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import PreLoader from "components/Loader/PreLoader";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import { formatDate } from "lib/formatDate";
import csvDownload from "json-to-csv-export";
import Skeleton from "react-loading-skeleton";
import contactEmpty from "assets/icons/contacts/ContactEmpty.png";
import EmptyState from "pages/Contacts/EmptyState";
import { getAllLead } from "services/contactService";
import { getAllSegments } from "services/contactService";
import Tooltip from "components/Tooltip";
import usePlan from "hooks/usePlan";
import Pagination from "components/Pagination";
import IframeCard from "components/Videocard/IframeCard";

const ContactHome = () => {
  const { data } = useQuery({ queryKey: ["getContactDashboard"], queryFn: getContactDashboard });
  const { plan } = usePlan();

  console.log(plan, "pan");

  const navigate = useNavigate();
  const [selectArray, setSelectArray] = useState([]);

  const [searchVal, setSearchVal] = useState("");
  const [contactCurrentPage, setContactCurrentPage] = useState(1);
  const [contactItemPerPage, setContactItemPerPage] = useState(10);
  const [openModal, setOpenModal] = useState();
  const [active, setActive] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");

  const contactSize = (plan) => {
    switch (plan) {
      case "free":
        return "100";
      case "sme":
        return "1,000";
      case "business":
        return "25,000";
      case "pro":
        return "100,000";
      default:
    }
  };

  const {
    data: contact,
    isPending: isFetchingContacts,
    isSuccess: isSuccessContacts,
    isError: isErrorContacts,
    error: errorContacts,
  } = useQuery({
    queryKey: ["allContactTab", contactCurrentPage, searchVal, contactItemPerPage],
    queryFn: () =>
      getAllContacts({
        page: contactCurrentPage,
        sortBy: "name",
        searchVal: searchVal.toLowerCase(),
        perPage: contactItemPerPage,
      }),
  });

  const { data: newAllLeads } = useQuery({ queryKey: ["newAllLeads"], queryFn: getAllLead });
  const { data: allSegments } = useQuery({ queryKey: ["allSegments"], queryFn: () => getAllSegments("name") });

  const handleCheck = (e, x) => {
    if (e.target.checked) {
      setSelectArray([...selectArray, x]);
    } else {
      const filterArray = selectArray?.filter((el) => el !== x);
      setSelectArray(filterArray);
    }
  };

  const checkArray = useCallback(() => {
    if (selectArray.length > 0) {
      setActive(true);
    } else if (selectArray.length <= 0) {
      setActive(false);
    }
  }, [selectArray.length]);

  useEffect(() => {
    checkArray();
  }, [checkArray]);

  const selectAll = (e) => {
    if (e.target.checked) {
      contact?.contacts?.map((el) => selectArray.push(el._id));
      for (let i = 0; i < contact?.contacts?.length; i++) {
        setSelectArray([...selectArray, contact?.contacts[i]?._id]);
      }
    } else {
      setSelectArray([]);
    }
  };

  const checkInArray = (y) => {
    let checked = false;
    for (let x in selectArray) {
      if (selectArray[x] === y) {
        checked = true;
      }
    }
    return checked;
  };

  const toggleModal = (id) => {
    setOpenModal(openModal === id ? null : id);
  };

  const { mutate: tagMutate } = useMutation({
    mutationFn: addToTags,
    onSuccess: () => {
      Toast.success("Contact(s) added to tag");
      queryClient.invalidateQueries({ queryKey: ["allTags"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const [pageCountStart, pageCountEnd] = useMemo(() => {
    const totalValue = contact?.totalContacts;

    let startValue = (contactCurrentPage - 1) * 10;
    startValue = startValue + 1;

    let endValue = Math.min(contactCurrentPage * 10, totalValue);
    return [startValue, endValue];
  }, [contactCurrentPage, contact?.totalContacts]);

  const { mutate: deleteMutate, isLoading: loadingDelete } = useMutation({
    mutationFn: deleteContacts,
    onSuccess: () => {
      Toast.success("Contact deleted successfully");
      queryClient.invalidateQueries({ queryKey: ["allContacts"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: csvMutate } = useMutation({
    mutationFn: getCSVData,
    onSuccess: (data) => {
      const dataToConvert = {
        data: data,
        filename: "Contact",
        delimiter: ",",
      };

      csvDownload(dataToConvert);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  if (loadingDelete) {
    return <PreLoader text="Deleting contact" />;
  }

  const exportCSV = () => {
    if (selectArray.length > 0) {
      csvMutate({ contactIds: selectArray });
    }
  };

  const handleAddToTags = () => {
    tagMutate({ contactIds: selectArray, tag: selectedTag });
    setActive(false);
    setSelectArray([]);
    toggleModal();
  };

  const deleteContactsFunc = () => {
    deleteMutate({ contactId: selectArray });
    setActive(false);
    setSelectArray([]);
    toggleModal();
  };

  const handlePrev = () => {
    setContactCurrentPage(contactCurrentPage - 1);
    // setSelectArray([]);
  };

  const handleNext = () => {
    setContactCurrentPage(contactCurrentPage + 1);
    // setSelectArray([]);
    // fetchNextPage();
  };

  const contactData = contact?.contacts?.map((item, i) => ({
    inputProps: (
      <p className="absolute mx-auto left-0 right-0 flex justify-center items-center w-[100%] h-[100%] top-0">
        <input
          type="checkbox"
          name="recipient"
          id="recipient"
          checked={checkInArray(item?._id)}
          className="pl-0 w-[15px] h-[15px] accent-purple"
          onChange={(e) => handleCheck(e, item?._id)}
        />
      </p>
    ),

    id: item._id,
    sn: `000${i + 1}`,
    firstname: item.firstname,
    lastname: item.lastname,
    email: item.email,
    phone: item.phone,
    tag: <p>{`${item.tag[0]} ${item.tag.length > 1 ? `+${item.tag.length - 1}` : ""}`}</p>,
    createdAt: formatDate(item.createdAt),
    viewProps: (
      <Link
        to={`${item._id}`}
        key={item._id}
        className="mr-4 mt-[4px] text-[#29085C] text-ss font-[500] flex items-center cursor-pointer"
        onClick={() => handleMore(item._id)}
      >
        View Contact
      </Link>
    ),
  }));

  const handleMore = (id) => {
    navigate(id);
  };

  const columns = [
    {
      field: "inputProps",
      header: (
        <p className="pl-0 flex items-center justify-center">
          <input
            type="checkbox"
            name="inputProps"
            className="pl-0 w-[15px] h-[15px] accent-purple"
            onChange={selectAll}
          />
        </p>
      ),
    },
    { field: "firstname", header: "First name" },
    { field: "lastname", header: "Last name" },
    { field: "email", header: "Email Address" },
    { field: "phone", header: "Phone Number" },
    { field: "tag", header: "Tags" },
    { field: "createdAt", header: "Date Added" },
    { field: "viewProps", header: "Action" },
  ];

  const handleSearch = (e) => {
    setSearchVal(e.target.value);
  };

  // const handleNewContact = () => {
  //   navigate("new-contact");
  // };

  // console.log(data, "data");
  return (
    <div>
      <div className="bg-white border-[1px] rounded-lg">
        <div className="bg-contactBg bg-cover flex justify-between pl-[5%] h-[230px] w-full">
          <div className="p-6  w-[50%] ">
            <p className="text-2xl text-primary-800 font-semibold">Grow your audience</p>

            <p className="text-sm text-neutral-700 my-4">
              Easily manage leads, upload and segment your contacts. Organise them into groups or tags for more targeted
              communication.
            </p>
            <Link to="setup">
              <BlueButton text="Add contacts" />
            </Link>
          </div>
          {/* <div className="w-[308px]">
            <img src={contactBanner} alt="" />
          </div> */}
          <div className="w-[50%]">
            <IframeCard videoUrl="https://youtu.be/sdopzLi2fpQ?si=ISQQIM0RpQDdEADV" />
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-6 my-6">
        <div className="flex items-start space-x-4 border-[1px] p-4 w-[25%] rounded-lg bg-white">
          <img src={totalContact} alt="totalContact" />
          <div>
            <p className="text-2xl font-semibold">
              {data?.contactCount?.totalContacts}/{contactSize(plan?.plan)}
            </p>
            <div className="flex items-center space-x-2">
              <p className="text-sm">Total contacts</p>
              <Tooltip text="This shows the available contacts in your database against limits you have on your current plan." />
            </div>
          </div>
        </div>

        <div className="flex items-start space-x-4 border-[1px] p-4 w-[25%] rounded-lg bg-white">
          <img src={leads} alt="leads" />
          <div>
            <p className="text-2xl font-semibold">{newAllLeads?.length}</p>
            <p className="text-sm">Total leads</p>
          </div>
        </div>

        <div className="flex items-start space-x-4 border-[1px] p-4 w-[25%] rounded-lg bg-white">
          <img src={tagGroup} alt="tagGroup" />
          <div>
            <p className="text-2xl font-semibold">{data?.contactCount?.totalTags}</p>
            <p className="text-sm">Total tags</p>
          </div>
        </div>

        <div className="flex items-start space-x-4 border-[1px] p-4 w-[25%] rounded-lg bg-white">
          <img src={activeContact} alt="activeContact" />
          <div>
            <p className="text-2xl font-semibold">{allSegments?.length}</p>
            <p className="text-sm">Total segments</p>
          </div>
        </div>
      </div>

      <div className="bg-white p-5 rounded-[8px]">
        {/* <CallModal /> */}
        <div className="flex items-center justify-between">
          {/* <BlueButton
          text="Create new contact"
          css="bg-primary-800 text-white h-[42px] w-[144px] text-s font-semibold tracking-[-0.01em]"
          onClick={handleNewContact}
        /> */}

          <div className="bg-[#F2F1F3] w-[366px] h-[42px] rounded-[8px] flex items-center px-4 space-x-2">
            <BsSearch className="text-[#9CA3AF]" />
            <input
              type="text"
              className="placeholder:text-[#9CA3AF] placeholder:text-s placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
              placeholder="Search for name or email..."
              value={searchVal}
              onChange={handleSearch}
            />
          </div>

          <div className="my-3 flex space-x-2">
            <button
              className={`flex items-center bg-[#EBEBEB] rounded-[6px] px-[15px] py-[6px] text-s space-x-2 ${
                active ? "text-primary-800 hover:bg-gray-200" : "text-[#999999]"
              }`}
              onClick={active ? exportCSV : null}
            >
              <BiExport />
              <p>Export CSV</p>
            </button>

            <button
              className={`flex items-center bg-[#EBEBEB] rounded-[6px] px-[15px] py-[6px] text-s space-x-2 ${
                active ? "text-primary-800 hover:bg-gray-200" : "text-[#999999]"
              }`}
              onClick={active ? () => toggleModal(2) : null}
            >
              <HiOutlinePlusCircle />
              <p>Add to Tag</p>
            </button>
            <button
              className={`flex items-center bg-[#EBEBEB] rounded-[6px] px-[15px] py-[6px] text-s space-x-2 ${
                active ? "text-[#EF4444] hover:bg-gray-200" : "text-[#999999]"
              }`}
              onClick={active ? () => toggleModal(1) : null}
            >
              <MdDeleteOutline />
              <p>Delete</p>
            </button>
          </div>
        </div>

        <table className="w-[100%] my-4 border-b-[1px] bg-white">
          <thead className="h-[50px] text-s border-t-[1px] border-b-[1px]">
            <tr className="h-[50px] border-r-[1px] rounded-[2px] overflow-hidden">
              {columns &&
                columns.map((head, i) => (
                  <th key={i} className="px-2 text-left border-l-[1px]">
                    {head.header}
                  </th>
                ))}
            </tr>
          </thead>

          <tbody>
            {isFetchingContacts ? (
              <>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((row, i) => (
                  <tr
                    key={i}
                    className="h-[50px]  text-ss text-[#404040] font-[400] border-r-[1px] rounded-[2px] bg-white border-b-[1px]  "
                  >
                    {columns?.map((col, i) => (
                      <td key={i} className="pl-2 border-l-[1px] relative">
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            ) : (
              <>
                {contactData &&
                  contactData?.map((row, i) => (
                    <tr
                      className="h-[50px] text-ss text-[#404040] font-[400] border-r-[1px] rounded-[2px] bg-white border-b-[1px] hover:bg-slate-50 cursor-pointer"
                      // onClick={() => handleMore(row.id)}
                      key={i}
                    >
                      {columns?.map((col, j) => (
                        <td key={j} className="px-4 border-l-[1px] relative">
                          {row[col.field] || "-"}
                        </td>
                      ))}
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>

        {contactData?.length <= 0 && (
          <div className="text-center flex justify-center items-center flex-col w-[100%] h-[35vh]">
            <EmptyState
              title="No Contacts Found."
              icon={contactEmpty}
              content="You haven't added any contacts yet. Click the 'Add Contacts' button to begin."
              link="/contacts/create"
              button="Add contacts"
            />
          </div>
        )}

        {contactData?.length > 0 && (
          <Pagination
            data={contactData}
            dataCount={contact?.totalContacts}
            itemsPerPage={contactItemPerPage}
            setItemsPerPage={setContactItemPerPage}
            currentPage={contactCurrentPage}
            setCurrentPage={setContactCurrentPage}
          />
        )}

        {openModal === 1 ? (
          <DeleteContact
            title="Delete contact"
            content="Are you sure you want to delete? This action cannot be undone."
            toggleModal={toggleModal}
            deleteContactsFunc={deleteContactsFunc}
          />
        ) : null}

        {openModal === 2 ? (
          <AddToTagsModal
            toggleModal={toggleModal}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            handleAddToTags={handleAddToTags}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ContactHome;
