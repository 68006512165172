import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Rectangle } from "recharts";

const COLORS = ["#4852FF", "#6B7FFF", "#95ADFF", "#1D19AF"];

const StackedBarCharts = ({ data, dataKey, className = "w-[100%] h-[400px]" }) => {
  console.log(data, "StackedBarCharts");
  const keys = data?.length === 0 || data === undefined ? [] : Object?.keys(data[0])?.filter((key) => key !== dataKey);

  return (
    <div className="w-[100%] h-full">
      <div className={className}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: -20,
              bottom: 5,
            }}
            barSize={60}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey={dataKey ?? "name"} fontSize={10} />
            <YAxis fontSize={10} />
            <Tooltip />
            {/* <Legend /> */}

            {keys?.map((key, index) => (
              <Bar
                dataKey={key}
                stackId="a"
                fill={COLORS[index % 10]}
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                radius={[2, 2, 0, 0]}
                width={30}
                label={{ position: "insideTop", fill: "white", fontSize: 10 }}
              >
                {/* {data.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % 10]} />
                ))} */}
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="flex gap-4 flex-wrap mb-1 mx-auto w-[45%]">
        {keys?.map((elm, index) => {
          if (elm !== dataKey) {
            return (
              <div
                key={`optionTag${index}`}
                className="flex items-center space-x-2 p-1 px-2 border-[1px] rounded-[4px] mx-auto"
              >
                <div
                  className="w-[10px] h-[10px] rounded-sm"
                  style={{ background: COLORS[index % COLORS.length] }}
                ></div>
                <p className="text-ss">{elm === "MooyiOption" ? "Others" : elm}</p>
              </div>
            );
          } else {
            return null; // Return null instead of undefined
          }
        })}
      </div>
    </div>
  );
};

export default StackedBarCharts;
