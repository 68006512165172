import { useEffect, useState } from "react";
import Joyride from "react-joyride";
import WKIcon from "assets/images/wkIcon.png";
import { IoIosClose } from "react-icons/io";
import Checkbox from "components/Inputs/CheckBox";
import Typography from "utils/typography";

const steps = [
  {
    target: ".workspace",
    content: (
      <div>
        <Typography>
          Welcome to your <span className="text-primary-800">workspace.</span> Here, you can collaborate seamlessly with
          your teammates to gather valuable insights through surveys and campaigns.
        </Typography>
      </div>
    ),
    disableBeacon: true,
    placement: "right",
  },
  {
    target: ".balance",
    content: (
      <Typography>
        Monitor your <span className="text-primary-800">available balance</span> and top up when needed. Use your
        credits to leverage our research and messaging features to enhance audience reach and maximise value.
      </Typography>
    ),
    placement: "bottom",
    disableBeacon: true,
  },
  {
    target: ".account",
    content: (
      <Typography>
        Manage your <span className="text-primary-800">account </span>with ease, update your personal details, adjust
        preferences, and stay in control of your profile to get the most value from our platform.
      </Typography>
    ),
    placement: "bottom",
    disableBeacon: true,
  },
  {
    target: ".Home",
    content: (
      <Typography>
        Craft engaging content that resonates with your audience and drives meaningful interactions. Leverage our quick
        actions to gather insights from your target audience.
      </Typography>
    ),
    placement: "right",
    disableBeacon: true,
  },

  {
    target: ".contactTabs",
    content: (
      <Typography>
        Manage, upload, and segment your<span className="text-primary-800"> contacts </span> effortlessly. Organise your
        contacts into segments and custom groups for targeted communication.
      </Typography>
    ),
    placement: "right",
    disableBeacon: true,
  },

  {
    target: ".insights",
    content: (
      <Typography>
        Know your <span className="text-primary-800">audience's needs, preferences, and feedback </span> to create
        meaningful interactions that drive loyalty and growth.
      </Typography>
    ),
    placement: "right",
    disableBeacon: true,
  },
  {
    target: ".research",
    content: (
      <Typography>
        Gain valuable, actionable insights from your target audience or existing customers through our comprehensive
        range of <span className="text-primary-800">online and field research </span>solutions.
      </Typography>
    ),
    placement: "right",
    disableBeacon: true,
  },

  {
    target: ".messaging",
    content: (
      <Typography>
        Connect and share <span className="text-primary-800">email, SMS and voice campaigns </span>with your uploaded
        audience to expand your reach.
      </Typography>
    ),
    placement: "right",
    disableBeacon: true,
  },

  {
    target: ".Calendar",
    content: (
      <Typography>
        Easily <span className="text-primary-800">schedule surveys and campaigns,</span> plan ahead and coordinate
        activities seamlessly with your team. Preview upcoming events and special dates.
      </Typography>
    ),
    placement: "right",
    disableBeacon: true,
  },
  {
    target: ".Settings",
    content: (
      <Typography>
        <span className="text-primary-800">Manage your account, </span>invite team members and set up relevant
        permissions to your campaigns from our intuitive settings page
      </Typography>
    ),
    placement: "right",
    disableBeacon: true,
  },
];

function CustomTooltip(props) {
  const { backProps, closeProps, continuous, index, primaryProps, step, tooltipProps } = props;

  const [checked, setChecked] = useState(false);

  const handleShowAgain = () => {
    localStorage.setItem("showTour", "true"); // Save the "Don't show again" flag
    setChecked(!checked);
  };

  return (
    <div className="bg-primary-100 rounded-xl w-[400px] h-[250px] p-4" {...tooltipProps}>
      <div className="flex items-center justify-between">
        <div>
          <img src={WKIcon} alt="WkIcon" className="w-[35px] h-[35px]" />
        </div>
        <div
          className="bg-white w-[20px] h-[20px] rounded-full flex items-center justify-center cursor-pointer"
          {...closeProps}
        >
          <IoIosClose className="text-xl" />
        </div>
      </div>

      <div className="flex flex-col justify-between h-[75%] mt-4">
        <div>
          <div className="text-sm">{step.content}</div>

          <div className="flex items-center space-x-2 mt-3">
            <Checkbox value={checked} onChange={handleShowAgain} />
            <p className="text-xs">Don't show again</p>
          </div>
        </div>

        <div className="flex justify-between items-center">
          {/* <button className="tooltip__button" {...skipProps}>
          {skipProps.title}
        </button> */}
          <div className="flex space-x-4">
            {index > 0 && (
              <button className="w-[100px] text-sm bg-primary-800 rounded-md h-[35px] text-white" {...backProps}>
                {backProps.title}
              </button>
            )}
            {continuous && (
              <button className="w-[100px] text-sm bg-white rounded-md h-[35px] text-primary-800" {...primaryProps}>
                {primaryProps.title}
              </button>
            )}
          </div>
          <div className="text-xs">{index + 1} / 10</div>
        </div>
      </div>
    </div>
  );
}

const ProductTour = () => {
  const [run, setRun] = useState(false);

  useEffect(() => {
    // Check local storage to see if the user has opted out of the tour
    const showAgain = localStorage.getItem("showTour");
    if (!showAgain) {
      setRun(true); // Start the tour if no flag is found
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;

    if (status === "finished" || status === "skipped" || action === "close") {
      localStorage.setItem("showTour", "true");
      setRun(false);
    }
  };

  return (
    <>
      <Joyride
        run={run}
        steps={steps}
        continuous={true}
        tooltipComponent={CustomTooltip}
        callback={handleJoyrideCallback}
        locale={{
          last: "Finish",
        }}
        styles={{
          options: {
            zIndex: 10000,
            arrowColor: "#DAE5FF",
            overlayColor: "rgba(0, 0, 0, 0.5)", // Overlay color
          },
          tooltip: {
            animation: "fade-in 0.5s",
          },
          overlay: {
            animation: "fade-in-overlay 0.5s ease-in",
          },
          spotlight: {
            border: "2px solid #141254",
          },
        }}
      />
    </>
  );
};

export default ProductTour;
