import SelectInput from "components/Inputs/SelectInput";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import SecondaryInput from "components/Inputs/SecondaryInput";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import { CreateSegmentSucess } from "../CreateSegmentSuccess";
import { segmentation } from "services/contactService";
import { useMutation } from "@tanstack/react-query";
import Toast from "config/toast";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useSideBarStore } from "store/sidebar/sidebarStore";
import Typography from "utils/typography";
import { checkInArray } from "lib/checkInArray";
import Checkbox from "components/Inputs/CheckBox";
import { HiOutlineChevronDown } from "react-icons/hi2";
import { transformToLabelValue } from "lib/transformData";
import countryData from "data/countries.json";
import stateData from "data/states.json";
import DropDownWithSearch from "components/DropDownWithSearch";
import { CiSearch } from "react-icons/ci";

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Name too short")
    .max(25, "Name too long (25 characters max)")
    .matches(/^\S*$/, "Name should not contain any whitespace")
    .required("Please complete this field."),
});

const enumeration = {
  booleanCondition: [
    { label: "options", value: "options" },
    { label: "is", value: "is" },
    { label: "is not", value: "isNot'" },
  ],
  multipleBoolean: [
    { label: "options", value: "options" },
    { label: "is", value: "is" },
    { label: "is not", value: "isNot'" },
    { label: "Contains", value: "contains" },
    { label: "Does not contain", value: "doesNotContain" },
  ],
  textBoolean: [
    { label: "options", value: "options" },
    { label: "range", value: "range" },
  ],
  locationBoolean: [
    { label: "options", value: "options" },
    { label: "In", value: "in" },
    { label: "Not in", value: "Not in" },
  ],
};

const option1 = [{ value: "", label: "Loading..." }];

const ManualSegment = () => {
  const navigate = useNavigate();
  const { sidebar } = useSideBarStore();
  const [selectedData, setSelectedData] = useState();
  const [openModal, setOpenModal] = useState(null);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [pickCountry, setPickCountry] = useState("");
  const [filterData, setFilteredData] = useState();
  const [query, setQuery] = useState("");
  const [stateQuery, setStateQuery] = useState("");

  const countryValues = transformToLabelValue(countryData, "name");

  const getStatesForCountry = (countryName) => {
    const data = stateData.find((country) => country.name === countryName)?.states || [{}];
    return transformToLabelValue(data, "name");
  };

  const handleFilter = useCallback((searchArray, searchTerm) => {
    const filteredData = searchTerm
      ? searchArray.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()))
      : searchArray; // Only filter if a searchTerm is provided
    setFilteredData(filteredData);
  }, []);

  useEffect(() => {
    if (selectedData !== undefined && selectedData[0].value === "country") {
      handleFilter(countryValues, query);
    } else if (selectedData !== undefined && selectedData[0].value === "state") {
      handleFilter(getStatesForCountry(pickCountry), stateQuery);
    }
  }, [query, stateQuery, countryValues, pickCountry, handleFilter]);

  const filterOptions = useMemo(
    () => [
      {
        id: 0,
        value: "values",
        label: "Select filter",
        condition: [],
        options: [],
      },
      {
        id: 1,
        value: "gender",
        label: "Gender",
        condition: enumeration.booleanCondition,
        options: [
          { label: "Select option", value: "" },
          { label: "Male", value: "male" },
          { label: "Female", value: "female" },
        ],
      },
      {
        id: 2,
        value: "age",
        label: "Age",
        condition: enumeration.textBoolean,
        options: "text",
      },
      {
        id: 3,
        value: "country",
        label: "Country",
        condition: enumeration.multipleBoolean,
        options: "country",
      },
      {
        id: 4,
        value: "state",
        label: "State",
        condition: enumeration.multipleBoolean,
        options: "state",
      },
    ],
    [enumeration],
  );

  const findFilterObject = (value) => {
    return filterOptions.find((option) => option.value === value);
  };

  const { mutate: mutateSegment } = useMutation({
    mutationFn: segmentation,
    onSuccess: () => {
      Toast.success("segmentation created");
      handleSave(2);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const onSubmit = async (data) => {
    const updatedFieldsSelector = data.fieldsSelector.map((item) => {
      // Check if the field is 'country' and update its values
      if (item.field === "country") {
        return {
          ...item,
          values: selectedCountry, // Replace the values with selectedCountry
        };
      } else if (item.field === "state") {
        return {
          ...item,
          values: selectedStates,
        };
      }
      return item; // Return the item as-is for other fields
    });

    const payload = {
      segmentName: data.name,
      fieldsSelector: updatedFieldsSelector,
    };
    mutateSegment(payload);
  };

  const { handleChange, values, handleBlur, handleSubmit, setFieldValue, errors, touched } = useFormik({
    initialValues: {
      name: "",
      description: "",
      fieldsSelector: [{ field: "", values: [], condition: "", id: 0 }],
    },
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  const disableButton = values.fieldsSelector.some(
    (filterObj) => !filterObj.field || !filterObj.values || !filterObj.condition,
  );

  const handleSave = (id) => {
    setOpenModal(openModal === id ? null : id);
  };

  const handleModal = (id) => {
    setOpenModal(openModal === id ? null : id);
  };

  const filterOptionData = useCallback(
    (searchTerm) => {
      const data = filterOptions?.filter((item) => {
        const options = item?.value?.toLocaleLowerCase();
        return options.includes(searchTerm?.toLocaleLowerCase());
      });

      setSelectedData(data);
    },
    [filterOptions], // Dependencies of useCallback
  );

  const handleSelectedOption = (state) => {
    setSelectedStates((prevStates) => {
      if (prevStates.includes(state)) {
        return prevStates.filter((item) => item !== state);
      } else {
        return [...prevStates, state];
      }
    });
  };

  const handleCountryOption = (country) => {
    setSelectedCountry((prevCountries) => {
      if (prevCountries.includes(country)) {
        return prevCountries.filter((item) => item !== country);
      } else {
        return [...prevCountries, country];
      }
    });
  };

  const addFilter = () => {
    if (values.fieldsSelector.length < 3) {
      setFieldValue("fieldsSelector", [...values.fieldsSelector, { field: "", values: [""], condition: "" }]);
    } else {
      Toast.error("You cannot add more than 3 filters");
    }
  };

  const removeFilter = (id) => {
    setFieldValue(
      "fieldsSelector",
      values.fieldsSelector.filter((input) => input.field !== id),
    );
  };

  useEffect(() => {
    values.fieldsSelector.forEach((filterObj) => {
      filterOptionData(filterObj.field);
    });
  }, [filterOptionData, values.fieldsSelector]);

  const arraySplitNum = (text) => {
    if (text.includes("-")) {
      // Split by comma, trim spaces, and convert each to a number
      return text.split("-").map((e) => Number(e.trim()));
    }
    // If no comma, return the value as a number inside an array
    return [Number(text.trim())];
  };

  return (
    <div className="border p-5 bg-white rounded-md">
      <div
        onClick={() => navigate(-1)}
        className="flex items-center space-x-1 my-2 text-primary-800 pl-2 cursor-pointer"
      >
        <FiChevronLeft />
        <p>Go back</p>
      </div>
      <div className="flex items-start space-x-2">
        <div className="w-[24px] mt-2 h-[24px] rounded-full bg-primary-800 text-white flex items-center justify-center">
          <p>1</p>
        </div>
        <div>
          <p className="text-[20px] font-[500]">Create new segment</p>
          <p>Enter a name and a short description for your new segment below.</p>
        </div>
      </div>
      <form action="">
        <div className="mt-5 flex items-center space-x-5">
          <div className="w-full">
            <SecondaryInput
              text="Segment name"
              placeholder="Enter segment name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              name="name"
              error={getError("name")}
              type="text"
            />
          </div>
          <div className="w-full">
            <SecondaryInput
              text="Description (optional)"
              placeholder="Describe your segment"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              name="description"
              error={getError("description")}
              type="text"
            />
          </div>
        </div>
        <hr className="my-5" />
        <div className="flex items-start space-x-2 mt-5">
          <div className="w-[24px] mt-2 h-[24px] rounded-full bg-primary-800 text-white flex items-center justify-center">
            <p>2</p>
          </div>
          <div>
            <p className="text-[20px] font-[500]">Add segment filters</p>
            <p>Add relevant filter to segment your contacts</p>
          </div>
        </div>
        {values?.fieldsSelector.map((item, i) => (
          <div key={i}>
            <div className="mt-5 flex items-start space-x-5">
              <div className="flex items-center space-x-2 w-[40%]">
                <div className="w-full">
                  <SelectInput
                    name={`fieldsSelector.${i}.field`}
                    options={filterOptions}
                    values={item.field}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="w-[60%]">
                {item?.field !== "" ? (
                  <div className="flex items-start space-x-3">
                    {item.field === "custom fields" ? (
                      <div>
                        <SelectInput
                          name={`fieldsSelector.${i}.field`}
                          options={
                            selectedData === undefined
                              ? option1
                              : [
                                  { label: "Shoe size", value: "shoe size" },
                                  { label: "Salary", value: "Salary" },
                                  { label: "Dress size", value: "dress size" },
                                ]
                          }
                          value={item.field}
                          onChange={handleChange}
                        />
                      </div>
                    ) : null}
                    <div>
                      {(item.field !== "") & (findFilterObject(item.field)?.value === item.field) ? (
                        <SelectInput
                          name={`fieldsSelector.${i}.condition`}
                          options={findFilterObject(item.field).condition}
                          value={item.condition}
                          onChange={handleChange}
                        />
                      ) : (
                        <SelectInput
                          name={`fieldsSelector.${i}.condition`}
                          options={selectedData === undefined ? option1 : selectedData[0]?.condition}
                          value={item.condition}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                    <div className={`${selectedData[0]?.options === "state" ? "w-[90%]" : "w-[60%]"}`}>
                      {(item.field !== "") & (item.field === "age") ? (
                        <div>
                          <SecondaryInput
                            name={`fieldsSelector.${i}.values`}
                            placeholder="text"
                            onChange={(e) => {
                              const inputText = e.target.value;
                              const splitValues = arraySplitNum(inputText);
                              setFieldValue(`fieldsSelector.${i}.values`, splitValues);
                            }}
                            onBlur={handleBlur}
                            type="text"
                            value={item.values}
                          />
                          <Typography.MicroText className="text-primary-800">
                            Seperate range with - e.g. 18-50
                          </Typography.MicroText>
                        </div>
                      ) : (item.field !== "") & (findFilterObject(item.field).value === "country") ? (
                        <div className="relative w-full mt-1">
                          <div
                            className="flex items-center justify-between border-[1px] border-gray-300 rounded p-2 py-[10px] cursor-pointer "
                            onClick={() => handleModal(3)}
                          >
                            <p className="text-xs">
                              {selectedCountry?.length > 0 ? (
                                <>{selectedCountry?.length} Selected </>
                              ) : (
                                "Select country"
                              )}
                            </p>

                            <HiOutlineChevronDown />
                          </div>
                          {openModal === 3 ? (
                            <div className="absolute top-10 bg-white border rounded-b-md w-full h-[300px] overflow-y-auto">
                              <div className="flex items-center bg-gray-100 border space-x-2 rounded-[4px] h-[35px] px-2">
                                <div>
                                  <CiSearch />
                                </div>
                                <div className="w-full">
                                  <input
                                    type="text"
                                    className="w-full border-none focus:outline-none text-[14px] bg-gray-100"
                                    onChange={(e) => setQuery(e.target.value)}
                                  />
                                </div>
                              </div>
                              {filterData?.map((el, i) => (
                                <div
                                  key={`${i}`}
                                  className={`text-xs cursor-pointer p-2 flex items-center  space-x-3 ${
                                    checkInArray(el.value, selectedCountry)
                                      ? "border-b-[1px] border-white"
                                      : "hover:bg-slate-100"
                                  }`}
                                  onClick={() => handleCountryOption(el.value)}
                                >
                                  <Checkbox checked={checkInArray(el.value, selectedCountry)} />

                                  <p className="text-[12px]">{el.label}</p>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      ) : (item.field !== "") & (findFilterObject(item.field).value === "state") ? (
                        <>
                          <div className="flex items-start space-x-5 mt-1 ">
                            <div className="w-full">
                              <DropDownWithSearch
                                name={`fieldsSelector.${i}.values`}
                                tag="Select country"
                                options={countryValues}
                                onSelect={(el) => setPickCountry(el.value)}
                              />
                            </div>
                            <div className="relative w-full">
                              <div
                                className="flex items-center justify-between border-[1px] border-gray-300 rounded px-2 py-[10px] cursor-pointer w-full"
                                onClick={() => handleModal(4)}
                              >
                                <p className="text-xs">
                                  {selectedStates?.length > 0 ? (
                                    <>{selectedStates?.length} Selected </>
                                  ) : (
                                    "Select states"
                                  )}
                                </p>

                                <HiOutlineChevronDown />
                              </div>
                              {openModal === 4 ? (
                                <div className="absolute top-10 bg-white border rounded-b-md w-full h-[300px] overflow-y-auto">
                                  <div className="flex items-center bg-gray-100 border space-x-2 rounded-[4px] h-[35px] px-2">
                                    <div>
                                      <CiSearch />
                                    </div>
                                    <div className="w-full">
                                      <input
                                        type="text"
                                        className="w-full border-none focus:outline-none text-[14px] bg-gray-100"
                                        onChange={(e) => setStateQuery(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                  {filterData?.map((el, i) => (
                                    <div
                                      key={`${i}`}
                                      className={`text-xs cursor-pointer p-2 flex items-center  space-x-3 ${
                                        checkInArray(el.value, selectedStates)
                                          ? "border-b-[1px] border-white"
                                          : "hover:bg-slate-100"
                                      }`}
                                      onClick={() => handleSelectedOption(el.value)}
                                    >
                                      <Checkbox checked={checkInArray(el.value, selectedStates)} />

                                      <p className="text-[12px]">{el.label}</p>
                                    </div>
                                  ))}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </>
                      ) : item.field === "birthdate" ? (
                        <div>{item.values}</div>
                      ) : (item.field !== "") & (findFilterObject(item.field).value === "gender") ? (
                        <div>
                          <SelectInput
                            name={`fieldsSelector.${i}.values`}
                            options={findFilterObject(item.field).options}
                            value={item.values}
                            onChange={(e) => setFieldValue(`fieldsSelector.${i}.values`, [e.target.value])}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
              <div>
                {item.field !== "" && values.fieldsSelector.length > 1 && (
                  <div className="w-fit p-2 border rounded" onClick={() => removeFilter(item.field)}>
                    <RiDeleteBin6Line />
                  </div>
                )}
              </div>
            </div>
            {item.field !== "" && values.fieldsSelector.length < 3 && (
              <div>
                <p className="w-fit px-4 rounded-md bg-primary-50 py-2 border text-sm" onClick={() => addFilter()}>
                  And
                </p>
              </div>
            )}
          </div>
        ))}
      </form>
      <div
        className={`flex justify-between border-t-[1px] fixed z-20 bottom-0 right-0 px-4 h-[60px] items-center bg-white transition-[width] duration-300 ease-in-out ${
          sidebar === "false" ? "w-[calc(100vw-80px)]" : "w-[calc(100vw-250px)]"
        }`}
      >
        <div></div>

        <div className="flex justify-between space-x-4">
          <GreyButton text="Cancel" onClick={() => navigate(-1)} />

          <BlueButton text={`Save`} disabled={disableButton} onClick={handleSubmit} />
        </div>
      </div>
      {openModal === 2 ? <CreateSegmentSucess handleModal={() => setOpenModal(null)} /> : null}
    </div>
  );
};

export default ManualSegment;
