import ModalWithoutClose from "components/ModalWithoutClose";
import ImportContacts from "../../pages/Contacts/NewContact/ImportContacts";
import IndividualEntry from "../../pages/Contacts/NewContact/IndividualEntry";
import UploadCSV from "../../pages/Contacts/NewContact/UploadCSV";
import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const tabs = [
  { name: "Upload CSV or XLSX file ", value: 0 },
  { name: "Individual Entry", value: 1 },
  // { name: "Connect contact API", value: 2 },
];

const ContactModal = ({ outSideContact, toggleModal, onClose }) => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const [newStep, setNewStep] = useState(true);

  const displayStep = () => {
    switch (step) {
      case 0:
        return (
          <div className="">
            <UploadCSV step={newStep} setStep={setNewStep} handleModal={toggleModal} outSideContact={outSideContact} />
          </div>
        );
      case 1:
        return (
          <div className="">
            <IndividualEntry handleModal={toggleModal} outSideContact={outSideContact} />
          </div>
        );
      case 2:
        return <ImportContacts />;
      default:
    }
  };

  const handleTabChange = (e) => {
    setStep(e);
  };

  const handleBack = () => {
    if (step === 0 && newStep) {
      navigate("/contact");
    } else if (step === 1) {
      setStep(0);
    } else if (step === 0 && !newStep) {
      setNewStep(true);
    }
  };

  return (
    <ModalWithoutClose>
      <div className="w-[700px] h-[70vh]  bg-white rounded-xl p-6 overflow-y-scroll">
        <div>
          <div className="flex justify-between">
            <div>
              <p className="text-[24px] font-[500]">Add contacts</p>
            </div>
            {/* <div
              // to="/contact/new-contact"
              onClick={handleBack}
              className="flex items-center space-x-1 my-2 bg-[#F6F0FE] w-[100px] text-primary-800 py-[4px] rounded-[20px] pl-2 cursor-pointer"
            >
              <FiChevronLeft />
              <p className="text-sm">Back</p>
            </div> */}
            <div
              className="text-[#737373] bg-[#ECECECB2] h-[30px] w-[30px] flex items-center justify-center rounded-full text-[20px] cursor-pointer"
              onClick={toggleModal}
            >
              <FaTimes className="w-[10px] " />
            </div>
          </div>

          <div className="flex space-x-4 mt-4">
            {tabs.map((el) => {
              return (
                <p
                  className={` pb-[4px] px-2 cursor-pointer ${
                    step === el.value
                      ? "border-b-[2px] text-[#0F172A] border-primary-800 font-semibold "
                      : "text-[#6C6685]"
                  } `}
                  onClick={() => handleTabChange(el.value)}
                  key={el.value}
                >
                  {el.name}
                </p>
              );
            })}
          </div>
          <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

          <div className=" h-[78vh] w-full">{displayStep()}</div>
        </div>
      </div>
    </ModalWithoutClose>
  );
};

export default ContactModal;
