import React from "react";
import Typography from "utils/typography";

const ChartCard = ({ title, subtitle, children, className }) => {
  return (
    <div className={`w-full h-[430px] p-5 drop-shadow-md bg-white rounded-lg ${className}`}>
      <div className="flex flex-col items-center">
        <Typography.SubText>{title}</Typography.SubText>
        <Typography.SubText className="text-gray-500">{subtitle}</Typography.SubText>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default ChartCard;
