import React from "react";
import { Route, Routes } from "react-router-dom";
import NPSInsightSummary from "./npsInsightSummary";
import CPInsightSummary from "./cpInsightSummary";
import CSATInsightSummary from "./csatInsightSummary";

const PublicInsightSummary = () => {
  return (
    <Routes>
      <Route exact path="/nps/:workspaceId" element={<NPSInsightSummary />} />
      <Route exact path="/cp/:workspaceId" element={<CPInsightSummary />} />
      <Route exact path="/csat/:workspaceId" element={<CSATInsightSummary />} />
    </Routes>
  );
};
export default PublicInsightSummary;
