import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import promoters from "assets/images/promoters.png";
import passives from "assets/images/passives.png";
import detractors from "assets/images/detractors.png";
import Typography from "utils/typography";
import { SmsIcon } from "assets/svgs/SmsIcon";
import { Messaging } from "assets/svgs/Messaging";
import { useLocation } from "react-router-dom";
import { formatDate } from "lib/formatDate";

const ResponseCard = ({ elm }) => {
  const [iconValue, setIconValue] = useState(null);
  const location = useLocation();
  const locationArray = location.pathname.split("/");
  const isNPS = locationArray.includes("nps");
  const case1 = isNPS ? "promoter" : "Satisfied";
  const case2 = isNPS ? "passive" : "Neutral";
  const case3 = isNPS ? "detractors" : "Unsatisfied";
  const category = isNPS ? elm?.scores?.value : elm?.scores?.value;

  const icon = (category) => {
    switch (category) {
      case case1:
        return promoters;
      case case2:
        return passives;
      case case3:
        return detractors;
      default:
    }
  };

  const messagingIcon = [
    {
      name: "email",
      value: 1,
      icon: (
        <Messaging fill1={iconValue === 1 ? "#95ADFF" : "#D4D4D4"} fill2={iconValue === 1 ? "#1D19AF" : "#404040"} />
      ),
      linkTo: "/broadcasts/email",
    },
    {
      name: "SMS",
      value: 2,
      icon: <SmsIcon fill1={iconValue === 2 ? "#95ADFF" : "#D4D4D4"} fill2={iconValue === 2 ? "#1D19AF" : "#404040"} />,
      linkTo: "/broadcasts/sms",
    },
  ];

  const handleHover = (id) => {
    return setIconValue(iconValue === id ? null : id);
  };

  const indicatorCSAT = category === 5 ? "bg-green-500" : category === 4 ? "bg-yellow-500" : "bg-red-500";
  const textIndicatorCSAT = category === 5 ? "bg-green-500" : category === 4 ? "bg-yellow-500" : "bg-red-500";
  const colorIndicatorCSAT = category === 5 ? "bg-green-500" : category === 4 ? "bg-yellow-500" : "bg-red-500";

  const indicatorNps = category >= 9 ? "bg-green-500" : category >= 6 ? "bg-yellow-500" : "bg-red-500";
  const textIndicatorNps = category >= 9 ? "text-green-500" : category >= 6 ? "text-yellow-500" : "text-red-500";
  const colorIndicatorNps = category >= 9 ? "bg-green-100" : category >= 6 ? "bg-yellow-100" : "bg-red-100";

  const indicator = isNPS ? indicatorNps : indicatorCSAT;
  const textIndicator = isNPS ? textIndicatorNps : textIndicatorCSAT;
  const colorIndicator = isNPS ? colorIndicatorNps : colorIndicatorCSAT;

  return (
    <div className="border rounded-lg w-full overflow-hidden h-[103px] relative bg-gray-50">
      <div className={`h-full w-[5px] absolute top-0 ${indicator}`}></div>
      <div className="p-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className={`flex items-center ${colorIndicator} w-fit px-3 py-1 space-x-1 rounded-xl`}>
              <div className="w-[20px] h-[20px]">
                <img src={icon(category)} alt="" />
              </div>
              <Typography.SubText>
                {" "}
                <p className={`${textIndicator} font-[700]`}>{elm?.scores?.value}</p>
              </Typography.SubText>
            </div>
            <div>
              <Typography.Text>
                {elm?.firstname} {elm?.lastname}
              </Typography.Text>
            </div>
          </div>
          <div>
            <Typography.SubText>{formatDate(elm?.responseTime?.endTime)}</Typography.SubText>
          </div>
        </div>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-center justify-between pl-[5rem]">
          {isNPS ? (
            <div className="">
              <Typography.SubText>{elm?.responses[0]?.question}</Typography.SubText>
            </div>
          ) : null}
          <div className="flex items-center space-x-2">
            <Typography.SubText>Actions:</Typography.SubText>
            <div className="flex items-center space-x-2">
              {messagingIcon.map((el, i) => (
                <div
                  className="cursor-pointer relative"
                  key={i}
                  onMouseEnter={() => handleHover(el.value)}
                  onMouseLeave={() => setIconValue(null)}
                >
                  <NavLink to={el.linkTo} className="">
                    {el.icon}
                  </NavLink>
                  {iconValue === el.value ? (
                    <div className="absolute top-4">
                      <p className="p-1 w-fit rounded-md bg-white text-[10px]">{el.name}</p>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponseCard;
