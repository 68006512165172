const { TiInfo } = require("react-icons/ti");

const WarningCard = ({ heading, info }) => {
  return (
    <div>
      <div className="border-[1px] p-4 rounded-[8px] bg-primary-50 flex space-x-2 my-4 mt-2">
        <TiInfo className="text-primary-800 text-[24px]" />
        <div className="flex items-center space-x-2">
          <p className="text-[14px font-[600] text-primary-800"> {heading} </p>

          <p className="text-s font-semibold">{info}</p>
        </div>
      </div>
    </div>
  );
};

export default WarningCard;
