import { useQuery } from "@tanstack/react-query";
import { fetchPlan } from "services/userService";

const usePlan = () => {
  const { data: plan, isFetching } = useQuery({ queryKey: ["getPlan"], queryFn: fetchPlan });
  console.log(plan, "from here");
  return {
    plan,
    isFetching,
  };
};

export default usePlan;
