import PrimaryInput from "components/Inputs/PrimaryInput";
import { formatCamelCaseToWords } from "lib/formatCamelCaseToWords";
import { removeEmptyKeysAndValues } from "lib/removeEmptyValueAndKey";
import { toTitleCase } from "lib/titleCase";
import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { FaCircleInfo } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import useSummaryStore from "store/useSummaryStore";
import Typography from "utils/typography";

const tabs = [
  { name: "Core", value: 0 },
  { name: "Income", value: 2 },
  { name: "Occupation", value: 1 },
];

const Core = ({
  ageData,
  countryData,
  genderData,
  cityData,
  stateData,
  educationLevelData,
  relationshipStatusData,
  parentalStatusData,
  handleFilter,
  filterData,
}) => {
  const [open, setOpen] = useState(null);
  const { ageFilter, setAgeFilter, setApplyAgeFilter } = useSummaryStore();

  const handleAgeFilters = (e) => {
    setAgeFilter(e.target.name, e.target.value);
  };
  console.log(cityData, "cityData");

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const handleAgeApply = () => {
    setApplyAgeFilter(true);
  };

  const transformGender = (value) => {
    switch (value) {
      case "male":
        return "Male";
      case "female":
        return "Female";
      case "preferNotToSay":
        return "Prefer not to say";
      default:
        return "";
    }
  };

  function transformAgeRangeToString(ageRange) {
    return `${ageRange?.gte}-${ageRange?.lte}`;
  }

  function sortAlphabetically(data) {
    return data?.sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <div className="space-y-3">
      <div>
        <div className={`px-4 pl-0`} onClick={() => handleModal("Age")}>
          <p className="text-ss">Age</p>
          {/* {open === "Age" ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />} */}
        </div>

        <div className="flex items-center space-x-4 my-2">
          <div className="w-[40px]">
            <PrimaryInput type="pin" maxNum={2} value={ageFilter.min} onChange={handleAgeFilters} name="min" />
          </div>

          <Typography.MicroText>to</Typography.MicroText>

          <div className="w-[40px]">
            <PrimaryInput type="pin" maxNum={2} value={ageFilter.max} onChange={handleAgeFilters} name="max" />
          </div>

          <Typography.MicroText
            className="hover:text-primary-800 text-gray-500 hover:underline cursor-pointer"
            onClick={handleAgeApply}
          >
            Apply
          </Typography.MicroText>
        </div>

        {/* {open === "Age" ? ( */}
        <>
          {ageData?.map((el) => (
            <div
              key={`ageData-${el.name}`}
              onClick={() => handleFilter("age", el.name)}
              className={`mt-2 rounded-md cursor-pointer  ${
                transformAgeRangeToString(filterData.age) === el.name ? "bg-primary-100" : "bg-gray-100"
              }`}
            >
              <div className="flex justify-between p-1 px-3 text-ss text-gray-600 ">
                <p>{el.name}</p>
                <p>
                  {el.percentage}% ({el.value})
                </p>
              </div>
            </div>
          ))}
        </>
        {/* ) : (
          <></>
        )} */}
      </div>

      <div>
        <div className={`px-4 pl-0`} onClick={() => handleModal("Gender")}>
          <p className="text-ss">Gender</p>
          {/* {open === "Gender" ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />} */}
        </div>

        {/* {open === "Gender" ? ( */}
        <div className="mt-2 space-y-2">
          {genderData?.map((el) => (
            <div
              className={`mt-2 rounded-md cursor-pointer  ${
                filterData.gender === el.name ? "bg-primary-100" : "bg-gray-100"
              }`}
              key={`genderData-${el.name}`}
              onClick={() => handleFilter("gender", el.name)}
            >
              <div className="flex justify-between  p-1 px-3 text-ss text-gray-600 ">
                <p>{transformGender(el.name)}</p>
                <p>
                  {el.percentage}% ({el.value})
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* ) : (
          <></>
        )} */}
      </div>

      <div>
        <div className={`px-4 pl-0`} onClick={() => handleModal("Country")}>
          <p className="text-ss">Country</p>
          {/* 
          {open === "Country" ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />} */}
        </div>

        {/* {open === "Country" ? ( */}
        <div className="mt-2 space-y-2">
          {sortAlphabetically(countryData)?.map((el) => (
            <div
              className={`mt-2 rounded-md cursor-pointer  ${
                filterData.country === el.name ? "bg-primary-100" : "bg-gray-100"
              }`}
              key={`countryData-${el.name}`}
              onClick={() => handleFilter("country", el.name)}
            >
              <div className="flex justify-between p-1 px-3 text-ss text-gray-600 ">
                <p>{toTitleCase(el.name)}</p>
                <p>
                  {el.percentage}% ({el.value})
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* ) : null} */}
      </div>

      <div>
        <div className={`px-4 pl-0`} onClick={() => handleModal("State")}>
          <p className="text-ss">State</p>

          {/* {open === "State" ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />} */}
        </div>

        {/* {open === "State" ? ( */}
        <div className="mt-2 space-y-2">
          {sortAlphabetically(stateData)?.map((el) => (
            <div
              className={`mt-2 rounded-md cursor-pointer  ${
                filterData.state === el.name ? "bg-primary-100" : "bg-gray-100"
              }`}
              key={`stateData-${el.name}`}
              onClick={() => handleFilter("state", el.name)}
            >
              <div className="flex justify-between  p-1 px-3 text-ss text-gray-600">
                <p>{toTitleCase(el.name)}</p>
                <p>
                  {el.percentage}% ({el.value})
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* ) : null} */}
      </div>

      <div>
        <div className={`px-4 pl-0`} onClick={() => handleModal("City")}>
          <p className="text-ss">City</p>

          {/* {open === "City" ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />} */}
        </div>

        {/* {open === "City" ? ( */}
        <div className="mt-2 space-y-2">
          {sortAlphabetically(cityData)?.map((el) => (
            <div
              className={`mt-2 rounded-md cursor-pointer  ${
                filterData.city === el.name ? "bg-primary-100" : "bg-gray-100"
              }`}
              key={`cityData-${el.name}`}
              onClick={() => handleFilter("city", el.name)}
            >
              <div className="flex justify-between  p-1 px-3 text-ss text-gray-600 ">
                <p>{toTitleCase(el.name)}</p>
                <p>
                  {el.percentage}% ({el.value})
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* ) : null} */}
      </div>

      <div>
        <div className={`px-4 pl-0`} onClick={() => handleModal("Education level")}>
          <p className="text-ss">Education level</p>
          {/* 
          {open === "Education level" ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />} */}
        </div>

        {/* {open === "Education level" ? ( */}
        <div className="mt-2 space-y-2">
          {educationLevelData?.map((el) => (
            <div
              className={`mt-2 rounded-md cursor-pointer  ${
                filterData.educationLevel === el.name ? "bg-primary-100" : "bg-gray-100"
              }`}
              key={`educationLevelData-${el.name}`}
              onClick={() => handleFilter("educationLevel", el.name)}
            >
              <div className="flex justify-between  p-1 px-3 text-ss text-gray-600 ">
                <p>{formatCamelCaseToWords(el.name)}</p>
                <p>
                  {el.percentage}% ({el.value})
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* ) : null} */}
      </div>

      <div>
        <div className={`px-4 pl-0`} onClick={() => handleModal("Relationship Status")}>
          <p className="text-ss">Relationship Status</p>

          {/* {open === "Relationship Status" ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />} */}
        </div>

        {/* {open === "Relationship Status" ? ( */}
        <div className="mt-2 space-y-2">
          {relationshipStatusData?.map((el) => (
            <div
              className={`mt-2 rounded-md cursor-pointer  ${
                filterData.relationshipStatus === el.name ? "bg-primary-100" : "bg-gray-100"
              }`}
              key={`relationshipStatusData-${el.name}`}
              onClick={() => handleFilter("relationshipStatus", el.name)}
            >
              <div className="flex justify-between  p-1 px-3 text-ss text-gray-600">
                <p>{formatCamelCaseToWords(el.name)}</p>
                <p>
                  {el.percentage}% ({el.value})
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* ) : null} */}
      </div>

      <div>
        <div className={`px-4 pl-0`} onClick={() => handleModal("Parental Status")}>
          <p className="text-ss">Parental Status</p>

          {/* {open === "Parental Status" ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />} */}
        </div>

        {/* {open === "Parental Status" ? ( */}
        <div className="mt-2 space-y-2">
          {parentalStatusData?.map((el) => (
            <div
              className={`mt-2 rounded-md cursor-pointer  ${
                filterData.parentalStatus === el.name ? "bg-primary-100" : "bg-gray-100"
              }`}
              key={`parentalStatusData-${el.name}`}
              onClick={() => handleFilter("parentalStatus", el.name)}
            >
              <div className="flex justify-between p-1 px-3 text-ss text-gray-600 ">
                <p>{formatCamelCaseToWords(el.name)}</p>
                <p>
                  {el.percentage}% ({el.value})
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* ) : null} */}
      </div>
    </div>
  );
};

const Personal = ({
  earningData,

  handleFilter,
  filterData,
}) => {
  const [open, setOpen] = useState(null);
  console.log(earningData);

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  return (
    <div className="space-y-3">
      <div>
        <div className={`px-4 pl-0`} onClick={() => handleModal("Earnings")}>
          <p className="text-ss">Earnings</p>

          {/* {open === "Earnings" ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />} */}
        </div>

        {/* {open === "Earnings" ? ( */}
        <div className="mt-2 space-y-2">
          {earningData?.map((el) => (
            <div
              className={`mt-2 rounded-md cursor-pointer  ${
                filterData.earning === el.name ? "bg-primary-100" : "bg-gray-100"
              }`}
              key={`earningData-${el.name}`}
              onClick={() => handleFilter("earning", el.name)}
            >
              <div className="flex justify-between  p-1 px-3 text-ss text-gray-600 ">
                <p>{el.name}</p>
                <p>
                  {el.percentage}% ({el.value})
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* ) : null} */}
      </div>
    </div>
  );
};

const Work = ({ employmentTypeData, categoryOfInterestData, occupationData, handleFilter, filterData }) => {
  const [open, setOpen] = useState(null);

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  return (
    <div className="space-y-3">
      <div>
        <div className={`px-4 pl-0`} onClick={() => handleModal("Employment type")}>
          <p className="text-ss">Employment type</p>

          {/* {open === "Employment type" ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />} */}
        </div>
        {/* {open === "Employment type" ? ( */}
        <div className="mt-2 space-y-2">
          {employmentTypeData?.map((el) => (
            <div
              className={`mt-2 rounded-md cursor-pointer  ${
                filterData.employmentStatus === el.name ? "bg-primary-100" : "bg-gray-100"
              }`}
              key={`employmentTypeData-${el.name}`}
              onClick={() => handleFilter("employmentStatus", el.name)}
            >
              <div className="flex justify-between  p-1 px-3 text-ss text-gray-600 ">
                <p>{formatCamelCaseToWords(el.name)}</p>
                <p>
                  {el.percentage}% ({el.value})
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* ) : null} */}
      </div>

      <div>
        <div className={`px-4 pl-0`} onClick={() => handleModal("Category of interest")}>
          <p className="text-ss">Category of interest</p>

          {/* {open === "Category of interest" ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />} */}
        </div>

        {/* {open === "Category of interest" ? ( */}
        <div className="mt-2 space-y-2">
          {categoryOfInterestData?.map((el) => (
            <div
              className={`mt-2 rounded-md cursor-pointer  ${
                filterData.categoryOfInterest === el.name ? "bg-primary-100" : "bg-gray-100"
              }`}
              key={`categoryOfInterestData-${el.name}`}
              onClick={() => handleFilter("categoryOfInterest", el.name)}
            >
              <div className="flex justify-between  p-1 px-3 text-ss text-gray-600">
                <p>{formatCamelCaseToWords(el.name)}</p>
                <p>
                  {el.percentage}% ({el.value})
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* ) : null} */}
      </div>

      <div>
        <div className={`px-4 pl-0`} onClick={() => handleModal("Occupation")}>
          <p className="text-ss">Occupation</p>

          {/* {open === "Occupation" ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />} */}
        </div>

        {/* {open === "Occupation" ? ( */}
        <div className="mt-2 space-y-2">
          {occupationData?.map((el) => (
            <div
              className={`mt-2 rounded-md cursor-pointer  ${
                filterData.occupation === el.name ? "bg-primary-100" : "bg-gray-100"
              }`}
              key={`occupationData-${el.name}`}
              onClick={() => handleFilter("occupationData", el.name)}
            >
              <div className="flex justify-between  p-1 px-3 text-ss text-gray-600">
                <p>{formatCamelCaseToWords(el.name)}</p>
                <p>
                  {el.percentage}% ({el.value})
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* ) : null} */}
      </div>
    </div>
  );
};

const Filters = ({
  filterData,
  handleFilter,
  refetchSummary,
  ageData,
  stateData,
  cityData,
  countryData,
  genderData,
  earningData,
  educationLevelData,
  relationshipStatusData,
  parentalStatusData,
  employmentTypeData,
  categoryOfInterestData,
  occupationData,
  setFilterData,
  resetAnswerFilter,
}) => {
  const [step, setStep] = useState(0);
  const { questionIndex, ageFilter, setAgeFilter, applyAgeFilter, setApplyAgeFilter } = useSummaryStore();

  // console.log(earningData, "earningData");

  const displayStep = () => {
    switch (step) {
      case 0:
        return (
          <Core
            ageData={ageData}
            refetchSummary={refetchSummary}
            countryData={countryData}
            genderData={genderData}
            handleFilter={handleFilter}
            stateData={stateData}
            cityData={cityData}
            educationLevelData={educationLevelData}
            relationshipStatusData={relationshipStatusData}
            parentalStatusData={parentalStatusData}
            filterData={filterData}
          />
        );
      case 2:
        return <Personal earningData={earningData} handleFilter={handleFilter} filterData={filterData} />;
      case 1:
        return (
          <Work
            employmentTypeData={employmentTypeData}
            categoryOfInterestData={categoryOfInterestData}
            occupationData={occupationData}
            handleFilter={handleFilter}
            filterData={filterData}
          />
        );
      default:
    }
  };

  const handleTabChange = (e) => {
    setStep(e);
  };

  const handleClick = (valueToRemove) => {
    // const valueToRemove = 'value1';
    const newObj = {};

    for (const [key, value] of Object.entries(filterData)) {
      if (value !== valueToRemove) {
        newObj[key] = value;
      }
    }

    setFilterData(newObj);
  };

  const clearAgeFilters = () => {
    setAgeFilter("min", 0);
    setAgeFilter("max", 0);
    setApplyAgeFilter(false);
  };

  const removeKey = (keyToRemove) => {
    const newObj = { ...filterData };
    delete newObj[keyToRemove];
    setFilterData(newObj);
  };

  return (
    <div className="">
      <div className="">
        <p className="text-sm font-semibold">Answer filters</p>

        <p className="text-xs my-2">Click answers to apply</p>

        {questionIndex !== "" ? (
          <div className="bg-primary-50 text-primary-800 p-1 rounded-md w-[50px] my-2 flex items-center justify-center space-x-1 cursor-pointer">
            <Typography>Q{questionIndex + 1}</Typography> <IoClose onClick={resetAnswerFilter} />
          </div>
        ) : null}

        <div className="text-[9px] bg-primary-50 flex items-center space-x-2 p-2 rounded-md text-primary-800 ">
          <FaCircleInfo />
          <p>Only three filter sets can be applied at a time.</p>
        </div>

        <div className="border-[1px]  flex items-center justify-between rounded p-2 my-3">
          <p className="text-xs flex space-x-2 items-center  ">
            <span>Demographic filters</span>
            <span className="bg-primary-50 w-[20px] h-[20px] flex items-center justify-center rounded-full">
              {Object.keys(removeEmptyKeysAndValues(filterData)).length}
            </span>{" "}
          </p>

          <p
            className="text-ss hover:text-primary-800 text-gray-500 hover:underline cursor-pointer "
            onClick={() => handleFilter("reset", "")}
          >
            Clear
          </p>
        </div>

        {applyAgeFilter && (
          <div className="bg-primary-50 text-primary-800 p-1 rounded-md w-[80px] my-2 flex items-center justify-center space-x-1 cursor-pointer">
            <Typography>{ageFilter.min}</Typography>
            <Typography> - </Typography>

            <Typography>{ageFilter.max}</Typography>
            <IoClose onClick={clearAgeFilters} />
          </div>
        )}

        <div>
          {Object.values(removeEmptyKeysAndValues(filterData)).map((el, i) => {
            if (typeof el === "object") {
              return (
                <div className="p-2 rounded-md text-xs bg-neutral-100 my-2 flex justify-between items-center">
                  <p>
                    {" "}
                    {removeEmptyKeysAndValues(filterData).age.gte} - {removeEmptyKeysAndValues(filterData).age.lte}
                  </p>

                  <FaTimes onClick={() => removeKey("age")} />
                </div>
              );
            } else {
              return (
                <div
                  className="p-2 rounded-md text-xs bg-neutral-100 my-2 flex justify-between items-center"
                  key={`filterData--${el}`}
                >
                  <p> {toTitleCase(el)}</p>

                  <FaTimes onClick={() => handleClick(el)} />
                </div>
              );
            }
          })}
        </div>
      </div>

      <div className="flex space-x-2 mt-4">
        {tabs.map((el) => {
          return (
            <p
              className={` pb-[6px] text-s px-2 cursor-pointer ${
                step === el.value
                  ? "border-b-[2px] text-neutral-800 border-primary-800 font-semibold "
                  : "text-[#6C6685]"
              } `}
              onClick={el.value !== 3 ? () => handleTabChange(el.value) : null}
              key={el.value}
            >
              {el.name}
            </p>
          );
        })}
      </div>
      <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>
      <div>{displayStep()}</div>
    </div>
  );
};

export default Filters;
