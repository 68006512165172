import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import MetricCard from "./metricsCard";
import StackedBarCharts from "../Charts/StackedBarCharts";
import CopyAction from "assets/svgs/actionsSvgs/CopyAction";
import DownloadAction from "assets/svgs/actionsSvgs/DownloadAction";
import PowerOffAction from "assets/svgs/actionsSvgs/PowerOffAction";
import PlayAction from "assets/svgs/actionsSvgs/PlayAction";
import ShareAction from "assets/svgs/actionsSvgs/ShareAction";
import Loading from "assets/images/loading.gif";
import EditAction from "assets/svgs/actionsSvgs/EditAction";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "utils/typography";
import PieChart from "./metricsCard/PieChart";
import HalfDoughnutChart from "./metricsCard/HalfPieChart";
import Skeleton from "react-loading-skeleton";
import dayjs from "dayjs";
import { baseMooyiRewardsURL } from "helpers/getBaseUrl";
import Toast from "config/toast";
import Dropdown from "../../../../components/DropDown";
import { useInsightInhibitAction, useFetchInsightCSV } from "hooks/useInsightOverview";
import Play2Action from "assets/svgs/actionsSvgs/Play2Action";
import ShareModal from "../../../Research/components/Analytics/ShareModal";
import CSVModal from "../../../Research/components/Analytics/CSVModal";
import SurveyModals from "components/Modals/SurveyModals";
import closeSVG from "assets/svgs/Close.svg";
import pauseSVG from "assets/svgs/pause.svg";
import restartSVG from "assets/svgs/restart.svg";

const OverviewTab = ({
  overViewData,
  graphData,
  isLoading,
  insights,
  dates,
  setFilterPublishId,
  insightType,
  insightId,
  surveyStatus,
  shareLink,
}) => {
  const cleanData = graphData?.map((elm) => ({
    Promoters: elm.promoter,
    Detractors: elm.detractor,
    Passives: elm.passive,
    Date: dayjs(elm.publishDate).format("DD/MM/YYYY"),
  }));

  const cleanCSATData = graphData?.map((elm) => ({
    Neutral: elm.neutral,
    Satisfied: elm.satisfied,
    Unsatisfied: elm.unsatisfied,
    Date: dayjs(elm.publishDate).format("DD/MM/YYYY"),
  }));

  const [active, setActive] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [publishId, setPublishId] = useState(null);
  const { data: csvData, refetch: csvRefetch } = useFetchInsightCSV(insightType, publishId);
  const [open, setOpen] = useState(null);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [showRestartModal, setShowResumeModal] = useState(false);
  const [action, setAction] = useState(null);
  const [togglePause, setTogglePause] = useState(false);

  const locationArray = location.pathname.split("/");

  const {
    data: actionData,
    refetch: actionRefetch,
    isLoading: actionIsLoading,
    isError: actionIsError,
  } = useInsightInhibitAction(insightId, action);

  const queryClient = useQueryClient();

  useEffect(() => {
    const cachedData = queryClient.getQueryData([`getInsight-${insightType}`]);

    if (cachedData && cachedData?.insights[0]?.surveyStatus !== surveyStatus) {
      queryClient.invalidateQueries([`getInsight-${insightType}`]);
    }
  }, [surveyStatus, insightType, queryClient]);

  useEffect(() => {
    if (surveyStatus === "paused") {
      setTogglePause(true);
    }
  }, [surveyStatus]);

  useEffect(() => {
    if (actionData) {
      Toast.success(actionData?.message);
      if (showCloseModal) {
        setShowCloseModal(false);
      } else if (showPauseModal) {
        setShowPauseModal(false);
      } else if (showRestartModal) {
        setShowResumeModal(false);
      }
    } else if (actionIsError) {
      Toast.error("Failed!");
    }
  }, [actionData, actionIsError]);

  useEffect(() => {
    if (action) {
      actionRefetch();

      if (action === "pause" || action === "resume") {
        setTogglePause((val) => !val);
      }
    }
  }, [action, actionRefetch]);

  const handleHover = (id) => {
    setActive(active === id ? null : id);
  };

  const handleEdit = () => {
    if (locationArray.includes("nps")) {
      navigate(`/insights/setup/nps?insightId=${insights._id}`);
    } else if (locationArray.includes("csat")) {
      navigate(`/insights/setup/csat?insightId=${insights._id}`);
    } else {
      navigate(`/insights/setup/customer-profile?insightId=${insights._id}`);
    }
  };
  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const surveyLink = `${baseMooyiRewardsURL}insights/${insights._id}?userId=`;

  const copyFunc = async () => {
    await navigator.clipboard.writeText(`${surveyLink?.toString()}`);
    Toast.success("Link copied successfully");
  };

  const controlCSVModal = () => {
    csvRefetch();
    handleModal(2);
  };

  const actionButtons = [
    {
      id: 1,
      name: "Copy link",
      action: (
        <CopyAction
          fill1={active === 1 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 1 ? "#95ADFF" : "#404040"}
          fill3={active === 1 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
      onClick: copyFunc,
    },
    {
      id: 2,
      name: "Export",
      action: (
        <DownloadAction
          fill1={active === 2 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 2 ? "#95ADFF" : "#404040"}
          fill3={active === 2 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
      onClick: controlCSVModal,
    },
    {
      id: 3,
      name: "Close survey",
      action: (
        <PowerOffAction
          fill1={active === 3 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 3 ? "#95ADFF" : "#404040"}
          fill3={active === 3 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
      onClick: () => {
        setShowCloseModal(true);
      },
    },
    togglePause === false
      ? {
          id: 4,
          name: "Pause survey",
          action: (
            <PlayAction
              fill1={active === 4 ? "#1D19AF" : "#DFE5EC"}
              fill2={active === 4 ? "#95ADFF" : "#404040"}
              fill3={active === 4 ? "#1D19AF" : "#A3A3A3"}
            />
          ),
          onClick: () => {
            setShowPauseModal(true);
          },
        }
      : {
          id: 4,
          name: "Resume survey",
          action: (
            <Play2Action
              fill1={active === 4 ? "#1D19AF" : "#DFE5EC"}
              fill2={active === 4 ? "#95ADFF" : "#404040"}
              fill3={active === 4 ? "#1D19AF" : "#A3A3A3"}
            />
          ),
          onClick: () => {
            setShowResumeModal(true);
          },
        },
    {
      id: 5,
      name: "Share",
      action: (
        <ShareAction
          fill1={active === 5 ? "#1D19AF" : "#DFE5EC"}
          fill2={active === 5 ? "#95ADFF" : "#404040"}
          fill3={active === 5 ? "#1D19AF" : "#A3A3A3"}
        />
      ),
      onClick: () => {
        handleModal(1);
      },
    },
  ];

  const filterByDateOptions = dates
    ? dates?.map((date) => {
        return { label: dayjs(date.date).format("YYYY-MM-DD"), value: date.id };
      })
    : [];

  // alert(insightType)
  const pieChartCSATData = {
    Satisfied: overViewData[1]?.value,
    Neutral: overViewData[2]?.value,
    Unsatisfied: overViewData[3]?.value,
  };
  const pieChartNPSData = {
    Promoters: overViewData[1]?.value,
    Passives: overViewData[2]?.value,
    Detractors: overViewData[3]?.value,
  };
  const pieChartData = insightType === "nps" ? Object.values(pieChartNPSData) : Object.values(pieChartCSATData);
  const piecChartLabels = insightType === "nps" ? Object.keys(pieChartNPSData) : Object.keys(pieChartCSATData);

  return (
    <div>
      <div>
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-row gap-4 items-center w-[20%]">
            <Typography.SubText>Filter:</Typography.SubText>
            <div className="border-0  w-[90%]">
              <Dropdown
                tag={""}
                options={[{ label: "Select date", value: " " }, ...filterByDateOptions]}
                onSelect={(e) => {
                  setPublishId(e.value);
                  setFilterPublishId(e.value);
                }}
                selectedValue={" "}
              />
            </div>
          </div>
          <div className="flex space-x-2 items-center">
            <Typography.SubText>Actions:</Typography.SubText>
            <div className="flex items-center space-x-2 relative">
              {insights?.surveyStatus === "draft" ? (
                <div
                  className="cursor-pointer"
                  onMouseEnter={() => handleHover(6)}
                  onMouseLeave={() => setActive(null)}
                  onClick={handleEdit}
                >
                  <EditAction
                    fill1={active === 6 ? "#1D19AF" : "#DFE5EC"}
                    fill2={active === 6 ? "#95ADFF" : "#404040"}
                    fill3={active === 6 ? "#1D19AF" : "#A3A3A3"}
                  />

                  {active === 6 ? (
                    <div className="absolute top-10">
                      <p className="px-3 py-1 w-fit rounded-md bg-white text-[10px]">Edit</p>
                    </div>
                  ) : null}
                </div>
              ) : (
                <>
                  {actionButtons.map((el, i) => (
                    <div
                      key={i}
                      className="cursor-pointer"
                      onMouseEnter={() => handleHover(el.id)}
                      onMouseLeave={() => setActive(null)}
                      onClick={el.onClick}
                    >
                      {el.action}
                      {active === el.id ? (
                        <div className="absolute top-10">
                          <p className="px-3 py-1 w-fit rounded-md bg-white text-[10px]">{el.name}</p>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex space-x-4 mt-[2em]">
          <div className="flex flex-col items-center w-[25%] bg-white p-4 border-[1px] rounded-lg space-y-[3em]">
            <div className="w-[100%] h-[140px] flex flex-col space-y-4">
              <Typography.H4 className="text-center">Score</Typography.H4>

              <div className="mx-auto relative">
                {/* <PieChart className={"text-primary-800"} value={isLoading ? 0 : overViewData[0]?.value} /> */}
                <HalfDoughnutChart data={pieChartData} labels={piecChartLabels} />

                <div className=" w-full flex flex-col items-center ">
                  {isLoading ? (
                    <Skeleton count={1} className="w-[40%]" />
                  ) : (
                    <Typography.Headers>{overViewData[0]?.value}</Typography.Headers>
                  )}

                  {/* <Typography.SubText>{title}</Typography.SubText> */}
                </div>
              </div>
            </div>

            <div className="border-t-[1px] w-[100%] pt-5 space-y-4">
              {overViewData?.slice(1).map((el, i) => (
                <div key={i} className="w-full">
                  <MetricCard elm={el} type={el.name} value={el.value} title={el.name} loading={isLoading} />
                </div>
              ))}
            </div>
          </div>

          <div className="flex bg-white p-6  rounded-lg border-[1px] w-[75%]">
            {isLoading ? (
              <div className="w-full h-full flex items-center justify-center">
                <img src={Loading} alt="Loading..." className="w-[200px]" />
              </div>
            ) : (
              <StackedBarCharts
                data={locationArray.includes("nps") ? cleanData : cleanCSATData}
                dataKey={"Date"}
                className="w-[100%] h-[85%]"
              />
            )}
          </div>
        </div>

        {open === 1 ? <ShareModal handleModal={handleModal} data={location.state} shareLink={shareLink} /> : null}

        {open === 2 ? <CSVModal handleModal={handleModal} fileUrl={csvData?.filePath} data={location.state} /> : null}

        {showCloseModal && (
          <SurveyModals
            title="Close Survey"
            setShowModal={setShowCloseModal}
            setAction={() => setAction("close")}
            yesBtnText="Yes,close"
            actionType="close"
            img={closeSVG}
            isLoading={actionIsLoading}
          >
            <div className="text-[14px]">Are you sure you want to close this survey?</div>
          </SurveyModals>
        )}

        {showPauseModal && (
          <SurveyModals
            title="Pause Survey"
            setShowModal={setShowPauseModal}
            setAction={() => setAction("pause")}
            yesBtnText="Yes,pause"
            actionType="pause"
            img={pauseSVG}
            isLoading={actionIsLoading}
          >
            <div className="flex flex-col items-center justify-center">
              <div className="text-[14px]">Are you sure you want to pause the survey?</div>
              <div className="text-[14px]">You will stop receiving responses until you restart.</div>
            </div>
          </SurveyModals>
        )}

        {showRestartModal && (
          <SurveyModals
            title="Resume Survey"
            setShowModal={setShowResumeModal}
            setAction={() => setAction("resume")}
            yesBtnText="Resume survey"
            actionType="resume"
            img={restartSVG}
            isLoading={actionIsLoading}
          >
            <div className="flex flex-col items-center justify-center">
              <div className="text-[14px]">Do you really want to restart the survey?</div>
            </div>
          </SurveyModals>
        )}
      </div>
    </div>
  );
};

export default OverviewTab;
