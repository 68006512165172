import React, { useState } from "react";
import SurveyDetailsModal from "./components/SurveyDetailsModal";
import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import Typography from "utils/typography";
import { FaChevronLeft } from "react-icons/fa";
import Os from "assets/images/os.png";
import Fi from "assets/images/fi.png";
// import Bt from "assets/images/bt.png";
import CreateSurveyModal from "./components/CreateSurveyModal";
import PreviewTemplateModal from "./components/PreviewTemplateModal";
import { surveyTemplates } from "data/surveyTemplates";
import { cn } from "helpers/classHelpers";
import { hasFeatureAccess } from "lib/useFeatureAccess";

const cardData = [
  {
    id: "researchCard1",
    name: "Online surveys",
    description: " Gather valuable and actionable insights from our 3M+ audience or your contacts.",
    image: Os,
    key: "onlineSurvey",
  },

  // {
  //   id: "researchCard3",
  //   name: "Brand tracking",
  //   description: "Track key brand metrics, understand user behaviours & enhance your brand image.  ",
  //   image: Bt,
  //   key: "brandTracking",
  //   plan: "pro",
  // },

  {
    id: "researchCard2",
    name: "Field interviews",
    description: "Connect to a wider audience using our field surveys.",
    image: Fi,
    key: "fieldInterview",
    plan: "pro",
  },
];
const SetupResearch = () => {
  const [open, setOpen] = useState(null);
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const [previewTemplateInfo, setPreviewTemplateInfo] = useState();

  const handleOpen = (id) => {
    setOpen(open === id ? null : id);
  };

  const handleTemplatePreview = (id) => {
    const foundTemplate = surveyTemplates.find((el) => el.id === id);
    handleOpen(id === foundTemplate.id ? 4 : null);
    setPreviewTemplateInfo(foundTemplate);
  };

  const handleNext = () => {
    if (selected === "onlineSurvey") {
      handleOpen(2);
    } else {
      handleOpen(3);
    }
  };
  return (
    <div>
      <div className="bg-white h-[calc(100vh-150px)] rounded border-[1px] p-6 py-[3em]">
        <div className="flex items-center ">
          <Typography.SubText
            className="flex items-center text-primary-700 space-x-2 cursor-pointer "
            onClick={() => navigate("/research")}
          >
            <FaChevronLeft className="" />
            <span className="font-semibold"> Go back</span>
          </Typography.SubText>

          <Typography.H3 className="w-[85%] text-center">
            Gather insights from your target customers in multiple ways
          </Typography.H3>
        </div>

        <div className="flex items-center space-x-7 py-[3em] w-[85%] mx-auto">
          {cardData.map((el) => (
            <div
              key={el.id}
              className={cn(
                `border-[1px] rounded-md h-[280px] w-[50%] flex flex-col justify-between items-center overflow-hidden cursor-pointer ${
                  el.key === selected ? "border-primary-800" : ""
                }`,
                hasFeatureAccess(el.key) ? "opacity-[0.5]" : "",
              )}
              onClick={hasFeatureAccess(el.key) ? null : () => setSelected(el.key)}
            >
              <div className="bg-gray-50 h-[55%] w-[100%] flex items-start justify-between p-4">
                <div className="w-[90%] h-[100%] flex items-center justify-center">
                  <img src={el.image} alt={el.id} className="w-[80px] mx-auto" />
                </div>

                {hasFeatureAccess(el.key) ? null : (
                  <div
                    className={`border-[1px] rounded-full  h-[15px] w-[15px] flex items-center justify-center ${
                      el.key === selected ? "border-primary-800" : "border-gray-400"
                    }`}
                  >
                    {el.key === selected ? <div className="h-[9px] w-[9px] rounded-full bg-primary-800"> </div> : null}
                  </div>
                )}
              </div>

              <div className="text-center p-4  space-y-2 h-[45%] flex items-center flex-col justify-center w-[100%]">
                <Typography.SubText className="font-semibold">{el.name}</Typography.SubText>

                <Typography.MicroText className="w-[90%] mx-auto ">{el.description}</Typography.MicroText>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center">
          <p className="text-sm text-neutral-600 font-semibold">Need help getting started?</p>

          <div className="text-primary-800 text-xs flex space-x-3 items-center mt-3">
            <a href="https://usemooyi.com/faq" target="_blank" rel="noopener noreferrer">
              Visit our FAQ
            </a>

            <span className="border-neutral-300 border-l-[1px] h-[20px]"></span>

            <a
              href="https://www.youtube.com/playlist?list=PLo_fqoPSbelV7UeZDu4rEiajv7JCm6MMp"
              target="_blank"
              rel="noopener noreferrer"
            >
              Watch tutorial video
            </a>
          </div>
        </div>
      </div>

      <div
        className={`flex justify-between border-t-[1px] fixed bottom-0 right-0 px-4 h-[60px] items-center bg-white ${
          params.get("sidebar") === "true" ? "w-[calc(100vw-80px)] " : "w-[calc(100vw-250px)]"
        } transition ease-in`}
      >
        <div onClick={() => navigate("/research")}>
          <GreyButton text="Cancel" className="w-[140px]" />
        </div>

        <div className="flex justify-between space-x-4">
          <BlueButton text={"Next"} className="h-[40px] text-s w-[140px]" onClick={handleNext} />
        </div>
      </div>

      {open === 3 ? <SurveyDetailsModal from={selected} handleOpen={() => handleOpen(3)} /> : null}

      {open === 2 ? <CreateSurveyModal handleModal={handleOpen} handleTemplatePreview={handleTemplatePreview} /> : null}
      {open === 4 ? (
        <PreviewTemplateModal
          handleClose={handleOpen}
          data={previewTemplateInfo}
          handleTemplateUse={() => handleOpen(3)}
        />
      ) : null}
    </div>
  );
};

export default SetupResearch;
