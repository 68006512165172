import GreyButton from "components/Button/GreyButton";
import Modal from "components/Modal";
import { baseAppURL } from "helpers/getBaseUrl";
import { copyFunc } from "lib/copyFunc";
import React from "react";
import { FaFacebook, FaTelegram, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { idText } from "typescript";

const ShareModal = ({ data, handleModal }) => {
  const { id } = useParams();

  console.log({ id });

  const surveyLink = `${baseAppURL}public/email-summary/${id}`;

  return (
    <div>
      <Modal title="Share broadcast report" onClose={handleModal}>
        <div className="w-[500px] px-5">
          <p className="text-s">Copy your report link to share your broadcast report.</p>

          <div className="mt-[2em]">
            <div className="flex justify-between">
              <div className="h-[40px] rounded-md border-[1px] flex items-center justify-center w-[75%]">
                <p className="text-ss">
                  {surveyLink?.substring(0, 55)} {surveyLink?.length > 55 ? "..." : ""}
                </p>
              </div>
              <GreyButton text="Copy Link" onClick={() => copyFunc(surveyLink, "Link Copied")} />
            </div>
          </div>

          <div className="flex flex-col justify-center space-y-1 items-center mt-[2em]">
            <p className="text-s">Share via:</p>

            <div className="flex items-center space-x-2">
              <FaWhatsapp />
              <FaTwitter />
              <FaFacebook />
              <FaTelegram />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ShareModal;
