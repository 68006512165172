import { useMutation } from "@tanstack/react-query";
import BlueButton from "../../../components/Button/BlueButton";
import SelectInput from "../../../components/Inputs/SelectInput";
import { queryClient } from "../../../config/gateway";
import { trackEvent } from "../../../config/mixpanel";
import Toast from "../../../config/toast";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { uploadFile } from "../../../utils/upload";
import useLoggedInUser from "../../../hooks/useLoggedInUser";
import dayjs from "dayjs";

const MatchColumns = ({ fileHeaders, topThreeRows, rawFile, fileName, tagArr, outSideContact, handleModal }) => {
  const [columnMatch, setColumMatch] = useState({
    firstname: "",
    lastname: "",
    gender: "",
    email: "",
    phone: "",
    birthday: "",
    address: "",
  });
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(true);
  const { user } = useLoggedInUser();

  const { mutate, isLoading } = {
    mutationFn: uploadFile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["allContacts", "onBoarding"] });
      Toast.success("Contacts added successfully");
      navigate("/contact");
    },
    onError: (error) => {
      Toast.error(error);
    },
  };

  console.log(columnMatch);

  const { mutate1, isLoading1 } = useMutation({
    mutationFn: uploadFile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["allContacts", "onBoarding"] });
      Toast.success("Contacts added successfully");

      handleModal();
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const [defaultFields] = useState([
    { value: "", label: "DO NOT IMPORT" },
    { value: "firstname", label: "FIRST NAME" },
    { value: "lastname", label: "LAST NAME" },
    { value: "gender", label: "GENDER" },
    { value: "email", label: "EMAIL" },
    { value: "phone", label: "PHONE" },
    { value: "address", label: "ADDRESS" },
    { value: "birthday", label: "DATE OF BIRTH" },
  ]);

  const handleColumnMatch = (e, headingName) => {
    setColumMatch((prevState) => {
      return {
        ...prevState,
        [e.target.value]: headingName,
      };
    });

    if (e.target.value === "email" || e.target.value === "phone") {
      setDisableButton(false);
    }
  };

  const topRowsFormatted = useMemo(() => {
    // compile the three top rows data into a
    // format that makes for easy display in column matching table

    let finalObj = {};

    for (const heading of fileHeaders) {
      for (const row of topThreeRows) {
        if (heading in finalObj) {
          finalObj[heading].push(row[heading]);
        } else {
          finalObj[heading] = [row[heading]];
        }
      }
    }

    return finalObj;
  }, [fileHeaders, topThreeRows]);

  const handleSubmit = async (e) => {
    const generateUploadObj = () => {
      let finalObj = {};

      for (const property in columnMatch) {
        if (columnMatch[property]) {
          finalObj[`fields[${property}]`] = columnMatch[property];
        }
      }

      return finalObj;
    };

    generateUploadObj();

    const parts = fileName.split(".");
    const lastString = parts[parts.length - 1];

    try {
      e.preventDefault();
      const userData = {
        ...generateUploadObj(),
        contact: rawFile,
        fileType: lastString,
        tag: tagArr,
      };

      if (outSideContact) {
        mutate1(userData);
        trackEvent("add_contact", {
          email: user?.data?.email,
          addContactMethod: "CSV upload",
          date: dayjs().format(),
        });
      } else {
        mutate(userData);
        trackEvent("add_contact", {
          email: user?.data?.email,
          addContactMethod: "CSV upload",
          date: dayjs().format(),
        });
      }
    } catch (error) {
      Toast.error(error);
    }
  };

  return (
    <div className="pb-[3em]">
      <p className="text-[24px] font-[600]">Preview File</p>
      <p className="text-[#737373]">Match column labels to contact information </p>
      <div>
        {fileHeaders.map((heading, i) => {
          return (
            <div key={i} className="rounded-[12px] py-4  flex space-x-8 w-[100%]">
              <div className="border-[1px] w-[50%] rounded-[6px]">
                <p className=" text-black bg-[#F5F5F5] px-4 py-2 text-s font-[500]">{heading}</p>

                <div className="p-4 space-y-1 text-s font-[400]">
                  {topRowsFormatted[heading].map((ele, i) => {
                    return <p key={i}>{ele}</p>;
                  })}
                </div>
              </div>

              <div className="w-[40%]">
                <p className="font-[600]">Match to</p>
                <SelectInput
                  options={defaultFields}
                  name={heading}
                  onChange={(e) => handleColumnMatch(e, heading)}
                  css="h-[46px] text-sm rounded-lg font-medium text-black border-[#BFCCDA]"
                />
              </div>
            </div>
          );
        })}

        <BlueButton
          text="Submit"
          disabled={disableButton || isLoading || isLoading1}
          onClick={handleSubmit}
          type="button"
        />
      </div>
    </div>
  );
};

export default MatchColumns;
