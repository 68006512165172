import React from "react";

const Play2Action = ({ fill1, fill2, fill3 }) => (
  <svg fill="none" width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.4" y="0.4" width="39.2" height="39.2" rx="3.6" fill="#FAFAFA" />
    <rect x="0.4" y="0.4" width="39.2" height="39.2" rx="3.6" stroke={fill1} stroke-width="0.8" />

    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z"
      fill={fill2}
      transform="translate(10 10) scale(0.8)"
      opacity={0.5}
    />
  </svg>
);

export default Play2Action;
