import OnBoarding from "assets/images/onboarding.png";
import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getToken, removeToken, removeWorkspaceToken, setWorkspaceToken } from "helpers/authHelper";
import useLoggedInUser from "hooks/useLoggedInUser";
import { IoIosArrowDown, IoMdCheckmark } from "react-icons/io";
import { useStore } from "store";
import { queryClient } from "config/gateway";
import { onboardingProgress } from "services/userService";
import PreLoader from "components/Loader/PreLoader";
import useOnclickOutside from "react-cool-onclickoutside";
import { getWorkspacePlan } from "services/workspaceService";
import BlueButton from "components/Button/BlueButton";
import BalanceModal from "./BalanceModal";
import Sidebar from "components/Sidebar";
import { getHeader } from "lib/getHeader";
import { HiMiniCreditCard } from "react-icons/hi2";
import usePlan from "hooks/usePlan";
import { toTitleCase } from "lib/titleCase";
import TopUpModal from "pages/Settings/Campaigns/TopUpModal";
import { useSideBarStore } from "store/sidebar/sidebarStore";
import useSurveyStore from "store/insightStore";
import useUserPlanStore from "store/useUserPlanStore";
import Typography from "utils/typography";
import useWorkspace from "hooks/useWorkspace";
import ProductTour from "./WorkThrough";

const DashLayout = () => {
  const [openSidebar, setOpenSidebar] = useState(true);
  const { setSideBar } = useSideBarStore();
  const [params] = useSearchParams();
  const { plan } = usePlan();

  const handleSidebarOpen = () => {
    setOpenSidebar(!openSidebar);
    if (openSidebar) {
      params.set("sidebar", "true");
      setSideBar("false");
    } else {
      params.set("sidebar", "false");
      setSideBar("true");
    }
  };

  const planData = plan?.plan;

  const { user } = useLoggedInUser();

  const [wsToken, setWsToken] = useState("");

  const { data } = useWorkspace();

  const {
    data: onBoardingData,
    isLoading: boardingLoading,
    isFetching: boardingFetch,
  } = useQuery({ queryKey: ["onBoarding"], queryFn: onboardingProgress, enabled: !!wsToken });
  const { data: wsData } = useQuery({ queryKey: ["wsData"], queryFn: getWorkspacePlan, enabled: !!wsToken });

  const { setUser, setOnBoarding, onBoarding } = useStore();
  const { setInsightCategory } = useSurveyStore();
  const { setPlan } = useUserPlanStore();

  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const toggleDropdown = (id) => {
    setOpen(open === id ? null : id);
  };

  const refer = useOnclickOutside(toggleDropdown);

  const logOut = () => {
    removeToken();
    removeWorkspaceToken();
    queryClient.clear();
    navigate("/auth/login");
  };

  useEffect(() => {
    setUser(user?.data);
    setOnBoarding(onBoardingData);
    // setPlan("free");

    setPlan(wsData?.data?.plan);
  }, [onBoardingData, setOnBoarding, setUser, setPlan, user?.data, wsData?.data?.plan]);

  useEffect(() => {
    setWorkspaceToken(data?.token);
    setWsToken(data?.token);
    setInsightCategory(user?.data?.business?.category);
  }, [data, setInsightCategory, user?.data?.business?.category]);

  useEffect(() => {
    let token = getToken();

    if (!token) {
      navigate("/auth/login");
    }
  }, [navigate]);

  if (boardingLoading || boardingFetch) {
    return <PreLoader text="Loading" />;
  }

  const checkRoute = () => {
    toggleDropdown();
    if (onBoarding?.progress < 50) {
      navigate("/dashboard/kyc");
    } else if (onBoarding?.progress === 50) {
      navigate("/contact");
    } else if (onBoarding?.progress === 75) {
      navigate("/messaging");
    }
  };

  // console.log(showAgain, "showAgain");

  return (
    <div className="flex h-[100vh] bg-white">
      {/* {showAgain === null ? <TestIntro /> : null} */}
      {/* <TestIntro /> */}
      <ProductTour />
      <Sidebar openSidebar={openSidebar} handleSidebarOpen={handleSidebarOpen} />

      {/* open === 6 */}
      {open === 6 ? <BalanceModal toggleDropdown={toggleDropdown} /> : null}
      {open === 7 ? <TopUpModal handleModal={() => toggleDropdown(7)} /> : null}

      <div
        className={`${
          openSidebar ? "w-[calc(100vw-250px)]" : "w-[calc(100vw-80px)]"
        } h-[100vh]   bg-[#F7F7F8]  border-[1px]`}
      >
        <div className="flex justify-between h-[56px]  items-center px-6 bg-white">
          <p className="text-[16px] font-semibold">{getHeader(window.location.href)}</p>

          <div className="relative">
            <div className="flex items-center space-x-5 divide-x cursor-pointer">
              <div
                className="flex items-center space-x-2 px-5 text-white bg-primary-800 border-primary-200 border-[4px] rounded-[8px] p-[6px] hover:bg-primary-600 balance"
                onClick={() => toggleDropdown(6)}
              >
                <HiMiniCreditCard />
                <Typography.MicroText className="text-sm text-white">Account Balance</Typography.MicroText>

                <IoIosArrowDown />
              </div>

              <div>
                <Link to="/pricing">
                  <Typography.MicroText className="hover:underline underline-offset-2 text-primary-800">
                    Upgrade Plan
                  </Typography.MicroText>
                </Link>
              </div>
              <div className="flex items-center space-x-2 pl-5 account">
                <div className="text-ss font-bold space-y-1">
                  <p className="font-bold">
                    <span>{user?.data?.firstname}</span> <span>{user?.data?.lastname}</span>
                    {/* <p className="text-ss font-[500] text-green">{toTitleCase(plan?.data?.plan)} Plan</p> */}
                  </p>
                  <div className="h-[19px] w-fit px-4 flex items-center justify-center bg-primary-100 rounded-[4px] cursor-pointer">
                    <Link to="/pricing" className="text-[11px] font-[600] text-primary-800 ">
                      {planData === "sme" ? "SME" : toTitleCase(planData)} plan
                    </Link>
                  </div>
                </div>
                <div className="w-[40px] h-[40px] bg-[#D7D7D7] rounded-full flex items-center justify-center overflow-hidden ">
                  {!user?.data?.profile_image.includes("https://") ? (
                    <img
                      src={`https://${user?.data?.profile_image}`}
                      alt=""
                      className="w-[100%] h-[100%] object-cover"
                    />
                  ) : (
                    <p className="text-black  text-ss font-[500] mx-auto">
                      {[
                        user?.data?.firstname.slice(0, 1).toUpperCase(),
                        user?.data?.lastname.slice(0, 1).toUpperCase(),
                      ]}
                    </p>
                  )}
                </div>
                <IoIosArrowDown onClick={() => toggleDropdown(3)} />
              </div>
            </div>
            {open === 3 && (
              <div
                className="shadow-secondary text-ss absolute top-[3.5em] right-0 w-[150px] rounded-[6px] overflow-hidden z-20"
                ref={refer}
              >
                <Link
                  to="/account"
                  onClick={() => toggleDropdown(3)}
                  className=" text-ss h-[40px] flex items-center px-4    bg-white z-[99] text-[500] cursor-pointer hover:bg-[#d3d3d3]"
                >
                  My account
                </Link>

                <p
                  onClick={logOut}
                  className="  h-[40px] flex  items-center px-4 bg-white z-[99] text-[500] cursor-pointer hover:bg-[#d3d3d3]"
                >
                  Log Out
                </p>
              </div>
            )}
          </div>
        </div>

        <div className={`p-[1em] max-h-[calc(100vh-80px)]  min-h-[calc(100vh-90px)] overflow-y-scroll`}>
          <Outlet />

          {open === 4 ? (
            <div className="bg-grey fixed top-0 bottom-0 left-0 h-[100vh] w-full flex justify-end items-end z-[999]">
              <div className="bg-white w-[270px]  m-8 rounded-lg p-4">
                <p className="font-bold">Hi there, I’m mooyi</p>
                <p className="text-s pt-[0.5em]">Complete these steps to get started!</p>

                <div className="h-[7px] my-[1em] w-[100%] bg-primary-50 rounded-lg overflow-hidden">
                  <div className={`bg-primary-800 w-[${onBoarding?.progress}%] h-[100%]`}></div>
                </div>

                <div className="space-y-5 pb-[2em]">
                  <div className="flex space-x-3">
                    <div
                      className={`border-[1px] rounded-md w-[20px] h-[20px] flex items-center justify-center ${
                        onBoarding?.completeProfile === true ? "bg-primary-800" : ""
                      }`}
                    >
                      {onBoarding?.completeProfile === true ? (
                        <IoMdCheckmark className="text-[24px] text-white" />
                      ) : null}
                    </div>
                    {/* <Checkbox /> */}
                    <div className="-mt-[4px]">
                      <p className="font-bold text-s">Complete your profile</p>
                      <p className="text-ss text-[#667085]">Add information about you and your business.</p>
                    </div>
                  </div>

                  <div className="flex space-x-3">
                    <div
                      className={`border-[1px] rounded-md min-w-[20px] h-[20px] flex items-center justify-center ${
                        onBoarding?.importContacts === true ? "bg-primary-800" : ""
                      }`}
                    >
                      {onBoarding?.importContacts === true ? (
                        <IoMdCheckmark className="text-[24px] text-white" />
                      ) : null}
                    </div>
                    <div className="-mt-[4px] ">
                      <p className="font-bold text-s">Add your contacts</p>
                      <p className="text-ss text-[#667085]">
                        Manually add your contacts or upload a CSV file within seconds.
                      </p>
                    </div>
                  </div>

                  <div className="flex space-x-3">
                    <div
                      className={`border-[1px] rounded-md min-w-[20px] h-[20px] flex items-center justify-center ${
                        onBoarding?.sendCampaign === true ? "bg-primary-800" : ""
                      }`}
                    >
                      {onBoarding?.sendCampaign === true ? <IoMdCheckmark className="text-[24px] text-white" /> : null}
                    </div>
                    <div className="-mt-[4px]">
                      <p className="font-bold text-s">Publish your survey</p>
                      <p className="text-ss text-[#667085]">Send out your first message or survey.</p>
                    </div>
                  </div>
                </div>

                <div onClick={checkRoute}>
                  <BlueButton text="Get started" className="w-full" />
                </div>

                <p className="text-center text-s pt-2 text-[#667085] cursor-pointer" onClick={() => toggleDropdown(4)}>
                  Close
                </p>
              </div>
            </div>
          ) : null}

          {onBoarding?.progress === 100 ? null : (
            <div
              className="absolute  bottom-[5em] right-[3em]  flex items-center justify-center cursor-pointer space-x-2"
              onClick={() => toggleDropdown(4)}
            >
              <div className="w-[50px] h-[50px] rounded-full bg-primary-100 flex items-center justify-center relative">
                <img src={OnBoarding} alt="OnBoarding" className="w-[70%]" />

                <p className="absolute top-0 -left-2 text-sm text-white bg-primary-800 rounded-full flex items-center justify-center font-semibold w-[20px] h-[20px]">
                  {" "}
                  {(100 - onBoarding?.progress) / 25}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <div
        className={`${openSidebar ? "w-[calc(100vw-250px)]" : "w-[calc(100vw-80px)]"}  bg-white absolute ${
          openSidebar ? "ml-[250px]" : "ml-[80px]"
        }  bottom-0 h-[56px] duration-300 flex items-center z-40 px-5`}
      >
        <div>
        </div>
      </div> */}
    </div>
  );
};

export default DashLayout;
