import { useQuery } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import PreLoader from "components/Loader/PreLoader";
import Modal from "components/Modal";
import { queryClient } from "config/gateway";
import Toast from "config/toast";
import { formatAmount } from "lib/formatAmount";
import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { approveWalletTopUp } from "services/walletService";
import { topUpWallet } from "services/walletService";
import { getCredit } from "services/workspaceService";
import { useStore } from "store";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";

const publicKey =
  process.env.REACT_APP_NODE_ENV !== "production" ? process.env.REACT_APP_FLW_KEY : process.env.REACT_APP_FLW_LIVE_KEY;

const TopUpModal = ({ handleModal }) => {
  const [amount, setAmount] = useState("");
  const [open, setOpen] = useState(false);
  const { data } = useQuery({ queryKey: ["getCredit"], queryFn: getCredit });
  const [enableTransac, setEnableTransac] = useState(false);
  const [paramData, setParamData] = useState({
    id: "",
    tx_ref: "",
  });
  const { user: storeUser } = useStore();

  const configs = {
    public_key: publicKey,
    tx_ref: Date.now(),
    amount: "",
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: storeUser?.email,
      phone_number: `+234${storeUser?.phone}`,
      name: storeUser?.firstname + " " + storeUser?.lastname,
    },
    customizations: {
      title: "MOOYI",
      description: "Top Up",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const initializePayment = useFlutterwave(configs);

  const { refetch: refetchWallet, isFetching: walletLoading } = useQuery({
    queryKey: ["verifyWalletTopUp", paramData],
    queryFn: () => approveWalletTopUp(paramData.id, paramData.tx_ref),

    enabled: false,
    onSuccess: () => {
      closePaymentModal();
      handleModal();
      Toast.success("Top-up successful.");
      queryClient.invalidateQueries({ queryKey: ["walletBalance"] });
    },
    onError: () => {
      Toast.error("Top-up failed, please try again");
    },
  });
  // configs.amount = amount.price;
  const { refetch } = useQuery({
    queryKey: ["topUpWallet", amount?.id],
    queryFn: () => topUpWallet(amount?.id),
    enabled: false,
    onSuccess: (data) => {
      configs.amount = amount.price;
      initializePayment({
        callback: (response) => {
          setParamData({
            ...paramData,
            tx_ref: data.tx_ref,
            id: response.transaction_id,
          });
          refetchWallet();

          // routeFn(); // this will close the modal programmatically
        },
        onClose: () => {
          // routeFn();
        },
      });
    },
    onError: () => {
      Toast.error("Top-up failed, please try again");
    },
  });

  const creditData = data?.map((el) => ({
    id: el._id,
    price: el?.amount,
    value: el?.credit,
  }));

  const handleSelect = (x) => {
    setAmount(x);
    setOpen(!open);
  };

  const handleDropdown = () => {
    setOpen(!open);
  };

  const handleSubmit = () => {
    setEnableTransac(true);
  };

  if (walletLoading) {
    return <PreLoader />;
  }

  return (
    <Modal title="Top up credit" onClose={() => handleModal(1)}>
      <div className="w-[400px] h-[200px] px-5">
        <p className="mt-[1em] font-[500] mb-2">Amount</p>
        <div className="relative">
          <div
            className="h-[50px] border-[#D0D5DD] w-[100%] rounded-[6px] border-[1px] flex items-center justify-between px-4 cursor-pointer"
            onClick={handleDropdown}
          >
            {!amount?.value ? (
              <p className="text-[#737373] tracking-[-0.01em] text-s">Select amount</p>
            ) : (
              <p className="text-[#737373] tracking-[-0.01em] text-s">
                {amount?.value} {"credits"}
              </p>
            )}

            <IoIosArrowDown />
          </div>

          {open ? (
            <div className="rounded-[6px] shadow-secondary absolute w-[100%] top-[3.5em] z-[9999] bg-white cursor-pointer">
              {creditData?.map((el, i) => {
                return (
                  <div
                    onClick={() => handleSelect(el)}
                    className={`h-[45px] ${
                      i !== creditData.length - 1 && "border-b-[1px]"
                    }  flex items-center px-4 justify-between`}
                  >
                    <p className="text-s text-[#404040]">{el?.value} credits</p>

                    <p className="text-s">NGN {formatAmount(el?.price)}.00</p>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>

        <div className="flex justify-between items-center my-[2em] mt-[4em]">
          <div></div>

          <div className="flex space-x-4">
            <BlueButton text="Top Up" onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TopUpModal;
