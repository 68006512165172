export const surveyTemplates = [
  {
    id: 0,
    surveyName: "",
    surveyCategory: "",
    surveyDescription: "",
    coverImage: "",
    reward: 0,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [],
  },
  {
    id: 100,
    surveyName: "",
    surveyCategory: "",
    surveyDescription: "",
    coverImage: "",
    reward: 0,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [],
  },
  {
    id: 1,
    surveyName: "Concept Test",
    surveySubTitle: "Tailor questions to fit your concept and gather valuable insights from participants.",
    surveyCategory: "Concept Test",
    surveyDescription:
      "Tailor the specific questions and options to the concept you are testing and the information you want to gather from the survey participants. Including a mix of rating scales, open-ended questions, and other questions that suit your needs is good practice.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Concept%20Test.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "How well do you understand the concept? (Scale 1-5)",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Not at all 😞", "A little bit 😕", "So-so 😐", "Quite well 🙂", "Very well 😄"],
      },
      {
        question: "Is the concept relevant to your needs or interests? (Scale 1-5)",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Not at all 😞", "A little bit 😕", "So-so 😐", "Quite relevant 🙂", "Very relevant 😄"],
      },
      {
        question: "What are the main benefits of the concept? ",
        description: "(Share your thoughts)",
        questionType: "text_response",
        optional: false,
        options: [],
      },
      {
        question: "Rank these features in order of importance: ",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Cost-effectiveness", "Ease of use", "Performance", "Flexibility", "Customisation options"],
      },
      {
        question: "How likely are you to use or adopt this concept?",
        description: "Choose one:",
        questionType: "single_choice",
        optional: false,
        options: ["Very likely 😄", "Likely 🙂", "Neutral 😐", "Unlikely 😕", "Very unlikely 😞"],
      },
      {
        question: "Do you have any suggestions to improve the concept? ",
        description: "Share your ideas",
        questionType: "text_response",
        optional: false,
        options: [],
      },
      {
        question: "How does this concept compare to its competitors in terms of uniqueness and advantages? ",
        description: "Share your thoughts",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 2,
    surveyName: "Ad Test",
    surveyCategory: "Ad Test",
    surveySubTitle: "Select creative concepts with the most potential for impactful results.",
    surveyDescription:
      "Remember that you can customise these questions based on the specific ad you’re testing or what you’re trying to discover. Simplicity helps ensure participants understand and provide valuable feedback.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Survey%20template%20cover%207.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgTwo",
    questions: [
      {
        question: "How did you feel about the ad?",
        description: "Choose One",
        questionType: "single_choice",
        optional: false,
        options: ["Loved it! 😄", "Liked it 🙂", "It was okay 😐", "Didn't like it 😕", "Hated it 😞"],
      },
      {
        question: "Was the main message clear? ",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: [
          "Yes, very clear 👍",
          "Yes, somewhat clear 🙂",
          "Not sure 😐",
          "Not very clear 😕",
          "No, not clear at all 😞",
        ],
      },
      {
        question: "Do you remember which brand the ad was for?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Yes 👍", "No 😕"],
      },
      {
        question: "Did the ad catch your attention? ",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Very much! 😄", "Yes 🙂", "Neutral 😐", "Not really 😕", "Not at all 😞"],
      },
      {
        question: "What would you do after seeing the ad?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: [
          "Visit the website 👍",
          "Make a purchase 🙂",
          "Share with friends/family 😊",
          "Sign up for more information 😊",
          "Not likely to take any action 😕",
        ],
      },
      {
        question: "What would you change or improve about the ad?",
        description: "Share your ideas!",
        questionType: "text_response",
        optional: false,
        options: [],
      },
      {
        question: "How likely are you to share this ad with others?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very likely 😄", "Likely 🙂", "Neutral 😐", "Unlikely 😕", "Very unlikely 😞"],
      },
    ],
  },
  {
    id: 3,
    surveyName: "Product Test",
    surveyCategory: "Product Test",
    surveySubTitle: "Pilot new ideas before launch. Build with incredible market fit from day one.",
    surveyDescription:
      "The questions address different areas of product testing, such as overall satisfaction, usability, feedback on features and faults, purchase intent, and recommendations. You may customise and elaborate on these questions to fit your product and testing goals.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/New%20Product%20Development.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "How much do you like the product?",
        description: "Choose One",
        questionType: "single_choice",
        optional: false,
        options: ["Loved it! 😄", "Liked it 🙂", "It was okay 😐", "Didn't like it 😕", "Hated it 😞"],
      },
      {
        question: "Was it easy to use the product?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Super easy 👍", "Easy 🙂", "So-so 😐", "Not very easy 😕", "Not easy at all 😞"],
      },
      {
        question: "What did you like the most about the product?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },
      {
        question: "Do you have any ideas to make the product even better? ",
        description: "Share your ideas!",
        questionType: "text_response",
        optional: false,
        options: [],
      },
      {
        question: "Will you buy this product again?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, definitely 😄", "Yes, maybe 🙂", "Not sure 😐", "Probably not 😕", "No, never 😞"],
      },
      {
        question: "Would you recommend this product to others?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Yes, definitely 😄",
          "Yes, but with some reservations 🙂",
          "Not sure 😐",
          "No, not really 😕",
          "No, not at all 😞",
        ],
      },
      {
        question: "How does this product compare to others you've tried before?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 4,
    surveyName: "Feedback from Local Communities",
    surveyCategory: "Feedback from local Communities",
    surveySubTitle: "Gather feedback from residents on their community involvement.",
    surveyDescription:
      "These questions aim to gather feedback from residents to understand their level of community involvement, awareness of initiatives, preferred communication channels, concerns, service satisfaction, and suggestions for improving community engagement. Customise these questions as needed to suit the specific context and goals of the survey.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Feedback%20from%20Local%20Communities.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgTwo",
    questions: [
      {
        question: "How often do you participate in community events or activities?",
        description: "Choose One",
        questionType: "single_choice",
        optional: false,
        options: ["Regularly 😄", "Occasionally 😊", "Rarely 😐", "Never 😞"],
      },
      {
        question: "How aware are you of what's happening in our community?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Very aware 😄", "Somewhat aware 🙂", "Kind of aware 😐", "Not aware at all 😞"],
      },
      {
        question: "How do you like to get updates about community events? ",
        description: "Select all that apply.",
        questionType: "multi_choice",
        optional: false,
        options: [
          "Social media 😊",
          "Local newspapers 😊",
          "Community website 😊",
          "Flyers/posters 😊",
          "Email newsletters 😊",
        ],
      },
      {
        question: "What are the most important concerns or issues in our community?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "How happy are you with community services and facilities?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Very dissatisfied 😞", "Dissatisfied 😞", "Neutral 😐", "Satisfied 🙂", "Very satisfied 😄"],
      },
      {
        question: "Are there enough chances for residents to share opinions and give feedback on community matters?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Yes, definitely 😄",
          "Yes, to some extent 🙂",
          "Not sure/Neutral 😐",
          "No, not really 😕",
          "No, not at all 😞",
        ],
      },
      {
        question: "How can we make community engagement better?",
        description: "Share your ideas!",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 5,
    surveyName: "Competitor Analysis",
    surveyCategory: "Competitor Analysis",
    surveySubTitle: "Benchmark your brand against other players in the market.",
    surveyDescription:
      "These questions aim to gather feedback and insights on competitor awareness, usage, perceived quality and pricing, differentiation factors, and preferences. Customise the questions to match the specific competitors and market dynamics relevant to your research.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Competitor%20Analysis.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "Are you aware of our main competitors in the market?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, very aware 😃", "Yes, somewhat aware 😐", "No, not aware 😕"],
      },
      {
        question: "Have you used products or services from our competitors?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, often 👍", "Yes, sometimes 😊", "No, never 👎"],
      },
      {
        question: "How do you like to get updates about community events? ",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Worse 😞", "Slightly worse 😕", "About the same 😐", "Slightly better 🙂", "Better! 😄"],
      },
      {
        question: "How do our prices compare to our main competitors?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Cheaper! 😄", "About the same 😐", "More expensive 😕"],
      },
      {
        question: "What makes our products/services stand out compared to our main competitors?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "What are our main competitors' weaknesses or areas for improvement?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "Which brand would you prefer to purchase from if you had to choose?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Our brand 👍", "A competitor's brand 😕", "It depends on the product/service 🤔"],
      },
    ],
  },
  {
    id: 6,
    surveyName: "Customer Feedback",
    surveyCategory: "Customer Feedback",
    surveySubTitle: "Get quality customer feedback you can quickly act on.",
    surveyDescription:
      "These questions aim to gather feedback on overall satisfaction, customer service, specific experiences, likelihood to recommend and continue as a customer, problem resolution, and suggestions for improvement. Customise the questions to align with your specific business and customer feedback goals.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Customer%20Feedback.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgTwo",
    questions: [
      {
        question: "How happy are you with your recent purchase?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Very happy 😄", "Happy 🙂", "Okay 😐", "Not so happy 😕", "Not happy at all 😞"],
      },
      {
        question: "How was your recent customer service experience with us?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent! 🌟", "Good 😊", "Okay 😐", "Not so good 😕", "Not good at all 😞"],
      },

      {
        question: "Do you have any ideas to make our products/services better?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "Would you recommend us to your friends?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Yes! 👍", "Maybe 🤔", "Not sure 😐", "No, not really 👎"],
      },
      {
        question: "If you faced any issues, were they solved well?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, they were fixed! 👍", "Kind of 🤔", "Not really 😕", "They were not fixed at all 😞"],
      },

      {
        question: "Will you continue using our products/services? ",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, definitely! 👍", "Maybe 🤔", "Not sure 😐", "No, not anymore 👎"],
      },

      {
        question: "Do you have any other suggestions or ideas to improve your experience with us?",
        description: "Feel free to share!",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },

  {
    id: 7,
    surveyName: "Event Feedback",
    surveyCategory: "Event Feedback",
    surveySubTitle: "Send out a post-event survey to get your guest feedback.",
    surveyDescription:
      "These questions aim to gather feedback on various aspects of the event, including overall experience, organisation, content, venue, staff, timing, and the likelihood of attending future events. Customise the questions to fit your specific event and feedback objectives.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Event%20Feedback.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgTwo",
    questions: [
      {
        question: "How much did you enjoy the event?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Loved it! 😄", "Liked it 🙂", "It was okay 😐", "Didn't like it 😕", "Hated it 😞"],
      },
      {
        question: "Was the event well-organized?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, very well! 👍", "Yes, somewhat 🤔", "Not really 😕", "No, not at all 👎"],
      },

      {
        question: "What was your favourite thing about the event?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "How did you feel about the event venue and facilities?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Awesome! 🌟", "Good 😊", "Okay 😐", "Not great 😕", "Terrible 😞"],
      },
      {
        question: "How friendly and helpful were the event staff and volunteers?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: [
          "Super friendly and helpful! 😄",
          "Friendly and helpful 😊",
          "Neutral 😐",
          "Not very friendly or helpful 😕",
          "Very unfriendly and unhelpful 😞",
        ],
      },

      {
        question: "Was the event too short, too long, or just right in time?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Just right 👍", "Too short 🏃‍♂️", "Too long 🐢"],
      },

      {
        question: "Will you come to a similar event in the future?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, definitely! 👍", "Maybe 🤔", "Not sure 😐", "No, not interested 👎"],
      },
    ],
  },

  {
    id: 8,
    surveyName: "Pricing Research",
    surveyCategory: "Pricing Research",
    surveySubTitle: "Gather data on customer behaviour, market trends, and competitor pricing.",
    surveyDescription:
      "Keep the language simple and easy to understand to ensure clear and accurate participant feedback. Customise the questions as needed to fit your research objectives and the preferences of your target audience.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Pricing%20Research.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "How much does the price matter when you decide to buy something?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["A lot! 😄", "Somewhat 🤔", "Not sure 😐", "Not much 😕", "Not at all 😞"],
      },
      {
        question: "Are our products/services a good deal for your price?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Definitely! 👍", "Yes, they're okay 🙂", "I'm not sure 😐", "Not really 😕", "No, not at all 😞"],
      },

      {
        question: "Compared to other similar things you've seen, are our prices:",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Lower 👍", "About the same 🙂", "Higher 👎"],
      },

      {
        question: "Does the price match what we offer? ",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Perfect match! 👍",
          "Kind of matches 🙂",
          "Not sure 😐",
          "It doesn't really match 😕",
          "It's way off 👎",
        ],
      },

      {
        question: "How easy is it for you to afford our products/services? ",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Very easy 😄", "Okay 😊", "Not sure 😐", "It's a bit hard 😕", "It's very tough 😞"],
      },

      {
        question: "How does our pricing affect what you think about our brand? ",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "What price changes would make you more likely to buy from us?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },

  {
    id: 9,
    surveyName: "Affiliate Program Feedback",
    surveyCategory: "Affiliate Program Feedback",
    surveySubTitle: "Gather data on satisfaction, ease of use, & effectiveness in the affiliate program.",
    surveyDescription:
      "These questions aim to gather feedback on overall satisfaction, ease of use, the effectiveness of marketing materials, support experience, and areas for improvement in the affiliate program. Customise the questions to align with your affiliate program and feedback goals.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Affiliate%20Program%20Feedback.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "How much do you like our affiliate program? ",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Love it! 😄", "Like it 🙂", "It's okay 😐", "Not really 😕", "Don't like it 😞"],
      },
      {
        question: "Was it easy to sign up and get started as an affiliate?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Super easy 👍", "Easy 🙂", "Not sure 😐", "Kind of hard 😕", "Really hard 😞"],
      },

      {
        question: "How useful are our affiliate marketing materials and resources?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Very useful 👍",
          "Quite useful 🙂",
          "Somewhat useful 😐",
          "Not very useful 😕",
          "Not useful at all 😞",
        ],
      },

      {
        question: "Did you need any help with our affiliate program?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, often 👎", "Yes, sometimes 😕", "No, never 👍"],
      },

      {
        question: "How helpful is our support team?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Super helpful 👍", "Helpful 🙂", "Neutral 😐", "Not very helpful 😕", "Not helpful at all 😞"],
      },

      {
        question: "What would you like us to improve in our affiliate program?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "Would you recommend our affiliate program to others?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, definitely 👍", "Maybe 🤔", "No, not really 👎"],
      },
    ],
  },

  {
    id: 10,
    surveyName: "Consumer Profiling",
    surveyCategory: "Consumer Profiling",
    surveySubTitle: "Get quality customer feedback you can quickly act on.",
    surveyDescription: "Which of these products have you purchased in the past 3 months?",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Consumer%20Profiling.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "How often do you use  >INSERT YOUR PRODUCT CATEGORY< ",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "A few times a day",
          "Once a day",
          "A few times a week",
          "Once a week",
          "A few times a month",
          "Once a month",
          "Less often",
        ],
      },
      {
        question:
          "Please rank the following on how important or unimportant they are when deciding which  >INSERT YOUR PRODUCT CATEGORY< to buy. 1 = most important, 9 = least important",
        description: "Choose one option",
        questionType: "likert",
        optional: false,
        options: {
          max: {
            label: "Most important",
            value: 9,
          },
          min: {
            label: "Least important",
            value: 1,
          },
        },
      },

      {
        question: "How do you prefer to shop for >INSERT YOUR PRODUCT CATEGORY<?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "I prefer to buy things in person",
          "I prefer to buy things online",
          "I have no preference for online or offline",
          "I prefer to buy things over the phone ",
        ],
      },

      {
        question: "Did you need any help with our affiliate program?",
        description: "Choose one option",
        questionType: "multi_choice",
        optional: false,
        options: [
          "Friends/family",
          "Money",
          "Physical health",
          "Spiritual life",
          "Mental health",
          "Having new experiences",
          "Status/promotion",
          "Having fun",
          "Feeling safe",
        ],
      },
    ],
  },

  /*
{
  question: "On average, how many days per week do you drink beer?",
  description: "Choose one option",
  questionType: "single_choice",
  optional: false,
  options: [
    "None",
    "1 day",
    "2 days",
    "3 days",
    "4 days or more"
  ],
},
{
  question: "What time of day do you most often consume beer?",
  description: "Choose one option",
  questionType: "single_choice",
  optional: false,
  options: [
    "Morning",
    "Afternoon",
    "Evening",
    "Night",
    "No specific time"
  ],
},
{
  question: "What factors influence your choice of beer selection? (Select all that apply)",
  description: "Choose all that apply",
  questionType: "multi_choice",
  optional: false,
  options: [
    "Flavor profile",
    "Brand reputation",
    "Price",
    "Alcohol content",
    "Availability",
    "Recommendations from others"
  ],
},
{
  question: "How often do you try new or different types of beer?",
  description: "Choose one option",
  questionType: "single_choice",
  optional: false,
  options: [
    "Very often",
    "Often",
    "Occasionally",
    "Rarely",
    "Never"
  ],
}

*/

  {
    id: 11,
    surveyName: "Jobs to be done Survey",
    surveyCategory: "Jobs to be done",
    surveySubTitle: "Gather data on user activities within a specified timeframe.",
    surveyDescription: "Looking at the list below, please select your activities in the past 12 months.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Jobs%20to%20be%20done%20Survey.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "How often have you carried out [INSERT ACTIVITY] in the last 12 months?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Everyday",
          "A few times a week",
          "Every week",
          "A few times in a month",
          "Once a month",
          "A few times a year",
          "Once every six months",
          "Once a year",
        ],
      },
      {
        question: "How much time would you typically spend on this [INSERT ACTIVITY]?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Less than 30 mins",
          "30 - 59 mins",
          "1 hr - 2 hr 59 mins",
          "3 hrs - 4 hr 59 mins",
          "5 hrs - 11hrs 59 mins",
          "12 hrs +",
        ],
      },
      {
        question: "How important or unimportant is carrying out this [INSERT ACTIVITY]?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Very important",
          "Somewhat important",
          "Neither important nor unimportant",
          "Somewhat unimportant",
          "Very unimportant",
        ],
      },

      {
        question: "How satisfied or dissatisfied do you feel when carrying out this [INSERT ACTIVITY]?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Very satisfied",
          "Somewhat satisfied",
          "Neither satisfied nor dissatisfied",
          "Somewhat dissatisfied",
          "Very dissatisfied",
        ],
      },

      {
        question: "What is the best thing about carrying out [INSERT ACTIVITY]?",
        description: "Choose one option",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "How does carrying out [INSERT ACTIVITY] make you feel?",
        description: "Choose one option",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "What problems or challenges do you encounter while carrying out [INSERT ACTIVITY]?",
        description: "Choose one option",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question:
          "Please rank these factors in order of importance when determining whether to buy [PRODUCT / SERVICE].",
        description: "Choose one option",
        questionType: "likert",
        optional: false,
        options: {
          max: {
            label: "Most important",
            value: 9,
          },
          min: {
            label: "Least important",
            value: 1,
          },
        },
      },
    ],
  },

  // {
  //   id: 12,
  //   surveyName: "Beer Survey",
  //   surveyCategory: "Consumer Profile",
  //   surveyDescription: "This question is tailored folks who take beer",
  //   surveySubTitle: "Gather data on customers who take beer.",
  //   coverImage:
  //     "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Consumer%20Profiling.png",
  //   reward: 100,
  //   campaignType: "",
  //   bg: "bg-bgOne",
  //   questions: [
  //     {
  //       question: "On average, how many days per week do you drink beer?",
  //       description: "Choose one option",
  //       questionType: "single_choice",
  //       optional: false,
  //       options: ["None", "1 day", "2 days", "3 days", "4 days or more"],
  //     },
  //     {
  //       question: "What time of day do you most often consume beer?",
  //       description: "Choose one option",
  //       questionType: "single_choice",
  //       optional: false,
  //       options: ["Morning", "Afternoon", "Evening", "Night", "No specific time"],
  //     },
  //     {
  //       question: "What factors influence your choice of beer selection? (Select all that apply)",
  //       description: "Choose all that apply",
  //       questionType: "multi_choice",
  //       optional: false,
  //       options: [
  //         "Flavor profile",
  //         "Brand reputation",
  //         "Price",
  //         "Alcohol content",
  //         "Availability",
  //         "Recommendations from others",
  //       ],
  //     },
  //     {
  //       question: "How often do you try new or different types of beer?",
  //       description: "Choose one option",
  //       questionType: "single_choice",
  //       optional: false,
  //       options: ["Very often", "Often", "Occasionally", "Rarely", "Never"],
  //     },
  //   ],
  // },
];

export const fieldSurveyTemplate = [
  {
    id: 0,
    surveyName: "",
    surveyCategory: "",
    surveyDescription: "",
    coverImage: "",
    reward: 0,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [],
  },
  {
    id: 1,
    surveyName: "Market Research",
    surveySubTitle: "To gather customer preferences, buying habits, and insights on product satisfaction.",
    surveyCategory: "Market Research",
    surveyDescription: "To gather customer preferences, buying habits, and insights on product satisfaction.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Concept%20Test.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "How frequently do you purchase our product?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Daily", "Weekly", "Monthly", "Rarely", "Never"],
      },
      {
        question: "What factors most influence your purchasing decision?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Price", "Quality", "Brand", "Availability", "Other"],
      },
      {
        question: "On a scale of 1-10, how satisfied are you with our product’s quality?",
        description: "(Share your thoughts)",
        questionType: "text_response",
        optional: false,
        options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
      {
        question: "How likely are you to recommend our product to others?",
        description: "Choose one:",
        questionType: "single_choice",
        optional: false,
        options: ["Very likely 😄", "Likely 🙂", "Neutral 😐", "Unlikely 😕", "Very unlikely 😞"],
      },
      {
        question: "What feature would you most like to see improved in our product?",
        description: "Share your ideas",
        questionType: "audio/video",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 2,
    surveyName: "Employee Performance Evaluation",
    surveySubTitle: "To assess employee performance and identify areas for improvement.",
    surveyCategory: "Performance Evaluation",
    surveyDescription:
      "This survey aims to evaluate employee performance, job satisfaction, and communication, providing insights for organizational improvement.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Survey%20template%20cover%207.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgThree",
    questions: [
      {
        question: "How would you rate your job satisfaction on a scale of 1-10?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
      {
        question: "Have you met your performance goals for the last quarter?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Yes", "No"],
      },
      {
        question: "How would you rate your communication with your supervisor?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Fair", "Poor"],
      },
      {
        question: "What resources or tools do you need to improve your performance?",
        description: "Share your thoughts",
        questionType: "text_response",
        optional: false,
        options: [],
      },
      {
        question: "Do you feel your work is valued by the organisation?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Strongly Agree", "Agree", "Neutral", "Disagree", "Strongly Disagree"],
      },
    ],
  },
  {
    id: 3,
    surveyName: "Customer Satisfaction",
    surveySubTitle: "To measure customer satisfaction with services or products.",
    surveyCategory: "Customer Satisfaction",
    surveyDescription:
      "This survey aims to gather feedback on customer satisfaction with our services or products, to identify areas of improvement.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/New%20Product%20Development.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgFour",
    questions: [
      {
        question: "How satisfied are you with the overall quality of our service?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Satisfied", "Satisfied", "Neutral", "Unsatisfied", "Very Unsatisfied"],
      },
      {
        question: "Was the service delivered within the expected time frame?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Yes", "No"],
      },
      {
        question: "How would you rate the professionalism of our staff?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Fair", "Poor"],
      },
      {
        question: "What improvements can we make to serve you better?",
        description: "Share your thoughts",
        questionType: "text_response",
        optional: false,
        options: [],
      },
      {
        question: "Would you use our service again in the future?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Definitely", "Probably", "Unsure", "Probably Not", "Definitely Not"],
      },
    ],
  },
  {
    id: 4,
    surveyName: "User Experience (UX) Research",
    surveySubTitle: "To understand how users interact with a product or service.",
    surveyCategory: "UX Research",
    surveyDescription:
      "This survey gathers insights on how users interact with the product, including ease of navigation, design satisfaction, and areas for improvement.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Feedback%20from%20Local%20Communities.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgFive",
    questions: [
      {
        question: "How easy is it to navigate our website/app?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Easy", "Easy", "Neutral", "Difficult", "Very Difficult"],
      },
      {
        question: "How satisfied are you with the design and layout of our platform?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Satisfied", "Satisfied", "Neutral", "Unsatisfied", "Very Unsatisfied"],
      },
      {
        question: "On a scale of 1-10, how intuitive do you find our product’s interface?",
        description: "Rate on a scale from 1 to 10.",
        questionType: "single_choice",
        optional: false,
        options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
      {
        question: "Have you encountered any bugs or issues while using our product?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Yes", "No"],
      },
      {
        question: "What improvements would make your user experience better?",
        description: "Share your ideas",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 5,
    surveyName: "Product Feedback",
    surveySubTitle: "To gather feedback on a newly launched or existing product.",
    surveyCategory: "Product Feedback",
    surveyDescription:
      "This survey aims to collect feedback on the product’s usage, functionality, and value for money, helping us understand customer satisfaction and areas for improvement.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Competitor%20Analysis.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgSix",
    questions: [
      {
        question: "How often do you use the product?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Daily", "Weekly", "Monthly", "Rarely", "Never"],
      },
      {
        question: "How would you rate the product's overall functionality?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Average", "Below Average", "Poor"],
      },
      {
        question: "On a scale of 1-10, how satisfied are you with the value for money?",
        description: "Rate on a scale from 1 to 10.",
        questionType: "single_choice",
        optional: false,
        options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
      {
        question: "Are there any features that you feel are missing from the product?",
        description: "Yes (If yes, please specify)",
        questionType: "single_choice",
        optional: false,
        options: ["Yes", "No"],
      },
      {
        question: "Would you consider purchasing this product again?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Definitely", "Probably", "Unsure", "Probably Not", "Definitely Not"],
      },
    ],
  },
  {
    id: 6,
    surveyName: "Healthcare Satisfaction Survey",
    surveySubTitle: "To assess patient satisfaction with healthcare services.",
    surveyCategory: "Healthcare",
    surveyDescription:
      "This survey aims to gather feedback from patients regarding their satisfaction with healthcare services, including quality of care, wait times, and overall experience.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Customer%20Feedback.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgSeven",
    questions: [
      {
        question: "How would you rate the quality of care you received during your last visit?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Fair", "Poor"],
      },
      {
        question: "How satisfied were you with the wait time before seeing the healthcare provider?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Satisfied", "Satisfied", "Neutral", "Unsatisfied", "Very Unsatisfied"],
      },
      {
        question: "How would you rate the cleanliness and comfort of the healthcare facility?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Fair", "Poor"],
      },
      {
        question: "On a scale of 1-10, how likely are you to recommend our healthcare services to others?",
        description: "Rate on a scale from 1 to 10.",
        questionType: "single_choice",
        optional: false,
        options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
      {
        question: "What suggestions do you have for improving our healthcare services?",
        description: "Share your thoughts",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 7,
    surveyName: "Employee Engagement",
    surveySubTitle: "To gauge employee engagement and job satisfaction.",
    surveyCategory: "Employee Engagement",
    surveyDescription: "To gauge employee engagement and job satisfaction.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Event%20Feedback.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "How engaged do you feel in your current role?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Engaged", "Engaged", "Neutral", "Disengaged", "Very Disengaged"],
      },
      {
        question: "Do you feel that your work contributes to the overall success of the company?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Yes", "No"],
      },
      {
        question: "On a scale of 1-10, how satisfied are you with your current workload?",
        description: "",
        questionType: "scale",
        optional: false,
        options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
      {
        question: "How would you rate communication and transparency within the organisation?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Fair", "Poor"],
      },
      {
        question: "What improvements could the company make to boost employee engagement?",
        description: "",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 8,
    surveyName: "Focus Group Interview",
    surveySubTitle: "To collect insights from multiple participants on a product, service, or brand.",
    surveyCategory: "Focus Group",
    surveyDescription: "To collect insights from multiple participants on a product, service, or brand.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Pricing%20Research.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "What are your initial thoughts on the product/service we’ve discussed?",
        description: "",
        questionType: "text_response",
        optional: false,
        options: [],
      },
      {
        question: "What do you like most about this product/service?",
        description: "Please specify",
        questionType: "text_response",
        optional: false,
        options: [],
      },
      {
        question: "What areas of the product/service do you think need improvement?",
        description: "Please specify",
        questionType: "text_response",
        optional: false,
        options: [],
      },
      {
        question: "How does this product/service compare to others in the market?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Better", "Similar", "Worse"],
      },
      {
        question: "How likely are you to continue using or recommending this product/service?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Likely", "Likely", "Neutral", "Unlikely", "Very Unlikely"],
      },
    ],
  },
  {
    id: 9,
    surveyName: "Brand Perception",
    surveySubTitle: "Understand how customers perceive your brand in comparison to competitors.",
    surveyCategory: "Brand Perception",
    surveyDescription: "To understand how customers perceive your brand in comparison to competitors.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Affiliate%20Program%20Feedback.png",
    reward: 100, // Adjust as needed
    campaignType: "",
    bg: "bg-bgOne", // Adjust as needed
    questions: [
      {
        question: "How familiar are you with our brand?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Familiar", "Somewhat Familiar", "Not Familiar"],
      },
      {
        question: "How would you rate the quality of our brand’s products or services?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Fair", "Poor"],
      },
      {
        question: "How does our brand compare to competitors in terms of value for money?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Much Better", "Better", "Same", "Worse", "Much Worse"],
      },
      {
        question: "On a scale of 1-10, how would you rate your overall trust in our brand?",
        description: "",
        questionType: "text_response",
        optional: false,
        options: [], // No predefined options for a scale
      },
      {
        question: "What changes would you recommend to improve our brand perception?",
        description: "Share your thoughts",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 10,
    surveyName: "Public Opinion",
    surveySubTitle: "Objective: To gather general opinions on a topic, policy, or societal issue.",
    surveyCategory: "Public Opinion",
    surveyDescription:
      "This survey is designed to gather public opinions on a specific topic, policy, or issue, and to assess levels of concern, knowledge, and support.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Consumer%20Profiling.png",
    reward: 100, // Placeholder reward
    campaignType: "",
    bg: "bg-bgTwo", // Placeholder background class
    questions: [
      {
        question: "How concerned are you about [specific issue]?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Concerned", "Concerned", "Neutral", "Not Concerned", "Not At All Concerned"],
      },
      {
        question: "How informed do you feel about [specific policy/topic]?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Informed", "Informed", "Neutral", "Uninformed", "Very Uninformed"],
      },
      {
        question: "What is your stance on [policy or topic]?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Strongly Support", "Support", "Neutral", "Oppose", "Strongly Oppose"],
      },
      {
        question: "Do you believe the government or organisation is handling this issue well?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Yes", "No", "Unsure"],
      },
      {
        question: "What improvements or changes would you suggest regarding this issue?",
        description: "Share your thoughts",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 11, // Adjust ID as needed
    surveyName: "Educational Feedback",
    surveySubTitle:
      "Objective: To gather feedback from students about the quality of education and their overall learning experience.",
    surveyCategory: "Education",
    surveyDescription:
      "This survey aims to understand students' feedback on the quality of education, accessibility of instructors, and learning resources.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Jobs%20to%20be%20done%20Survey.png",
    reward: 100, // Placeholder reward
    campaignType: "",
    bg: "bg-bgTwo", // Placeholder background class
    questions: [
      {
        question: "How would you rate the overall quality of education you’re receiving?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Fair", "Poor"],
      },
      {
        question: "How accessible are your instructors or professors when you need help?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Accessible", "Accessible", "Neutral", "Inaccessible", "Very Inaccessible"],
      },
      {
        question: "On a scale of 1-10, how effective are the teaching methods used in your classes?",
        description: "(Rate the effectiveness)",
        questionType: "text_response", // Assuming the scale input can be handled as text response
        optional: false,
        options: [],
      },
      {
        question: "How satisfied are you with the learning resources provided (e.g., textbooks, online materials)?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Satisfied", "Satisfied", "Neutral", "Unsatisfied", "Very Unsatisfied"],
      },
      {
        question: "What improvements would you suggest to enhance the learning experience?",
        description: "Share your thoughts",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 12, // Adjust ID as needed
    surveyName: "Community Needs Assessment",
    surveySubTitle:
      "Objective: To assess the needs and concerns of a local community to guide development or social services.",
    surveyCategory: "Community Assessment",
    surveyDescription:
      "This survey aims to gather insights into the pressing issues and needs within the community to inform future development and social services.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Consumer%20Profiling.png",
    reward: 100, // Placeholder reward
    campaignType: "",
    bg: "bg-bgThree", // Placeholder background class
    questions: [
      {
        question: "What are the most pressing issues facing your community?",
        description: "",
        questionType: "multiple_choice",
        optional: false,
        options: ["Unemployment", "Education", "Healthcare", "Infrastructure", "Crime", "Other (please specify)"],
      },
      {
        question: "How would you rate the current quality of public services in your community?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Fair", "Poor"],
      },
      {
        question: "How safe do you feel in your community?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Safe", "Safe", "Neutral", "Unsafe", "Very Unsafe"],
      },
      {
        question: "On a scale of 1-10, how accessible are local healthcare facilities?",
        description: "",
        questionType: "scale",
        optional: false,
        options: [],
      },
      {
        question: "What services or improvements would you like to see implemented in your community?",
        description: "Share your suggestions",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 13, // Adjust ID as needed
    surveyName: "Environmental Awareness and Behaviour",
    surveySubTitle:
      "Objective: To understand public awareness, attitudes, and behaviours towards environmental conservation and sustainability.",
    surveyCategory: "Environmental Awareness",
    surveyDescription:
      "This survey seeks to gather insights on how the public perceives environmental issues and their engagement in sustainable practices.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Concept%20Test.png",
    reward: 100, // Placeholder reward
    campaignType: "",
    bg: "bg-bgFour", // Placeholder background class
    questions: [
      {
        question: "How concerned are you about environmental issues such as climate change and pollution?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Concerned", "Concerned", "Neutral", "Not Concerned", "Not At All Concerned"],
      },
      {
        question: "How often do you engage in environmentally friendly practices (e.g., recycling, conserving water)?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Always", "Often", "Sometimes", "Rarely", "Never"],
      },
      {
        question:
          "On a scale of 1-10, how aware are you of government or organisational initiatives to promote sustainability?",
        description: "",
        questionType: "scale",
        optional: false,
        options: [],
      },
      {
        question: "Have you made any lifestyle changes in the past year to reduce your environmental impact?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Yes", "No"],
      },
      {
        question: "What steps can individuals and communities take to promote environmental sustainability?",
        description: "Share your suggestions",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 14,
    surveyName: "Tourism Experience",
    surveySubTitle:
      "Objective: To gather insights from travellers about their experience with tourism services and destinations.",
    surveyCategory: "Tourism",
    surveyDescription:
      "This survey aims to collect feedback from travellers regarding their experiences with tourism services and their satisfaction levels.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Survey%20template%20cover%207.png",
    reward: 100, // Placeholder reward
    campaignType: "",
    bg: "bg-bgFive", // Placeholder background class
    questions: [
      {
        question: "How would you rate your overall satisfaction with your recent travel experience?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Fair", "Poor"],
      },
      {
        question: "How would you describe the quality of customer service you received during your trip?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Good", "Good", "Neutral", "Poor", "Very Poor"],
      },
      {
        question: "On a scale of 1-10, how likely are you to recommend the destination you visited to others?",
        description: "",
        questionType: "scale",
        optional: false,
        options: [],
      },
      {
        question: "How satisfied were you with the accommodation and amenities during your trip?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Satisfied", "Satisfied", "Neutral", "Unsatisfied", "Very Unsatisfied"],
      },
      {
        question: "What could have made your tourism experience more enjoyable or convenient?",
        description: "Share your suggestions",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 15, // Adjust ID as needed
    surveyName: "Telecom Survey",
    surveySubTitle:
      "Objective: To assess customer satisfaction, service quality, and content preferences in the telecom and entertainment industries.",
    surveyCategory: "Telecom",
    surveyDescription:
      "This survey aims to evaluate customer satisfaction regarding telecom services and identify areas for improvement.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/New%20Product%20Development.png",
    reward: 100, // Placeholder reward
    campaignType: "",
    bg: "bg-bgSix", // Placeholder background class
    questions: [
      {
        question:
          "How satisfied are you with the quality of your telecom service provider’s network (e.g., call quality, internet speed)?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Satisfied", "Satisfied", "Neutral", "Unsatisfied", "Very Unsatisfied"],
      },
      {
        question: "How often do you experience service interruptions (e.g., dropped calls, slow internet)?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Never", "Rarely", "Occasionally", "Frequently", "Always"],
      },
      {
        question: "How would you rate the customer service provided by your telecom service provider?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Average", "Poor", "Very Poor"],
      },
      {
        question: "On a scale of 1-10, how likely are you to recommend your telecom service provider to others?",
        description: "",
        questionType: "scale",
        optional: false,
        options: [],
      },
      {
        question: "What improvements would you suggest for your telecom or media service provider?",
        description: "Share your suggestions",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 16, // Adjust ID as needed
    surveyName: "Media and Entertainment Survey",
    surveySubTitle: "Objective: To gather feedback on media consumption habits and preferences.",
    surveyCategory: "Media",
    surveyDescription:
      "This survey aims to collect insights on consumer satisfaction with media content and their usage patterns.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Feedback%20from%20Local%20Communities.png",
    reward: 100, // Placeholder reward
    campaignType: "",
    bg: "bg-bgSeven", // Placeholder background class
    questions: [
      {
        question:
          "How satisfied are you with the variety of entertainment content available through your media subscription (e.g., streaming services, TV)?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Satisfied", "Satisfied", "Neutral", "Unsatisfied", "Very Unsatisfied"],
      },
      {
        question: "Which type of media content do you consume the most?",
        description: "",
        questionType: "multiple_choice",
        optional: false,
        options: ["Movies", "TV shows", "Music", "Podcasts", "News"],
      },
      {
        question: "On a scale of 1-10, how likely are you to explore new media platforms for entertainment?",
        description: "",
        questionType: "scale",
        optional: false,
        options: [],
      },
      {
        question: "How do you prefer to consume your media content?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Streaming", "Television", "Radio", "Online Articles"],
      },
      {
        question: "What recommendations do you have for improving your media and entertainment experience?",
        description: "Share your suggestions",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 17, // Adjust ID as needed
    surveyName: "Hotel Satisfaction",
    surveySubTitle: "Objective: To assess guest satisfaction with hotel services and amenities.",
    surveyCategory: "Hotel",
    surveyDescription: "This survey aims to gather feedback on your recent stay to enhance our services.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Competitor%20Analysis.png",
    reward: 100, // Placeholder reward
    campaignType: "",
    bg: "bg-bgOne", // Placeholder background class
    questions: [
      {
        question: "How satisfied were you with the cleanliness of your room?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Satisfied", "Satisfied", "Neutral", "Unsatisfied", "Very Unsatisfied"],
      },
      {
        question: "How would you rate the quality of customer service during your stay?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Fair", "Poor"],
      },
      {
        question: "Were the hotel’s amenities (e.g., gym, pool, Wi-Fi) up to your expectations?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Yes", "No"],
      },
      {
        question: "On a scale of 1-10, how comfortable was your stay at our hotel?",
        description: "",
        questionType: "scale",
        optional: false,
        options: [],
      },
      {
        question: "What improvements could we make to enhance your experience in the future?",
        description: "Share your thoughts",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 18, // Adjust ID as needed
    surveyName: "Restaurant Feedback",
    surveySubTitle: "Objective: To gather feedback on the dining experience and customer satisfaction.",
    surveyCategory: "Restaurant",
    surveyDescription: "This survey aims to collect your thoughts about your recent dining experience.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Customer%20Feedback.png",
    reward: 100, // Placeholder reward
    campaignType: "",
    bg: "bg-bgTwo", // Placeholder background class
    questions: [
      {
        question: "How would you rate the quality of the food you were served?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Fair", "Poor"],
      },
      {
        question: "How satisfied were you with the service provided by our staff?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Satisfied", "Satisfied", "Neutral", "Unsatisfied", "Very Unsatisfied"],
      },
      {
        question: "Was your meal served in a timely manner?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Yes", "No"],
      },
      {
        question: "On a scale of 1-10, how would you rate the overall dining experience?",
        description: "",
        questionType: "scale",
        optional: false,
        options: [],
      },
      {
        question: "What could we improve to make your next visit more enjoyable?",
        description: "Share your thoughts",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 19, // Adjust ID as needed
    surveyName: "Airline Service Satisfaction",
    surveySubTitle: "Objective: To assess customer satisfaction with airline services and travel experience.",
    surveyCategory: "Airline",
    surveyDescription: "This survey collects feedback to enhance our airline services and customer experience.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Event%20Feedback.png",
    reward: 100, // Placeholder reward
    campaignType: "",
    bg: "bg-bgThree", // Placeholder background class
    questions: [
      {
        question: "How would you rate your overall experience on your recent flight?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Fair", "Poor"],
      },
      {
        question: "How satisfied were you with the check-in and boarding process?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Satisfied", "Satisfied", "Neutral", "Unsatisfied", "Very Unsatisfied"],
      },
      {
        question: "How would you rate the in-flight services (e.g., food, entertainment, customer support)?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Fair", "Poor"],
      },
      {
        question: "Was your flight punctual?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Yes", "No"],
      },
      {
        question: "What could we do to improve your flying experience in the future?",
        description: "Share your thoughts",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 20, // Adjust ID as needed
    surveyName: "Retail Store Customer Feedback",
    surveySubTitle: "Objective: To gather feedback on the shopping experience and customer service.",
    surveyCategory: "Retail",
    surveyDescription: "This survey is designed to collect your feedback about your recent shopping experience.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Pricing%20Research.png",
    reward: 100, // Placeholder reward
    campaignType: "",
    bg: "bg-bgFour", // Placeholder background class
    questions: [
      {
        question: "How would you rate the overall quality of products at our store?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent", "Good", "Fair", "Poor"],
      },
      {
        question: "How satisfied were you with the helpfulness of our staff?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very Satisfied", "Satisfied", "Neutral", "Unsatisfied", "Very Unsatisfied"],
      },
      {
        question: "Was the store layout easy to navigate and find what you were looking for?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Yes", "No"],
      },
      {
        question: "On a scale of 1-10, how likely are you to recommend our store to others?",
        description: "",
        questionType: "scale",
        optional: false,
        options: [],
      },
      {
        question: "What improvements can we make to enhance your shopping experience?",
        description: "Share your thoughts",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
];
