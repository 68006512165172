import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import OverviewTab from "../components/analyticsTab/OverviewTab";
import ResponsesTab from "../components/analyticsTab/ResponsesTab";
import WordCloudTab from "../components/analyticsTab/WordCloudTab";
import totalScore from "assets/images/totalScore.png";
import promoters from "assets/images/promoters.png";
import passives from "assets/images/passives.png";
import detractors from "assets/images/detractors.png";
import Details from "../components/analyticsTab/Details";
import Typography from "utils/typography";
import { useQuery } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import researchGif from "assets/images/insights/npx.png";
import { getResponseByType } from "services/insightService";
import CreationModal from "../components/CreationModal";
import LoadingSkeleton from "../components/LoadingSkeleton";
import useInsight from "hooks/useInsight";
import { useInsightOverview } from "hooks/useInsightOverview";
import dayjs from "dayjs";
import { baseAppURL } from "helpers/getBaseUrl";
import useWorkspace from "../../../hooks/useWorkspace";

const tabs = [
  { id: 1, name: "Overview" },
  { id: 2, name: "Responses" },
  // { id: 3, name: "Word cloud" },
  { id: 4, name: "Survey details" },
];

const NPSHome = () => {
  const [step, setStep] = useState(1);
  const location = useLocation();
  const locationArray = location.pathname.split("/");
  const isNPS = locationArray.includes("nps");
  const [openModal, setOpenModal] = useState(null);
  const [filterByPublishId, setFilterPublishId] = useState(null);
  const { data: workspaceData } = useWorkspace();
  const workspaceId = workspaceData.id;

  const handleTabChange = (e) => {
    setStep(e);
  };

  const handleModal = (id) => {
    setOpenModal(openModal === id ? null : id);
  };

  const { insightData, loadingInsight } = useInsight("nps");
  const { data, isLoading } = useInsightOverview("nps", filterByPublishId);

  const { data: responseData } = useQuery({ queryKey: ["getResponses"], queryFn: () => getResponseByType("nps") });

  const [surveyStatus, setSurveyStatus] = useState(insightData?.insights[0]?.surveyStatus);

  const responseInfo = responseData?.processedResponse;

  const summaryData = data?.totalNpsMetrics;

  const onlineSummary = [
    {
      id: "npsScore",
      name: "NPS score",
      image: totalScore,
      value: summaryData?.npsScore,
      color: "text-primary-800",
    },
    {
      id: "promoter",
      name: "Promoters",
      image: promoters,
      value: summaryData?.promoter,
      color: "text-green-500",
    },
    {
      id: "passive",
      name: "Passives",
      image: passives,
      value: summaryData?.passive,
      color: "text-yellow-500",
    },
    {
      id: "detractor",
      name: "Detractors",
      image: detractors,
      value: summaryData?.detractor,
      color: "text-red-500",
    },
  ];

  const npsInsightSummaryPublicLink = `${baseAppURL}public/insight-summary/nps/${workspaceId}`;
  const displayStep = () => {
    switch (step) {
      case 1:
        return (
          <OverviewTab
            overViewData={onlineSummary}
            graphData={data?.npsHistory}
            isLoading={isLoading}
            insights={insightData?.insights[0]}
            dates={insightData?.insights[0]?.publishCache?.publishPointers}
            setFilterPublishId={setFilterPublishId}
            insightType={"nps"}
            insightId={insightData?.insights[0]._id}
            surveyStatus={surveyStatus}
            shareLink={npsInsightSummaryPublicLink}
          />
        );
      case 2:
        return <ResponsesTab overViewData={onlineSummary} responses={responseInfo} />;
      case 3:
        return <WordCloudTab />;
      case 4:
        return <Details />;

      default:
    }
  };

  // if (isLoading) {
  //   return <PreLoader />;
  // }

  return (
    <>
      {loadingInsight ? (
        <LoadingSkeleton />
      ) : (
        <>
          {insightData?.insights?.length > 0 ? (
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <div className="text-[14px] font-bold">{insightData?.insights[0]?.insightName}</div>
                <div className="flex flex-row gap-5 items-center">
                  <div className="text-[11px]">
                    {dayjs(insightData?.insights[0]?.createdAt).format("ddd, DD MMM, YYYY")}
                  </div>
                  <div className="w-1 h-1 bg-black rounded-full"></div>
                  <div className="text-[11px]">{dayjs(insightData?.insights[0]?.createdAt).format("hh:mm a")}</div>
                </div>
              </div>
              <div className="flex space-x-4 mt-4">
                {tabs.map((el) => {
                  return (
                    <Typography.SubText
                      className={` pb-[4px] cursor-pointer ${
                        step === el.id
                          ? "border-b-[3px] text-[#0F172A] border-primary-800 font-semibold "
                          : "text-[#6C6685]"
                      } `}
                      onClick={el.id !== 5 ? () => handleTabChange(el.id) : null}
                      key={el.id}
                    >
                      {el.name}
                    </Typography.SubText>
                  );
                })}
              </div>
              <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

              <div>{displayStep()}</div>
            </div>
          ) : (
            <div>
              <div className="border-[1px] rounded-lg h-[240px] w-[60%] bg-white ">
                <div className=" bg-cover h-[100%] flex items-center justify-center ">
                  <div className="w-[60%] space-y-4">
                    <div className="w-fit px-3 py-1 bg-primary-50">
                      <Typography.SubText className="w-[100%] text-primary-800">Insights</Typography.SubText>
                    </div>
                    <Typography.H3 className="font-semibold text-primary-800">
                      {isNPS ? "Send out your first NPS survey" : ""}
                    </Typography.H3>

                    <Typography.SubText className="w-[70%]">
                      {isNPS ? " Measure your customer loyalty with a quick net promoter score survey." : ""}
                    </Typography.SubText>

                    <BlueButton text="Get started" onClick={() => handleModal(isNPS ? 1 : 2)} />
                  </div>

                  <div className="w-[23%] ">
                    <img src={researchGif} alt="researchGif" />
                  </div>
                </div>
              </div>

              {openModal === 1 && <CreationModal from="nps" handleModal={handleModal} />}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NPSHome;
