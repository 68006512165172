export const FileIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.48828 10.4653C3.48828 8.53853 5.05025 6.97656 6.97703 6.97656H14.8353C15.7605 6.97656 16.648 7.34413 17.3022 7.99839L18.7473 9.44348C19.4015 10.0977 19.7691 10.9851 19.7691 11.9104V22.0945C19.7691 24.0213 18.2072 25.5832 16.2804 25.5832H6.97703C5.05025 25.5832 3.48828 24.0213 3.48828 22.0945V10.4653Z"
      fill="#6B7FFF"
    />
    <path
      d="M8.14062 5.81492C8.14062 3.88814 9.7026 2.32617 11.6294 2.32617H18.3248C19.25 2.32617 20.1374 2.69373 20.7916 3.348L23.3996 5.95601C24.0539 6.61028 24.4215 7.49765 24.4215 8.42293V17.4441C24.4215 19.3709 22.8595 20.9328 20.9327 20.9328H11.6294C9.7026 20.9328 8.14062 19.3709 8.14062 17.4441V5.81492Z"
      fill="#2D28FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 10.9629C11 10.4311 11.4311 10 11.9629 10H19.6662C20.1981 10 20.6292 10.4311 20.6292 10.9629C20.6292 11.4947 20.1981 11.9258 19.6662 11.9258H11.9629C11.4311 11.9258 11 11.4947 11 10.9629Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 14.8164C11 14.2846 11.4311 13.8535 11.9629 13.8535H15.8146C16.3464 13.8535 16.7775 14.2846 16.7775 14.8164C16.7775 15.3483 16.3464 15.7793 15.8146 15.7793H11.9629C11.4311 15.7793 11 15.3483 11 14.8164Z"
      fill="white"
    />
    <path
      d="M18.6055 5.81492V2.32617C19.3501 2.32617 20.0642 2.62196 20.5907 3.14848L23.5978 6.15553C24.1242 6.68205 24.4201 7.39615 24.4201 8.14076H20.9313C19.6467 8.14076 18.6055 7.09944 18.6055 5.81492Z"
      fill="white"
    />
  </svg>
);
