import React, { useEffect, useRef, useState } from "react";
import pauseIcon from "assets/icons/dashboard/pauseIconmB.png";

const VideoCard = ({ videoUrl, type }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);

  // Extract video ID from YouTube URL (works for both youtu.be and youtube.com URLs)
  const getVideoId = (url) => {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:v\/|u\/\w\/|embed\/|watch\?v=|\&v=)|youtu\.be\/)([^#\&\?]*).*/,
    );
    return match && match[1];
  };

  const videoId = getVideoId(videoUrl);

  useEffect(() => {
    // Load YouTube IFrame API
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Initialize the player once the API is ready
    window.onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player(`youtube-player-${videoId}`, {
        videoId,
        events: {
          onReady: () => {
            playerRef.current.playVideo(); // Start playing initially if needed
          },
        },
      });
    };
  }, [videoId]);

  const handleVideoClick = () => {
    if (playerRef.current) {
      if (isPlaying) {
        playerRef.current.pauseVideo();
      } else {
        playerRef.current.playVideo();
      }
      setIsPlaying((prev) => !prev);
    }
  };

  return (
    <div
      className={`${
        type === "skipLogic" ? "w-full h-[220px]" : "w-[100%] h-[230px]"
      }  flex justify-center items-center bg-[#EBF1FF]`}
    >
      <div className="w-[96%] h-[210px] rounded-md bg-white flex justify-start items-start overflow-hidden relative">
        <div id={`youtube-player-${videoId}`} className="w-full h-full object-cover"></div>
        {!isPlaying && (
          <div
            onClick={handleVideoClick}
            className="w-[70px] h-[70px] cursor-pointer absolute right-[40%] bottom-[30%] rounded-full flex items-center justify-center p-1 bg-primary text-white"
          >
            <img src={pauseIcon} alt="Play" />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoCard;
