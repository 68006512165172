import Modal from "components/Modal";
import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import SecondaryInput from "components/Inputs/SecondaryInput";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAllEmailId } from "services/senderIdService";
import Tooltip from "components/Tooltip";
import SelectInput from "components/Inputs/SelectInput";
import { useNavigate } from "react-router-dom";
import Toast from "config/toast";
import { saveAndExit } from "services/campaignService";

const validationSchema = Yup.object({
  emailSubject: Yup.string().required("Please complete this field."),
  objective: Yup.string().required("Please complete this field."),
});

const initialValues = {
  campaignSubject: "mooyi",
  emailSubject: "",
  objective: "",
  senderEmail: "",
  senderName: "",
};

const objectives = [
  { label: "Select objective", value: "" },
  { label: "Promotional", value: "promotional" },
  { label: "Transactional", value: "transactional" },
  { label: "Remainder", value: "remainder" },
  { label: "Educational", value: "educational" },
  { label: "Survey/Feedback", value: "survey/feedback" },
];

const EmailModal = ({ data, toggleModal, setBroadcastData }) => {
  const emailDataArray = data.data;

  const { data: email, isLoading: emailLoading } = useQuery({ queryKey: ["emailSenderIDs"], queryFn: getAllEmailId });

  const option1 = [{ value: "", label: "Loading..." }];
  const options = email?.map((el) => ({
    value: `${el?.name}${" "}${el?.email}`,
    label: <>{`${el?.name}<${el?.email}>`}</>,
  }));

  const emailData = emailDataArray?.map((e) => ({ email: e }));

  console.log(emailData, "email");

  const onSubmit = async (data) => {
    const payload = {
      campaign_channel: "email",
      sender_name: data.senderName,
      campaignObjective: data.objective,
      campaign_subject: data.campaignSubject,
      sender_mail: data.senderEmail.toString().replace(",", " "),
      campaign_name: data.emailSubject,
      testContact: "",
      content: "",
      receiver: emailData,
    };
    console.log(payload);
    setBroadcastData((prevData) => ({
      ...prevData,
      ...payload,
    }));
    toggleModal(1);
    // mutateCampaign({ payload });
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  // console.log(values)
  const handleSelect = (e) => {
    let x = e.target.value;
    var values = x.split(" ");
    var email = values[values.length - 1];
    values.pop();
    var name = values
      .toString()
      .replace(",", " ")
      .toString()
      .replace(",", " ")
      .toString()
      .replace(",", " ")
      .toString()
      .replace(",", " ");
    setFieldValue("senderEmail", email);
    setFieldValue("senderName", name.toString().replace(",", " "));
  };

  // const disabled = !values?.emailSubject || !values?.senderName || !values?.emailSubject;

  const disabled = [values?.emailSubject, values?.objective, values?.emailSubject].some((field) => field === "");

  const getError = (key) => {
    return touched[key] && errors[key];
  };
  return (
    <Modal title="Email details" onClose={() => toggleModal(null)}>
      <p className="text-m font-semibold my-2 px-5">Enter your email details to proceed.</p>
      <div className="w-[586px] px-5 mt-5">
        <form>
          <div className="mt-5 space-y-5">
            {/* <div className="w-full">
              <SecondaryInput
                name="emailSubject"
                text="Campaign Name"
                placeholder="Welcome Discount"
                onChange={handleChange}
                onBlur={handleBlur}
                error={getError("emailSubject")}
                type="text"
                value={values.name}
              />
            </div> */}
            <div className="w-full">
              <SecondaryInput
                name="emailSubject"
                text="Email subject"
                placeholder="Enter email subject"
                onChange={handleChange}
                onBlur={handleBlur}
                error={getError("emailSubject")}
                type="text"
                value={values.emailSubject}
              />
            </div>
            <div className="w-full">
              <div className="flex space-x-2 items-center">
                <p className="text-[#292D32] text-m font-[500] tracking-[-0.02em] ">Objective</p>
                <Tooltip text="This is the main goal of your campaign." />
              </div>

              <SelectInput
                handleModal
                name="objective"
                css="h-[50px] rounded-[8px] mt-1"
                options={objectives}
                value={values.objective}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <div className="flex space-x-2 items-center">
                <p className="text-sm font-medium">Email Sender ID</p>
                <Tooltip text="This will appear as the sender name in your audience's inbox, e.g. Muyiwa from Mooyi." />
              </div>
              <SelectInput
                options={
                  emailLoading
                    ? option1
                    : [
                        {
                          label: "Select a Sender ID",
                          value: "",
                        },
                        ...options,
                      ]
                }
                disabled={emailLoading ? true : false}
                css="h-[50px] rounded-[8px]"
                name="senderName"
                onChange={handleSelect}
              />
            </div>
          </div>
          <div className="w-full mt-4">
            <div className="flex justify-between space-x-5 mt-[3em]">
              <GreyButton text="Cancel" onClick={toggleModal} />
              <BlueButton type="submit" text="Next" css="" disabled={disabled} onClick={handleSubmit} />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EmailModal;
