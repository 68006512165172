import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Overview from "./Overview";
import Details from "../components/analyticsTab/Details";
import Typography from "utils/typography";
import BlueButton from "components/Button/BlueButton";
import researchGif from "assets/images/insights/customerProfile.png";
import useInsight from "hooks/useInsight";
import { useInsightOverview } from "hooks/useInsightOverview";
import CreationModal from "../components/CreationModal";
import LoadingSkeleton from "../components/LoadingSkeleton";
import dayjs from "dayjs";
import { baseAppURL } from "helpers/getBaseUrl";
import useWorkspace from "../../../hooks/useWorkspace";
const tabs = [
  { id: 1, name: "Overview" },
  { id: 3, name: "Survey details" },
];

const CustomerProfilingHome = () => {
  const [step, setStep] = useState(1);

  const [openModal, setOpenModal] = useState(null);

  const [filterByPublishId, setFilterPublishId] = useState(null);

  const { insightData, loadingInsight } = useInsight("cp");
  const { data, isLoading } = useInsightOverview("cp", filterByPublishId);
  const { data: workspaceData } = useWorkspace();
  const workspaceId = workspaceData.id;

  const [surveyStatus, setSurveyStatus] = useState(insightData?.insights[0]?.surveyStatus);

  const handleTabChange = (e) => {
    setStep(e);
  };

  const handleModal = (id) => {
    setOpenModal(openModal === id ? null : id);
  };

  const cpInsightSummaryPublicLink = `${baseAppURL}public/insight-summary/cp/${workspaceId}`;
  const displayStep = () => {
    switch (step) {
      case 1:
        return (
          <Overview
            insights={data}
            insightType={"cp"}
            surveyStatus={surveyStatus}
            setSurveyStatus={setSurveyStatus}
            insightId={insightData?.insights[0]._id}
            isLoading={isLoading}
            dates={insightData?.insights[0]?.publishCache?.publishPointers}
            setFilterPublishId={(e) => {
              setFilterPublishId(e);
            }}
            shareLink={cpInsightSummaryPublicLink}
          />
        ); //{insightData?.insights[0]}
      case 3:
        return <Details />;

      default:
    }
  };

  return (
    <div>
      {loadingInsight ? (
        <LoadingSkeleton />
      ) : (
        <>
          {insightData?.insights?.length > 0 ? (
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <div className="text-[14px] font-bold">{insightData?.insights[0]?.insightName}</div>
                <div className="flex flex-row gap-5 items-center">
                  <div className="text-[11px]">
                    {dayjs(insightData?.insights[0]?.createdAt).format("ddd, DD MMM, YYYY")}
                  </div>
                  <div className="w-1 h-1 bg-black rounded-full"></div>
                  <div className="text-[11px]">{dayjs(insightData?.insights[0]?.createdAt).format("hh:mm a")}</div>
                </div>
              </div>
              <div className="flex space-x-4 mt-4">
                {tabs.map((el) => {
                  return (
                    <Typography.SubText
                      className={` pb-[4px] cursor-pointer ${
                        step === el.id
                          ? "border-b-[3px] text-[#0F172A] border-primary-800 font-semibold "
                          : "text-[#6C6685]"
                      } `}
                      onClick={el.id !== 5 ? () => handleTabChange(el.id) : null}
                      key={el.id}
                    >
                      {el.name}
                    </Typography.SubText>
                  );
                })}
              </div>
              <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

              <div>{displayStep()}</div>
            </div>
          ) : (
            <div>
              <div className="border-[1px] rounded-lg h-[240px] w-[60%] bg-white ">
                <div className=" bg-cover h-[100%] flex items-center justify-center ">
                  <div className="w-[60%] space-y-4">
                    <div className="w-fit px-3 py-1 bg-primary-50">
                      <Typography.SubText className="w-[100%] text-primary-800">Insights</Typography.SubText>
                    </div>
                    <Typography.H3 className="font-semibold text-primary-800">Get to know your customers</Typography.H3>

                    <Typography.SubText className="w-[70%]">
                      Gather more information about the preferences and behaviour of your customers.
                    </Typography.SubText>

                    <BlueButton text="Get started" onClick={() => handleModal(1)} />
                  </div>

                  <div className="w-[23%] ">
                    <img src={researchGif} alt="researchGif" />
                  </div>
                </div>
              </div>
              {openModal === 1 && <CreationModal from="cp" handleModal={handleModal} />}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CustomerProfilingHome;
