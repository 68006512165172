import React, { useEffect, useState } from "react";

import { surveyIcons, surveyType } from "lib/surveyFunctions";
import { FaRegChartBar as NewBar } from "react-icons/fa6";

import Tooltip from "components/Tooltip";
import Skeleton from "react-loading-skeleton";

import Filters from "./SummaryCards/Filters";
import BlueButton from "components/Button/BlueButton";
import { mergeSort } from "lib/optimizedDataSort";
import { replaceMooyiOptionWithOthers } from "lib/ReplaceOther";
import { TimeIcon } from "assets/icons/survey/TimeIcon";
import { SurveyIcon } from "assets/icons/sidebar";
import Customized from "pages/Research/components/Charts/Customized";
import BarCharts from "pages/Research/components/Charts/BarCharts";
import PieCharts from "pages/Research/components/Charts/PieCharts";
// import MatrixBarCharts from "pages/Research/components/Charts/MatrixBarCharts";
import { FaChartPie, FaRegChartBar } from "react-icons/fa";
import classNames from "classnames";
import Typography from "utils/typography";
import { BranchingIcon } from "assets/icons/survey/BranchingIcon";
import { cn } from "helpers/classHelpers";
import { SummarySkeleton } from "./SummarySkeleton";
import MatrixCustomized from "pages/Research/components/Charts/MatrixCustomized";
import EmptyState from "pages/Research/components/EmptyState";
import emptySurvey from "assets/images/emptySurveyIcon.png";
import useSummaryStore from "store/useSummaryStore";

const Summary = ({
  data,
  filterPersonalData,
  handleDisplayImg,
  filterCoreData,
  filterWorkData,
  overview,
  handleFilter,
  isFetchingSummary,
  filterData,
  setFilterData,
  detailsData,
  section,
  setSection,
  filterLength,
  questionsFilter,
  setQuestionsFilter,
  refetchSummary,
  isLoadingDemo,
}) => {
  const [viewState, setViewState] = useState([]);
  const [open, setOpen] = useState(null);
  const [viewMoreNumber, setViewMoreNumber] = useState(5);
  const [keywordValue, setKeywordValue] = useState(null);
  const { setQuestionIndex, questionIndex } = useSummaryStore();

  const [responseArray, setResponseArray] = useState([]);

  console.log(filterCoreData?.length, "filterCoreData");

  const branchingDetails = detailsData?.surveyDetails?.branchingQuestion;

  const handleKeyword = (value, i, parentIndex) => {
    if (keywordValue === null || keywordValue !== value) {
      setKeywordValue(value);

      const filteredSentences = data?.responseData[parentIndex]?.textResponse?.responses.filter((sentence) =>
        sentence.toLowerCase().includes(value.split("-")[1]),
      );

      setResponseArray(filteredSentences);

      setOpen(i);
    } else {
      setKeywordValue(null);
      setOpen(null);
    }
  };

  const analyticData = [
    {
      id: 1,
      icon: <SurveyIcon fill1="#95ADFF" fill2="#1D19AF" />,
      title: (
        <div className="flex items-center gap-2">
          Responses
          <Tooltip text="The number of people who completed your survey compared to the total number of people who received your survey." />
        </div>
      ),
      number:
        overview?.responses === undefined
          ? `0 / ${overview?.progress?.expected}`
          : `${overview?.responses} / ${overview?.progress?.expected}`,
    },

    {
      id: 2,
      icon: <SurveyIcon fill1="#95ADFF" fill2="#1D19AF" />,
      title: (
        <div className="flex items-center gap-2">
          Total Completion time
          <Tooltip text="The number of people who started and completed your survey, i.e., clicked the 'Submit' button." />
        </div>
      ),
      number: `${overview?.surveyTtl === undefined ? "0" : overview?.surveyTtl} sec`,
    },
    {
      id: 3,
      icon: <TimeIcon fill1="#95ADFF" fill2="#1D19AF" />,
      title: (
        <div className="flex items-center gap-2">
          Drop-off rate
          <Tooltip text="The number of people who started your survey but did not complete it, i.e. did not click the 'Submit' button." />
        </div>
      ),
      number: `${overview?.dropOffRate === undefined ? "0" : overview?.dropOffRate}%`,
    },

    {
      id: 4,
      icon: <TimeIcon fill1="#95ADFF" fill2="#1D19AF" />,
      title: (
        <div className="flex items-center gap-2">
          Avg. Completion time <Tooltip text="The average time taken to complete the survey." />
        </div>
      ),
      number: `${overview?.timeToComplete === undefined ? "0" : overview?.timeToComplete} sec`,
    },
  ];

  const newViewType = [
    {
      name: "default_view",
      icon: NewBar,
    },
    {
      name: "column_chart",
      icon: FaRegChartBar,
    },
    {
      name: "pie_chart",
      icon: FaChartPie,
    },
  ];

  const handleDropdown = (id) => {
    setOpen(open === id ? null : id);
  };

  const handleTypeSelect = (x, index) => {
    setViewState((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = {
        ...updatedValues[index],
        view: x,
      };
      return updatedValues;
    });
    handleDropdown();
  };

  useEffect(() => {
    const arrayState = (data?.responseData || [])?.map((el) => ({
      id: el?.id,
      view: "default_view",
    }));
    setViewState(arrayState);
  }, [data?.responseData]);

  const displayChart = (index) => {
    const newData = data?.responseData[index]?.options;
    // const newData2 = allData?.responseData[index]?.options;
    console.log(newData, "newData");

    let finalValue = [];
    const keys = Object.keys(newData || {});

    if (keys.length > 0) {
      for (const elm of keys) {
        let finalEntry = {
          name: elm,
          value: newData?.[elm]?.value ?? 0,
          // staticValue: newData2?.[elm]?.value ?? 0,
          percentage: newData?.[elm]?.percentage ?? 0,
          // staticPercentage: newData2?.[elm]?.percentage ?? 0,
          question: data?.responseData[index]?.question,
          questionType: data?.responseData[index]?.optionType,
        };

        // Update finalEntry based on newData2 if available
        // if (newData2?.[elm]) {
        //   finalEntry = {
        //     ...finalEntry,
        //     staticValue: newData2[elm].value ?? finalEntry.staticValue,
        //     staticPercentage: newData2[elm].percentage ?? finalEntry.staticPercentage,
        //   };
        // }

        finalValue.push(finalEntry);
      }
    }

    switch (viewState[index]?.view) {
      case "default_view":
        return (
          <Customized
            data={replaceMooyiOptionWithOthers(finalValue)}
            questionOption={data?.responseData[index]?.questionOption}
            filterLength={filterLength}
            questionsFilter={questionsFilter}
            questionIndex={index}
            setQuestionsFilter={setQuestionsFilter}
            // sumLoading={sumLoading}
          />
        );
      case "column_chart":
        return (
          <BarCharts
            data={replaceMooyiOptionWithOthers(finalValue)}
            questionOption={data?.responseData[index]?.questionOption}
            filterLength={filterLength}
          />
        );
      case "pie_chart":
        return (
          <PieCharts
            data={replaceMooyiOptionWithOthers(finalValue)}
            questionOption={data?.responseData[index]?.questionOption}
            filterLength={filterLength}
          />
        );
      default:
    }
  };

  const createNewObject = (messages) => {
    const transformedArray = messages?.map((item) => {
      const [key] = Object.keys(item); // Extract the key ('male', 'female', etc.)
      const { count, percentage } = item[key]; // Destructure count and percentage from the nested object

      return { name: key, value: count, percentage };
    });

    return transformedArray;
  };

  const handleViewMore = (type) => {
    if (type === "more") {
      setViewMoreNumber(viewMoreNumber + 5);
    } else if (type === "less") {
      if (viewMoreNumber < 10 && viewMoreNumber >= 5) {
        const newValue = viewMoreNumber - 5;
        setViewMoreNumber(viewMoreNumber - newValue);
      } else {
        setViewMoreNumber(viewMoreNumber - 5);
      }
    }
  };

  const transformMatrixData = (options) => {
    return Object.keys(options).map((column) => {
      const rows = options[column];
      const transformedRow = { name: column };
      Object.keys(rows).forEach((row) => {
        transformedRow[row] = rows[row].value;
      });
      return transformedRow;
    });
  };

  const resetAnswerFilter = () => {
    setQuestionsFilter([]);
    setQuestionIndex("");
  };

  return (
    <div>
      <div className="flex justify-between ">
        {analyticData.map((el) => {
          return (
            <div className="w-[24%] " key={`analyticData-${el}`}>
              <div>
                <div className="flex items-start text-left bg-white h-[81px] w-full border print:border-[1px] rounded-lg space-x-4 p-4">
                  <div className="w-[30px] h-[30px] rounded-full bg-primary-50 flex items-center justify-center ">
                    {el.icon}
                  </div>
                  <div>
                    <p className="font-semibold text-lg text-gray-800">
                      {el.number === 0 ? "0" : el.number || <Skeleton />}
                    </p>

                    <div className="text-s text-neutral-500">{el.title || <Skeleton />}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="bg-white p-6 mt-[1em]">
        {/* <div className="bg-primary-50 bg-no-repeat w-[100%] bg-cover p-4 rounded-md border-[1px] flex items-center mb-[1em] space-x-4">
          <img src={Mascot} alt="Mascot" className="w-[90px]" />

          <div>
            <div className="flex items-center">
              <p className="text-ss  bg-primary-100 text-primary-700 p-1 px-2 rounded-[4px] ">Progress Report</p>
            </div>

            <div className="text-ss mt-2 h-[6px] w-[370px] bg-[#D7DDEA] rounded-lg overflow-hidden">
              <div
                style={{ width: `${(overview?.progress?.response / +overview?.progress?.expected) * 100}%` }}
                className="bg-success-500 h-[100%] w-[50%]"
              ></div>
            </div>
          </div>

          <p className="text-s w-[35%]">
            <span className="font-bold">
              You currently have: {overview?.progress?.response} of {overview?.progress?.expected} Responses.
            </span>
            You will receive an email when your audience completes the survey.
          </p>
        </div> */}
        <div className="flex items-start justify-between">
          {/* <div className="shadow-md w-[25%] p-4 rounded-md border-[1px] max-h-[60vh] overflow-y-auto ">
            <p className=" font-[500] text-m">Questions</p>

            {isFetchingSummary ? (
              <div className=" ">
                <QuestionSkeleton />
              </div>
            ) : (
              <>
                {data?.responseData?.map((el, i) => {
                  return (
                    <a
                      href={`#question-${i}`}
                      className="flex space-x-2 text-[#525252] items-center cursor-pointer my-2  h-[35px] hover:bg-primary-100 rounded-sm px-2"
                      key={i}
                    >
                      <div>{surveyIcons(el.optionType)}</div>
                      <p className="text-ss">{i + 1}</p>
                      <p className="text-ss pl-4">
                        {el.question.substring(0, 27)}
                        {el.question.length > 27 ? "..." : ""}
                      </p>
                    </a>
                  );
                })}
              </>
            )}
          </div> */}

          {/* replace with  isFetchingSummary */}
          {isFetchingSummary ? (
            <div className="w-[68%] space-y-6 h-[60vh] flex  border-[1px] rounded-md space-x-4">
              <SummarySkeleton />
            </div>
          ) : (
            <div className="w-[68%] space-y-6 h-[60vh] overflow-y-auto ">
              {data?.responseData?.map((el, i) => {
                return (
                  <div id={`question-${i}`} key={`card${i}`} className="border-[1px] rounded-md p-5">
                    <div className="flex  justify-between items-center">
                      <div className="flex items-center space-x-2 ">
                        <p className="bg-primary-100 flex items-center justify-center text-primary-800 w-[25px] h-[28px] rounded-md font-medium">
                          {i + 1}
                        </p>
                        <div className="border-[1px] flex items-center space-x-2 p-[5px] px-2 rounded-md">
                          <div>{surveyIcons(el.optionType)}</div>
                          <p className="text-ss">{surveyType(el.optionType)}</p>
                        </div>
                      </div>

                      {el.optionType === "text_response" ||
                      el.optionType === "image" ||
                      el.optionType === "video" ||
                      el.optionType === "matrix" ||
                      el.optionType === "ranking" ? null : (
                        <div className="flex items-center border-[1px] w-[100px] justify-between h-[30px] overflow-hidden rounded">
                          {newViewType.map((elm) => (
                            <div
                              className={classNames(
                                " h-[100%] w-[33%] flex items-center justify-center cursor-pointer",
                                elm.name === viewState[i]?.view ? "bg-primary-200" : "bg-gray-100 text-gray-700",
                              )}
                              onClick={() => handleTypeSelect(elm.name, i)}
                            >
                              <elm.icon />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    <p className="text-[20px] font-bold my-3">{el.question}</p>
                    <hr className="my-[1em]" />
                    <div>
                      {el.optionType === "text_response" ? (
                        <div className="space-y-3">
                          <div className="flex items-center gap-3 flex-wrap">
                            {el?.textResponse?.keywords?.map((elm, j) => {
                              return (
                                <div
                                  key={`textKey${i}`}
                                  className={`min-w-150px px-4 $  ${
                                    keywordValue === `${i}-${elm.word}`
                                      ? "bg-primary-800 text-white"
                                      : "bg-neutral-200 hover:bg-primary-200"
                                  } py-1 flex text-xs justify-center items-center rounded space-x-2 cursor-pointer`}
                                  onClick={() =>
                                    handleKeyword(`${i}-${elm.word}`, `${el.word}${el.count}${el.question}`, i)
                                  }
                                >
                                  <p> {elm.word}</p> <p> ({elm.count})</p>
                                </div>
                              );
                            })}
                          </div>

                          <>
                            {open === `${el.word}${el.count}${el.question}` ? (
                              <>
                                {responseArray?.slice(0, viewMoreNumber).map((elm, i) => {
                                  return (
                                    <div
                                      key={`textRes${i}`}
                                      className="text-[#737373] bg-[#FAFAFA] p-2 rounded-md text-s flex items-center justify-between"
                                    >
                                      {elm.split(" ").forEach((el) => {
                                        if (el === keywordValue) {
                                          return <p className="text-primary-800">{el}</p>;
                                        }
                                      })}

                                      <p> {elm}</p>

                                      {/* {elm.map((word, j) => (
                                      <span key={`wordKey${j}`} className={word === keywordValue ? "text-primary-800" : ""}>
                                        {word}
                                      </span>
                                    ))} */}
                                    </div>
                                  );
                                })}{" "}
                              </>
                            ) : null}
                          </>

                          <div className="flex items-center space-x-4">
                            {viewMoreNumber >= responseArray?.length ? (
                              <></>
                            ) : (
                              <BlueButton
                                onClick={() => handleViewMore("more")}
                                text="View More"
                                className="w-[100%] text-sm"
                                type="button"
                              />
                            )}

                            {viewMoreNumber > 5 ? (
                              <BlueButton
                                onClick={() => handleViewMore("less")}
                                text="View Less"
                                className="w-[100%] text-sm"
                                type="button"
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      ) : el.optionType === "image" ? (
                        <>
                          <div className="flex space-x-4 h-[200px] overflow-y-scroll">
                            {el?.theResponses?.length === 0
                              ? "No Response yet"
                              : el?.textResponse?.responses?.map((el, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="bg-[#FAFAFA] mb-2 w-[200px] h-[100%] p-2 text-ss rounded-lg"
                                      onClick={() => handleDisplayImg(el)}
                                    >
                                      <img src={el} alt="" className="object-contain w-[100%] h-[100%]" />
                                    </div>
                                  );
                                })}
                          </div>
                        </>
                      ) : el.optionType === "matrix" || el.optionType === "ranking" ? (
                        <>
                          {/* <MatrixBarCharts data={transformMatrixData(el?.options)} /> */}

                          <MatrixCustomized
                            data={transformMatrixData(el?.options)}
                            questionIndex={i}
                            datum={el}
                            setQuestionsFilter={setQuestionsFilter}
                          />
                        </>
                      ) : el.optionType === "video" ? (
                        <>
                          <div className="flex space-x-4 h-[200px] overflow-y-auto">
                            {el?.response?.length === 0
                              ? "No Response yet"
                              : el?.textResponse?.responses?.map((el, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="bg-[#FAFAFA] min-w-[300px] max-w-[300px] h-[100%] p-2 text-ss rounded-lg"
                                    >
                                      <video className="h-[100%] w-full rounded-[8px] object-cover" controls>
                                        <source src={el} />
                                      </video>
                                    </div>
                                  );
                                })}
                          </div>
                        </>
                      ) : (
                        <>
                          {displayChart(i)}

                          <>
                            {el.optionType === "single_choice" || el.optionType === "multi_choice" ? (
                              <>
                                {data?.responseData[i]?.options?.MooyiOption?.optionResponses?.length > 0 ? (
                                  <p className="mt-3 u underline text-xs">Others </p>
                                ) : null}

                                <div className="flex items-center gap-3 flex-wrap mt-2">
                                  {data?.responseData[i]?.options?.MooyiOption?.optionResponses?.map((el, index) => {
                                    return (
                                      <p
                                        className="min-w-150px px-4 bg-neutral-200 hover:bg-primary-200 py-1 flex text-xs justify-center items-center rounded space-x-2 cursor-pointer"
                                        key={`${el}${i}${index}`}
                                      >
                                        {el}
                                      </p>
                                    );
                                  })}
                                </div>
                              </>
                            ) : null}
                          </>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <div className="w-[30%] border-[1px] rounded-md  p-4 h-[60vh] overflow-y-auto ">
            {branchingDetails?.questionName !== "" && branchingDetails?.questionName !== undefined && (
              <>
                <div className="space-y-3  w-[100%]">
                  <div className="bg-primary-400 px-2 py-2 rounded flex items-center space-x-2 w-[180px]">
                    <BranchingIcon fill1="#fff" fill2="#fff" />

                    <Typography.MicroText className="text-white font-semibold">Branching Question</Typography.MicroText>
                  </div>

                  <Typography.MicroText>Click on a specific option to view responses.</Typography.MicroText>

                  <Typography.MicroText className="text-gray-700 font-semibold">
                    Q. {branchingDetails?.questionName}
                  </Typography.MicroText>

                  <div className="space-y-2">
                    {branchingDetails?.options?.map((el, i) => (
                      <div
                        key={`branchingDetails-${i}`}
                        className={cn(
                          "h-[40px] cursor-pointer flex items-center rounded-md  px-4 py-1",
                          section === el.section ? "bg-primary-50 font-semibold text-primary-800" : "bg-gray-100",
                        )}
                        onClick={() => setSection(el.section)}
                      >
                        <Typography.MicroText>{el.option}</Typography.MicroText>
                      </div>
                    ))}
                  </div>
                </div>

                <hr className="border-t-[2px] my-3 " />
              </>
            )}
            {/* isLoadingDemo */}
            {isLoadingDemo ? (
              <div className="space-y-2">
                <div>
                  <Skeleton className="h-[50px]" />
                  <Skeleton className="h-[35px]" />
                  <Skeleton className="h-[20px]" />
                  <Skeleton className="h-[10px]" />
                </div>

                <div>
                  <Skeleton className="h-[50px]" />
                  <Skeleton className="h-[35px]" />
                  <Skeleton className="h-[20px]" />
                  <Skeleton className="h-[10px]" />
                </div>

                <div>
                  <Skeleton className="h-[50px]" />
                  <Skeleton className="h-[35px]" />
                  <Skeleton className="h-[20px]" />
                  <Skeleton className="h-[10px]" />
                </div>
              </div>
            ) : (
              <>
                {Object.keys(filterCoreData)?.length === 0 && questionIndex === "" ? (
                  <div className="h-[50vh] w-[100%] flex items-center justify-center">
                    <EmptyState icon={emptySurvey} content="No response yet." />
                  </div>
                ) : (
                  <Filters
                    filterData={filterData}
                    refetchSummary={refetchSummary}
                    setFilterData={setFilterData}
                    handleFilter={handleFilter}
                    ageData={createNewObject(filterCoreData?.age)}
                    stateData={createNewObject(filterCoreData?.state)}
                    cityData={createNewObject(filterCoreData?.city)}
                    countryData={createNewObject(filterCoreData?.country)}
                    genderData={createNewObject(filterCoreData?.gender)}
                    earningData={createNewObject(filterPersonalData?.earning)}
                    educationLevelData={createNewObject(filterPersonalData?.educationLevel)}
                    relationshipStatusData={createNewObject(filterPersonalData?.relationshipStatus)}
                    parentalStatusData={createNewObject(filterPersonalData?.parentalStatus)}
                    employmentTypeData={createNewObject(filterWorkData?.employmentStatus)}
                    categoryOfInterestData={createNewObject(filterWorkData?.categoryOfInterest)}
                    occupationData={createNewObject(filterWorkData?.occupation)}
                    resetAnswerFilter={resetAnswerFilter}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
